import {
  Body,
  Button,
  Card,
  CardLevel,
  Col, Divider,
  FormGroup,
  Frow, IconNames16, ISelectOption,
  MultiSelect,
  PaddingLevel,
} from '@pinpointhq/thumbtack';
import Search from '../../../shared/Search';
import * as React from 'react';
import useVisibility from '../../../shared/hooks/useVisibility';
import { useState } from 'react';
import {
  exportAdminJobsPath,
} from '../../../../javascript/application/ts_routes';
import { visit } from 'turbolinks';

interface IFilters {
  companies?: string;
  workstream?: string;
}

export default function Filters({ handleApplyFilters, meta, searchTerm, selection, setSearchTerm, setSelection, initialFilter = {} }) {
  const { isOpen, toggleOpen } = useVisibility(false)
  const [filters, setFilters] = useState<IFilters>(initialFilter)
  const [companies, setCompanies] = React.useState<ISelectOption[]>([])
  const [workstreams, setWorkstreams] = React.useState<ISelectOption[]>([])

  React.useEffect(
    () => {
      if (meta && !workstreams.length && !companies.length) {
        setCompanies(meta.companies.map((company) => ({ value: company, label: company })))
        setWorkstreams(meta.workstreams)
      }
    }, [meta]
  )

  React.useEffect(() => {
    document.addEventListener(`${identifier}:search`, handleSearch);
    return () => {
      document.removeEventListener(`${identifier}:search`, handleSearch);
    };
  }, [searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.detail.searchTerm);
  }

  const handleFilter = () => {
    handleApplyFilters(filters)
  }

  const handleExport = () => {
    visit(exportAdminJobsPath({ filters: filters }))
  }

  const handleCompanyChange = (items) => setFilters({ ...filters, companies: items.map((item) => item.value) })
  const handleWorkstreamChange = (items) => setFilters({ ...filters, workstream: items.map((item) => item.value) })

  const handleCancel = () => setSelection([])

  const identifier = 'job-index'
  return(
    <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.EXPANDED}>
      <Frow gutters={16} verticalGutters={16}>
        <div className="bp3-col--flex-grow-1">
          {selection.length > 0 ? <ActionBar {...{ selection, handleCancel }} /> : <Search target={identifier} />}
        </div>
        <div>
          <Button minimal={true} text="Filters" large={true} onClick={toggleOpen} />
        </div>
        <div>
          <Button minimal={true} text="Export" large={true} onClick={handleExport} />
        </div>
      </Frow>
      {isOpen && (
        <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR} className="mar-t-2">
          <Frow gutters={16} verticalGutters={16} alignContent="flex-end" alignItems="flex-end">
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Company" style={{ marginBottom: 0 }}>
                <MultiSelect items={companies} loading={!meta} fill={true} condensed={true} handleChange={handleCompanyChange} />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Workstream" style={{ marginBottom: 0 }}>
                <MultiSelect items={workstreams} loading={!meta} fill={true} condensed={true} handleChange={handleWorkstreamChange} />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <Button large={true} fill={true} text="Apply Filters" onClick={handleFilter} />
            </Col>
          </Frow>
        </Card>
      )}
    </Card>
  )
}

function ActionBar({ handleCancel, selection }) {
  const handleClick = () => {
    // Alerter.create({
    //   cancelButtonText: 'Cancel',
    //   confirmButtonText: 'Yes',
    //   onConfirm: () => {
    //     $.ajax({
    //       data: { job_seeker_ids: selection.map((candidate) => candidate.id), authenticity_token: csrfToken() },
    //       dataType: 'json',
    //       success: () => {
    //         createToast({
    //           text: `Successfully queued assignment for ${selection.length} candidates`,
    //           type: 'success',
    //         });
    //         handleCancel()
    //       },
    //       type: 'POST',
    //       url: selectionAssignAdminProcessingJobSeekersPath(),
    //     });
    //   },
    //   text: `Are you sure you want to assign ${selection.length} candidate`,
    // });
  }

  const defaultStyles = { padding: '4px 4px 4px 28px' };
  return(
    <Card
      elevated={true}
      level={CardLevel.FILLED}
      padded={false}
      style={{ ...defaultStyles }}
    >
      <div className="frow frow--items-center frow--justify-between">
        <div style={{ flex: '1 0 0%' }}>
          <div className="frow frow--items-center">
            <Body className="mar-r-2">{selection.length} candidates selected</Body>
            <Button minimal={true} onClick={handleClick} text="Assign" />
          </div>
        </div>
        <div>
          <div className="frow frow--items-center">
            <ItemDivider />
            <Button
              icon={IconNames16.CLOSE}
              onClick={handleCancel}
              text=""
              minimal={true}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}

function ItemDivider() {
  return <Divider muted={true} className="mar-h-1" style={{ height: '24px' }} />;
}
