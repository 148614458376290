import { DateRange, DateRangePicker, FormGroup, IconButton24, IconNames8, Popover } from '@pinpointhq/thumbtack';
import { format, parse } from 'date-fns';
import * as React from 'react';

interface IProps {
  endDate?: string;
  label?: string;
  startDate?: string;
  handleDateChange(start: string, end: string): void;
}

export default function DateRangeFilter(props: IProps) {
  const { handleDateChange, label = 'Time period' } = props;
  const [endDate, setEndDate] = React.useState(props.endDate);
  const [startDate, setStartDate] = React.useState(props.startDate);

  React.useEffect(() => {
    setEndDate(props.endDate);
    setStartDate(props.startDate);
  }, [props.endDate, props.startDate]);

  React.useEffect(() => {
    handleDateChange(startDate, endDate);
  }, [startDate, endDate]);

  function handleChange(dateRange: DateRange) {
    setStartDate(dateRange[0] ? format(dateRange[0], 'YYYY-MM-DD') : null);
    setEndDate(dateRange[1] ? format(dateRange[1], 'YYYY-MM-DD') : null);
  }

  function handleFilterClear() {
    setStartDate(null);
    setEndDate(null);
  }

  const selectedDateRange = (
    <div>
      {startDate ? format(parse(startDate), 'DD MMM YY') : 'Please Select'}
      <i className="fal fa-arrow-right mar-h-1" />
      {endDate ? format(parse(endDate), 'DD MMM YY') : 'Please Select'}
    </div>
  );

  const value: DateRange = [startDate ? parse(startDate) : undefined, endDate ? parse(endDate) : undefined];
  const oneYearFromNow = new Date();

  return (
    <FormGroup label={label} style={{ marginBottom: 0 }}>
      <div>
        <Popover targetProps={{ style: { width: '100%' } }} autoFocus={false}>
          <div className="filter">
            <div className="filter__button">
              <div className="frow frow--justify-between">
                <div>{endDate || startDate ? selectedDateRange : 'All Time'}</div>
                <div className="frow frow--items-center">
                  {(endDate || startDate) && (
                    <IconButton24 onClick={handleFilterClear} icon={IconNames8.CROSS} aria-label="Clear" />
                  )}
                  <i className="fal fa-fw fa-angle-down filter__caret" />
                </div>
              </div>
            </div>
          </div>
          <DateRangePicker
            allowSingleDayRange={true}
            maxDate={new Date(oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1))}
            {...{ value }}
            onChange={handleChange}
            contiguousCalendarMonths={false}
          />
        </Popover>
      </div>
    </FormGroup>
  );
}
