import { Card, CardLevel, Heading2, Super2 } from '@pinpointhq/thumbtack';
import * as React from 'react';
import ReactTable from 'react-table';
import { CompaniesCycle, CompanySubmission } from '../../../javascript/models';
import NewPaginationComponent from '../shared/NewPaginationComponent';
import { useSpraypaintTableNamedParams } from '../shared/hooks/useSpraypaintTable';
import Filters from './table/Filters';
import { columns, submissionColumns } from './table/columns';

export default function Index({ currentCycleId, cycleOptions, programmeId }) {
  const [cycleId, setCycleId] = React.useState<string>(currentCycleId);
  const [columnOption, setColumnOption] = React.useState<string>('cycleInfo');
  const [searchTerm, setSearchTerm] = React.useState<string>();

  const CompanySubmissionScope = CompanySubmission.selectExtra(['internships_pledged']);

  const { tableProps, meta, isLoading, query, setQuery } = useSpraypaintTableNamedParams({
    initialScope: CompaniesCycle.includes(['company', 'company_cycle_information', 'company_submission'])
      .selectExtra(['pledge_status'])
      .merge({ company_submission: CompanySubmissionScope }),
    searchIdentifier: 'companyCycles',
    initialQuery: { cycle_id: cycleId, programme_id: programmeId },
  });

  React.useEffect(() => {
    setQuery({ ...query, cycle_id: cycleId, search: searchTerm });
  }, [cycleId, searchTerm]);

  const handleApplyFilters = (filters, sort) => {
    // getRecords(filters, sort)
  };

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  return (
    <>
      <Card level={CardLevel.FILLED}>
        <div className="frow frow--items-center">
          <Super2 className={isLoading && 'bp3-skeleton'}>{meta ? meta.stats.total.count : '100'}</Super2>
          <Heading2 style={{ margin: '0 0 0 16px' }} className={!isLoading ? '' : 'bp3-skeleton'}>
            Organisations
          </Heading2>
        </div>
      </Card>
      <Filters
        {...{
          cycleId,
          columnOption,
          setColumnOption,
          cycleOptions,
          handleApplyFilters,
          searchTerm,
          setSearchTerm,
          setCycleId,
          programmeId,
        }}
      />
      <div className="pad-a-5">
        <ReactTable
          {...tableProps}
          columns={columnOption === 'cycleInfo' ? columns(programmeId) : submissionColumns(programmeId)}
          PaginationComponent={NewPaginationComponent}
          getPaginationProps={paginationProps}
          className="-unthemed"
          minRows={1}
        />
      </div>
    </>
  );
}
