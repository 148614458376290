import * as $ from 'jquery';
import * as React from 'react';
import {visit} from 'turbolinks';
import {Button, Callout, Col, DateInput, Divider, Frow, Intent, Radio} from "@pinpointhq/thumbtack";
import {CSSProperties} from "react";
import {
  programmeCompanyTimelinesPath,
  thanksProgrammeCompanyTimelinesPath
} from "../../javascript/application/ts_routes";
import {getCsrfToken} from "../shared/csrfToken";
import {createToast} from "../FlashToaster";
import {ajaxErrorString} from "../shared/ajaxErrorString";

export default function CompanyTimelines({ companyTimeline, programmeId }) {
  const [isInitialValid, setIsInitialValid] = React.useState(
    !!companyTimeline.first_contact_date &&
    !!companyTimeline.start_interviews &&
    !!companyTimeline.make_offers &&
    !!companyTimeline.finalise_offers &&
    !!companyTimeline.complete_hires &&
    !!companyTimeline.internship_start_date &&
    !!companyTimeline.internshipEndDate
  )
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [firstContact, setFirstContact] = React.useState(companyTimeline.first_contact_date ? new Date(companyTimeline.first_contact_date) : null)
  const [startInterview, setStartInterview] = React.useState(companyTimeline.start_interviews ? new Date(companyTimeline.start_interviews) : null)
  const [makeOffers, setMakeOffers] = React.useState(companyTimeline.make_offers ? new Date(companyTimeline.make_offers) : null)
  const [finaliseOffers, setFinalizeOffers] = React.useState(companyTimeline.finalise_offers ? new Date(companyTimeline.finalise_offers) : null)
  const [completeHires, setCompleteHires] = React.useState(companyTimeline.complete_hires ? new Date(companyTimeline.complete_hires) : null)
  const [internshipStartDate, setInternshipStartDate] = React.useState(companyTimeline.internship_start_date ? new Date(companyTimeline.internship_start_date) : null)
  const [internshipEndDate, setInternshipEndDate] = React.useState(companyTimeline.internship_end_date ? new Date(companyTimeline.internship_end_date) : null)
  const [usingExternalAts, setUsingExternalAts] = React.useState(companyTimeline.using_external_ats ? '1' : '0')

  const labelStyles = { fontWeight: 'bold', fontSize: '18px', marginBottom: 0 } as CSSProperties
  const labelHelperStyles = { marginTop: 0 } as CSSProperties
  const inputWrapperStyles = { maxWidth: '400px', margin: '0 auto' } as CSSProperties

  const handleFirstContactChange = (date) => setFirstContact(date)
  const handleStartInterviewChange = (date) => setStartInterview(date)
  const handleMakeOffersChange = (date) => setMakeOffers(date)
  const handleFinaliseOffersChange = (date) => setFinalizeOffers(date)
  const handleCompleteHiresChange = (date) => setCompleteHires(date)
  const handleInternshipStartDateChange = (date) => setInternshipStartDate(date)
  const handleInternshipEndDateChange = (date) => setInternshipEndDate(date)

  const handleSubmit = () => {
    $.ajax({
      url: programmeCompanyTimelinesPath(programmeId),
      data: {
        authenticity_token: getCsrfToken(),
        company_timeline: {
          first_contact_date: firstContact,
          start_interviews: startInterview,
          make_offers: makeOffers,
          finalise_offers: finaliseOffers,
          complete_hires: completeHires,
          internship_start_date: internshipStartDate,
          internship_end_date: internshipEndDate,
          using_external_ats: usingExternalAts
        },
        token: companyTimeline.token
      },
      type: 'POST',
      dataType: 'json',
      beforeSend: () => setIsLoading(true),
      complete: () => setIsLoading(false),
      success: (response) => {
        setIsLoading(false)
        visit(thanksProgrammeCompanyTimelinesPath(programmeId, { token: response.data }))
      },
      error: (jqXhr) => {
        createToast({
          text: ajaxErrorString(jqXhr),
          type: 'error',
        });
      }
    });
  }

  const isFormValid = firstContact &&
    startInterview &&
    makeOffers &&
    finaliseOffers &&
    completeHires &&
    internshipStartDate &&
    internshipEndDate

  const radios = [{ label: 'Yes', value: '1', }, { label: 'No', value: '0' }].map((option) => {
    const onRadioChange = (event: React.FormEvent<HTMLInputElement>) => {
      setUsingExternalAts(event.currentTarget.value)
    };

    return (
      <Radio
        key={option.label}
        onChange={onRadioChange}
        {...option}
        checked={usingExternalAts === option.value || false}
      />
    );
  });

  return(
    <>
      <Divider className="mar-v-5" muted={true} />
      {isInitialValid && (
        <Callout intent={Intent.PRIMARY}>
          Timelines have already been completed. You can change them here, or just click the button below to be taken to your candidate allocation.
          Please note that allocations will take place on the w/c 21st November 2022.
        </Callout>
      )}
      <Frow verticalGutters={40}>
        <Col width="1-1">
          <p style={labelStyles}>1. External ATS</p>
          <p style={labelHelperStyles}>Do you plan on exporting the candidates we allocate to you and running your recruitment process through your own Applicant Tracking System (ATS)?</p>
          <div style={inputWrapperStyles}>
            <Frow flexDirection="row" flexWrap="nowrap" gutters={16} justifyContent="center">
              {radios}
            </Frow>
          </div>
        </Col>
      </Frow>
      <h2 className="mar-t-5">Please provide indicative dates for the following</h2>
      <Frow verticalGutters={40}>
        <Col width="1-1">
          <p style={labelStyles}>2. Initiate first contact with candidates*</p>
          <p style={labelHelperStyles}>By what date do you expect to initiate first contact with candidates</p>
          <div style={inputWrapperStyles}>
            <DateInput onChange={handleFirstContactChange} value={firstContact} minDate={new Date('2022-11-21')} initialMonth={new Date('2022-11-21')} />
          </div>
        </Col>
        <Col width="1-1">
          <p style={labelStyles}>3. Commence candidate assessments / interviews*</p>
          <p style={labelHelperStyles}>By what date do you expect to commence assessments / interview with the candidates</p>
          <div style={inputWrapperStyles}>
            <DateInput onChange={handleStartInterviewChange} value={startInterview} minDate={new Date('2022-11-21')} initialMonth={new Date('2022-11-21')} />
          </div>
        </Col>
        <Col width="1-1">
          <p style={labelStyles}>4. Make final offers*</p>
          <p style={labelHelperStyles}>By what date do you expect to make your final offers for internship positions</p>
          <div style={inputWrapperStyles}>
            <DateInput onChange={handleMakeOffersChange} value={makeOffers} minDate={new Date('2022-11-21')} initialMonth={new Date('2022-11-21')} />
          </div>
        </Col>
        <Col width="1-1">
          <p style={labelStyles}>5. Require offers to be accepted and / or rejected by chosen candidates*</p>
          <p style={labelHelperStyles}>By what date do you expect to require offers to be accepted / rejected</p>
          <div style={inputWrapperStyles}>
            <DateInput onChange={handleFinaliseOffersChange} value={finaliseOffers} minDate={new Date('2022-11-21')} initialMonth={new Date('2022-11-21')} />
          </div>
        </Col>
        <Col width="1-1">
          <p style={labelStyles}>6. Complete hires*</p>
          <p style={labelHelperStyles}>By what date do you expect to complete your hiring process</p>
          <div style={inputWrapperStyles}>
            <DateInput onChange={handleCompleteHiresChange} value={completeHires} minDate={new Date('2022-11-21')} initialMonth={new Date('2022-11-21')} />
          </div>
        </Col>
        <Col width="1-1">
          <p style={labelStyles}>7. Commence internships*</p>
          <p style={labelHelperStyles}>By what date do you expect internships to start</p>
          <div style={inputWrapperStyles}>
            <DateInput onChange={handleInternshipStartDateChange} value={internshipStartDate} minDate={new Date('2022-11-21')} initialMonth={new Date('2022-11-21')} />
          </div>
        </Col>
        <Col width="1-1">
          <p style={labelStyles}>8. Finish internships*</p>
          <p style={labelHelperStyles}>By what date do you expect internships to finish</p>
          <div style={inputWrapperStyles}>
            <DateInput onChange={handleInternshipEndDateChange} value={internshipEndDate} minDate={new Date('2022-11-21')} initialMonth={new Date('2022-11-21')} maxDate={new Date('2024-12-31')} />
          </div>
        </Col>
        <Col width="1-1">
          <Button
            text="Submit"
            className="mar-t-5"
            large={true}
            fill={true}
            style={{ backgroundColor: '#000', color: '#FFF' }}
            onClick={handleSubmit}
            loading={isLoading}
            disabled={!isFormValid}
          />
        </Col>
      </Frow>
    </>
  )
}