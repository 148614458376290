import * as React from "react";
import {
  Classes,
  Frow,
  IResponsiveTabItem,
  ResponsiveTabs,
} from "@pinpointhq/thumbtack";
import { compact, findKey, flattenDeep } from "lodash";

interface IPrimaryTabsProps {
  items: IResponsiveTabItem[];
}

// @ts-ignore
export default function PrimaryTabs({ items }: IPrimaryTabsProps) {
  /**
   * Looks through all hrefs (main tab href plus menu item hrefs) in each tab item to find out which tab
   * should be shown as selected.
   * Defaults to the All Candidates tab. This is because if we're on a stage applications page we
   * won't find an entry
   */
  function currentTabId() {
    const hrefs: { [id: string]: string[] } = {};
    items.forEach((item) => {
      hrefs[item.id] = flattenDeep(
        compact([
          item.href,
          ...(item.menuItems || []).map(({ href, menuItems }) => {
            return [
              href,
              (menuItems || []).map(({ href: subHref }) => subHref),
            ];
          }),
        ])
      );
    });
    return (
      findKey(hrefs, (item) => item.includes(window.location.pathname)) ||
      "candidates"
    );
  }

  const itemsWithoutTeam = items.filter(
    (item) => item.id !== "team" && item.id !== "settings"
  );

  return (
    <Frow flexWrap="nowrap" justifyContent="space-between">
      <div style={{ flexGrow: 1 }}>
        <ResponsiveTabs
          id="primary_tabs"
          items={itemsWithoutTeam}
          defaultSelectedTabId={currentTabId()}
          className={Classes.FOCUS_DISABLED}
        />
      </div>
    </Frow>
  );
}
