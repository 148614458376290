import {
  Body,
  Card,
  CardLevel,
  Classes,
  Col,
  Collapse,
  Colors,
  Divider,
  Empty,
  Frow,
  Heading2,
  IconButton40,
  IconNames16,
  Lead,
  PaddingLevel,
} from '@pinpointhq/thumbtack';
import { format } from 'date-fns';
import * as React from 'react';
import { Company, CompanySubmission } from '../../../../javascript/models';
import useVisibility from '../../../shared/hooks/useVisibility';

export function Submissions({ company }: { company: Company }) {
  const submissions = company.companiesCycles.map((companiesCycle) => companiesCycle.companySubmission);

  return (
    <>
      <Heading2>Submissions</Heading2>
      {submissions.length < 1 ? (
        <Empty text="There are no submissions for this company" />
      ) : (
        <Frow flexDirection="column" verticalGutters={16}>
          {submissions.map((submission: CompanySubmission) => {
            return <SubmissionCard key={submission.id} submission={submission} />;
          })}
        </Frow>
      )}
    </>
  );
}

function SubmissionCard({ submission }: { submission: CompanySubmission }) {
  const { isOpen, toggleOpen } = useVisibility(false);
  return (
    <Col>
      <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.FILLED} style={{ backgroundColor: Colors.IVORY_10 }}>
        <Frow alignItems="center" justifyContent="space-between" gutters={8}>
          <div>
            <Lead>{submission.name}</Lead>
          </div>
          <div>
            <IconButton40 icon={isOpen ? IconNames16.CARET_UP : IconNames16.CARET_DOWN} onClick={toggleOpen} />
          </div>
        </Frow>
      </Card>
      <Collapse isOpen={isOpen}>
        <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.FILLED}>
          <ul className={Classes.LIST_TABULAR}>
            <li>
              <Body semiBold={true}>Submission date</Body>
              <Body>{format(submission.createdAt, 'MMMM Do YYYY')}</Body>
            </li>
            <li>
              <Body semiBold={true}>Company name</Body>
              <Body>{submission.companyName}</Body>
            </li>
            <li>
              <Body semiBold={true}>Company sector</Body>
              <Body>{submission.workstream}</Body>
            </li>
            <li>
              <Body semiBold={true}>Will to consider applicants that require sponsorship</Body>
              <Body>{submission.sponsorship ? 'Yes' : 'No'}</Body>
            </li>
            <li>
              <Body semiBold={true}>Can provide sponsorship</Body>
              <Body>{submission.nonEligibleSponsorship ? 'Yes' : 'No'}</Body>
            </li>
            <li>
              <Body semiBold={true}>Contact name</Body>
              <Body>{submission.contactName}</Body>
            </li>
            <li>
              <Body semiBold={true}>Contact email</Body>
              <Body>{submission.contactEmail}</Body>
            </li>
            <li>
              <Body semiBold={true}>Contact number</Body>
              <Body>{submission.contactMobileNumber}</Body>
            </li>
            <li>
              <Body semiBold={true}>Secondary Contact name</Body>
              <Body>{submission.secondaryContactName}</Body>
            </li>
            <li>
              <Body semiBold={true}>Secondary Contact email</Body>
              <Body>{submission.secondaryContactEmail}</Body>
            </li>
            <li>
              <Body semiBold={true}>Secondary Contact number</Body>
              <Body>{submission.secondaryContactMobileNumber}</Body>
            </li>
            <li>
              <Body semiBold={true}>Internships</Body>
              <Body className="mar-l-2 text-left col-flex-grow-1">
                {submission.savedInternships.map((internship, index) => {
                  return (
                    <React.Fragment key={`${internship.number}-${index}`}>
                      Title: {internship.title} <br />
                      Location: {internship.location} <br />
                      Workstream: {internship.workstream} <br />
                      Number: {internship.number} <br />
                      Remote: {internship.remote ? 'Yes' : 'No'}
                      {index + 1 !== submission.savedInternships.length && <Divider className="mar-v-3" />}
                    </React.Fragment>
                  );
                })}
              </Body>
            </li>
          </ul>
        </Card>
      </Collapse>
    </Col>
  );
}
