import { useCallback, useState } from "react";

export interface IUseVisibilityHook {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  toggleOpen: () => void;
  setIsOpen: (value: boolean) => void;
}

export default function useVisibility(
  initialState: boolean = false
): IUseVisibilityHook {
  const [isOpen, setIsOpen] = useState(initialState);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return {
    handleClose,
    handleOpen,
    isOpen,
    setIsOpen,
    toggleOpen,
  };
}
