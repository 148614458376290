import * as React from 'react';
import {Alerter, Button, Dialog, FormGroup, Frow, RadioSelect, Select} from "@pinpointhq/thumbtack";
import {createToast} from "../../../../../../FlashToaster";

export function ChangeWorkstreamDialog({ fetchData, handleClose, isOpen, job, workstreamOptions }) {
  const [selectedWorkstream, setSelectedWorkstream] = React.useState<string>();
  const [removeExistingCandidates, setRemoveExistingCandidates] = React.useState<string>('false')

  const handleSelect = (selectedOption) => {
    setSelectedWorkstream(selectedOption.value)
  }

  const selectedOption = workstreamOptions.find((option) => option.value === selectedWorkstream)

  const radios = [{ value: 'false', label: 'No' }, { value: 'true', label: 'Yes' }].map((applicationOption) => {
    const handleChange = (selectedItem) => {
      if (selectedItem.currentTarget.checked) setRemoveExistingCandidates(applicationOption.value)
    }

    return (
      <div className="col-flex-grow-1" key={applicationOption.label}>
        <RadioSelect
          label={applicationOption.label}
          onChange={handleChange}
          checked={applicationOption.value === removeExistingCandidates}
        />
      </div>
    );
  })

  const key = 'job-updating'

  const handleSubmit = () => {
    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      onConfirm: () => {
        createToast({ type: 'success', isLoading: true, text: 'Updating workstream', key })
        job.workstream = selectedWorkstream;
        job.removeExistingCandidates = removeExistingCandidates === 'true';
        job.save().then((success) => {
          if (success) {
            createToast({ type: 'success', text: 'Successfully changed job workstream', key })
            fetchData();
          } else {
            createToast({ type: 'error', text: 'Unable to change workstream', key })
          }
        })
      },
      text: "Are you sure you want to change the workstream? We won't automatically allocate a new slate you will need to do that after changing the workstream"
    });
  }

  return(
    <Dialog title="Change workstream" isOpen={isOpen} onClose={handleClose}>
      <FormGroup label="New workstream">
        <Select
          onItemSelect={handleSelect}
          items={workstreamOptions} fill={true} buttonProps={{ large: true }}
          activeItem={selectedOption}
        />
      </FormGroup>
      <FormGroup label="Remove existing candidates" helperText="Do you want to remove the existing slate?">
        <Frow flexDirection="row" flexWrap="nowrap" justifyContent="center">
          {radios}
        </Frow>
      </FormGroup>
      <Button fill={true} text="Update" large={true} disabled={!selectedWorkstream} onClick={handleSubmit} />
    </Dialog>
  )
}