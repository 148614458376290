import { useCallback, useEffect, useRef, useState } from 'react';

export const useSpraypaintNamedParams = ({
                                                                                                            initialScope,
                                                                                                            initialQuery = null,
                                                                                                            initialPageSize = null,
                                                                                                            initialMeta = null,
                                                                                                            initialExtraParams = null,
                                                                                                            initialOrder = null,
                                                                                                          }) =>
  useSpraypaint(
    initialScope,
    initialQuery,
    initialPageSize,
    initialMeta,
    initialExtraParams,
    initialOrder,
  );

/**
 * @deprecated (TimKrins) use useSpraypaintNamedParams instead please
 */
export function useSpraypaint(
  initialScope,
  initialQuery = null,
  initialPageSize: number = null,
  initialMeta = null,
  initialExtraParams: Record<string, unknown> = null,
  initialOrder: Record<string, unknown> = null,
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [scope, setScope] = useState(initialScope);
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const [page, setPage] = useState<number>();
  const [query, setQuery] = useState(initialQuery);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState(initialMeta);
  const [order, setOrder] = useState<any>(initialOrder);
  const [merge, setMerge] = useState(null);
  const [extraParams, setExtraParams] = useState(initialExtraParams);
  const lastExecutedScopeRef = useRef<object>();

  useEffect(() => {
    fetchData();
  }, [scope, order, pageSize, page, query, merge, extraParams]);

  const fetchData = useCallback(() => {
    if (!scope) return Promise.resolve();
    setIsLoading(true);
    let scopeForExecution = scope.where(query);

    if (order) scopeForExecution = scopeForExecution.order(order);
    if (pageSize) scopeForExecution = scopeForExecution.per(pageSize);
    if (page) scopeForExecution = scopeForExecution.page(page);
    if (merge) scopeForExecution = scopeForExecution.merge(merge);
    if (extraParams) scopeForExecution = scopeForExecution.extraParams(extraParams);

    // make sure we only use the last executed promise result
    lastExecutedScopeRef.current = scopeForExecution;

    return scopeForExecution
      .all()
      .then(({ data: spraypaintData, meta: spraypaintMeta }) => {
        if (lastExecutedScopeRef.current === scopeForExecution) {
          setError(null);
          setData(spraypaintData);
          setMeta(spraypaintMeta);
        }
      })
      .catch((e) => {
        if (lastExecutedScopeRef.current === scopeForExecution) {
          setError(e);
        }
      })
      .then(() => {
        if (lastExecutedScopeRef.current === scopeForExecution) {
          setIsLoading(false);
          setIsInitialLoading(false);
        }
      });
  }, [scope, order, pageSize, page, query, merge, extraParams]);

  return {
    data,
    error,
    extraParams,
    fetchData,
    isInitialLoading,
    isLoading,
    meta,
    order,
    page,
    pageSize,
    query,
    scope,
    setExtraParams,
    setMerge,
    setOrder,
    setPage,
    setPageSize,
    setQuery,
    setScope,
  };
}

export default useSpraypaint;
