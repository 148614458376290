import { Card, CardLevel, Col, Frow, IconButton40, IconNames16, PaddingLevel } from '@pinpointhq/thumbtack';
import * as React from 'react';

export function Column({ column, handleRemoveColumn, handleMoveDown, handleMoveUp, index }) {
  const handleRemove = () => handleRemoveColumn(index);

  const handleMoveUpClick = () => handleMoveUp(index);
  const handleMoveDownClick = () => handleMoveDown(index);

  return (
    <Col key={column.value}>
      <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.NONE}>
        <Card key={column.value} level={CardLevel.FILLED} paddingLevel={PaddingLevel.COMPRESSED}>
          <Frow alignItems="center" justifyContent="space-between">
            <div>{column.label}</div>
            <div>
              <Frow alignItems="center" gutters={8}>
                <div>
                  <IconButton40 icon={IconNames16.ARROW_UP} onClick={handleMoveUpClick} />
                </div>
                <div>
                  <IconButton40 icon={IconNames16.ARROW_DOWN} onClick={handleMoveDownClick} />
                </div>
                <div>
                  <IconButton40 icon={IconNames16.CLOSE} onClick={handleRemove} />
                </div>
              </Frow>
            </div>
          </Frow>
        </Card>
      </Card>
    </Col>
  );
}
