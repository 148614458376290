import * as React from 'react';
import {CardListWithChart} from "../../dashboard/insights/CandidateInsightDoughnutChart";
import {Body, Card, CardLevel, Drawer, Subheading1} from "@pinpointhq/thumbtack";
import {useSpraypaintNamedParams} from "../../shared/hooks/useSpraypaint";
import {CompaniesCycle} from "../../../../javascript/models";
import { compact } from 'lodash';
import {ProgressTable} from "./ProgressTable";

export function AllocationProgress({ cycle, programme }) {
  const [selectedStatus, setSelectedStatus] = React.useState()

  const { meta, isLoading } = useSpraypaintNamedParams(
    {
      initialScope: CompaniesCycle.where({ cycle_id: cycle.id, programme_id: programme.id }).per(0).stats({ grouped_by: ['programme_progress'] }),
      initialMeta: { stats: { grouped_by: { programme_progress: {} } } }
    }
  )

  const setSelectedItem = (selection) => {
    setSelectedStatus(selection)
  }

  const handleClose = () => {
    setSelectedStatus(null)
  }

  const statuses = [
    {
      key: 'timelines_incomplete',
      label: 'Timelines Incomplete'
    },
    {
      key: 'recruitment_not_started',
      label: 'Recruitment not started'
    },
    {
      key: 'in_progress',
      label: 'In progress'
    },
    {
      key: 'offering',
      label: 'Offering'
    },
    {
      key: 'offering_and_hiring',
      label: 'Offering and hiring'
    },
    {
      key: 'complete',
      label: 'Complete'
    }
  ]

  const data = compact(statuses.map((status) => {
    const count: any = meta.stats.grouped_by.programme_progress[status.key]

    if (!count) return null;

    return {
      key: status.key,
      label: status.label,
      value: count,
      additional_info_url: null,
    }
  }))

  return(
    <>
      <Card level={CardLevel.RAISED}>
        <div className="mar-b-2">
          <div className="frow frow--items-start frow--justify-between frow--nowrap frow--items-center">
            <div className="col-flex-grow-1">
              <Subheading1>Allocation progress</Subheading1>
              <Body muted={true} className="mar-t-1">
                Progress of the organisations through the process
              </Body>
            </div>
          </div>
        </div>
        <CardListWithChart
          {...{ array: isLoading ? [] : data, isLoading: isLoading, setSelectedItem }}
          placeholderText="There is currently no data to show"
        />
      </Card>
      {selectedStatus && <ProgressTable {...{ cycle, handleClose, selectedStatus, programme }} />}
    </>
  )
}