import * as React from "react";
import {
  AnchorButton,
  Button,
  Divider,
  FormGroup,
  Heading1,
  IconNames16,
  Input,
} from "@pinpointhq/thumbtack";
import { csrfToken } from "../shared/react_rails_form_helpers";

import { userSessionPath } from "../../javascript/application/ts_routes";

export default function Login() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (!isSubmitting) return;

    const timer = setTimeout(() => {
      setIsSubmitting(false);
    }, 20_000);
    return () => clearTimeout(timer);
  }, [isSubmitting]);

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    event.target.submit();
  }
  return (
    <React.Fragment>
      <Heading1 className="devise__header">Login</Heading1>
      <form action={userSessionPath()} method="POST" onSubmit={handleSubmit}>
        <input type="hidden" name="authenticity_token" value={csrfToken()} />
        <FormGroup label="Email Address" labelFor="user[email]">
          <Input fill={true} type="email" autoFocus={true} name="user[email]" />
        </FormGroup>
        <FormGroup label="Password" labelFor="user[password]">
          <Input
            fill={true}
            type="password"
            autoComplete="off"
            name="user[password]"
          />
        </FormGroup>
        <Button
          loading={isSubmitting}
          type="submit"
          large={true}
          fill={true}
          rightIcon={IconNames16.ARROW_RIGHT}
        >
          Login
        </Button>
      </form>
      <Divider className="mar-t-2 mar-b-4" muted={true} />
      <div className="mar-t-8 frow frow--justify-between">
        <AnchorButton
          small={true}
          minimal={true}
          icon={IconNames16.ARROW_LEFT}
          href="https://www.pinpointhq.com"
        >
          Back to website
        </AnchorButton>
        <Logotype width={100} />
      </div>
    </React.Fragment>
  );
}

interface ILogotypeProps {
  /** Whether the logotype should be rendered white */
  light?: boolean;
  /** Width in px. Height will be automatically set */
  width?: number;
}

function Logotype({ light, width }: ILogotypeProps) {
  const fill = light ? "#FFFFFF" : "#1C2121";
  const aspectRatio = 140 / 35;
  const svgWidth = width || 140;
  const svgHeight = svgWidth / aspectRatio;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 140 35"
    >
      <g fill={fill} fillRule="evenodd">
        <path d="M27.7 5.436c1.443 0 2.59-1.036 2.59-2.44 0-1.517-1.147-2.552-2.59-2.552-1.404 0-2.552 1.035-2.552 2.551 0 1.405 1.148 2.441 2.553 2.441M30.215 23.52V7.876h-7.212v.074L25.187 9.8c.332.26.369.37.369.851v12.87c0 .405-.037.553-.369.813l-2.184 1.59v.074h9.8v-.074l-2.18-1.59c-.37-.296-.408-.408-.408-.814M102.778 23.52V7.876h-7.212v.074L97.748 9.8c.332.26.37.37.37.851v12.87c0 .405-.038.553-.37.813l-2.182 1.59v.074h9.8v-.074l-2.18-1.59c-.373-.296-.408-.408-.408-.814M100.262 5.436c1.443 0 2.59-1.036 2.59-2.44 0-1.517-1.147-2.552-2.59-2.552-1.405 0-2.551 1.035-2.551 2.551 0 1.405 1.146 2.441 2.55 2.441M139.502 8.542v-.666h-4.919v-6.1h-.075l-6.914 6.766h2.33v13.165c0 3.07 1.515 4.585 4.326 4.585 3.181 0 4.623-2.033 4.992-4.14l-.073-.038c-.408.961-1.369 1.812-2.551 1.812-1.296 0-2.035-.961-2.035-2.625V8.54h4.919zM8.395 22.742V0H0v.073l2.848 1.998c.369.258.404.37.404.813v20.229c0 .443-.035.555-.404.813L.001 25.923v.074h12.905v-.074l-4.104-2.145c-.407-.258-.407-.443-.407-1.036" />
        <path d="M11.908 0h-1.866c3.492 0 5.86 3.07 5.86 7.95 0 5.215-2.69 8.136-5.86 8.136h1.163c5.77 0 10.17-2.774 10.17-8.21C21.375 2.146 17.05 0 11.908 0M41.161 23.52V7.876H33.95v.074L36.134 9.8c.331.26.369.37.369.851v12.87c0 .405-.038.553-.37.813l-2.183 1.59v.074h9.8v-.074l-2.181-1.59c-.37-.296-.408-.408-.408-.814M52.145 23.52c.037-2.96.074-6.361.074-8.394 0-5.104-1.922-7.545-5.4-7.545-1.664 0-3 .545-4.01 1.576 3.918 0 4.751 3.01 4.751 7.003 0 1.627-.112 6.99-.112 7.397 0 .333-.073.516-.443.776l-2.072 1.59v.074h9.802v-.074l-2.183-1.59c-.333-.26-.407-.37-.407-.814M61.984 31.73V7.875h-7.212v.075L56.955 9.8c.331.26.37.37.37.851v21.302c0 .406-.039.554-.37.813l-2.183 1.589v.074h10.836v-.074l-3.07-1.775c-.48-.259-.554-.369-.554-.85" />
        <path d="M67.642 7.581c-1.606 0-2.933.516-4.011 1.654 4.164 0 5.639 3.153 5.639 7.48 0 5.325-1.855 8.718-5.64 8.718.805.54 1.86.86 3.235.86 4.327 0 7.396-3.808 7.396-9.836 0-5.326-2.552-8.876-6.619-8.876M113.724 23.52V7.876h-7.211v.074l2.184 1.848c.331.26.368.37.368.851v12.87c0 .405-.037.553-.368.813l-2.184 1.59v.074h9.8v-.074l-2.181-1.59c-.37-.296-.408-.408-.408-.814M124.707 23.52c.038-2.96.075-6.361.075-8.394 0-5.104-1.923-7.545-5.4-7.545-1.664 0-3 .545-4.011 1.576 3.918 0 4.752 3.01 4.752 7.003 0 1.627-.112 6.99-.112 7.397 0 .333-.073.516-.443.776l-2.072 1.59v.074h9.801v-.074l-2.182-1.59c-.333-.26-.408-.37-.408-.814M81.623 16.936h-.001c0-5.91 1.064-8.802 2.801-9.29-4.886.329-8.088 3.527-8.088 9.29 0 5.764 3.188 8.965 8.088 9.292-1.742-.484-2.8-3.373-2.8-9.292M86.07 7.646c1.736.487 2.8 3.38 2.8 9.291 0 5.92-1.058 8.808-2.8 9.292 4.9-.329 8.089-3.53 8.089-9.292 0-5.763-3.203-8.961-8.09-9.291" />
      </g>
    </svg>
  );
}
