import { Link, Spinner } from '@pinpointhq/thumbtack';
import { adminJobPath } from '../../../../javascript/application/ts_routes';
import * as React from 'react';
import workstreamCluster from '../../utilities/workstreamCluster';

export function jobColumns(isLoading, included) {
  const jobWorkstream = (job) => {
    const departmentId = job.relationships.department.data.id;
    return included.find((departments) => departments.id === departmentId).attributes.name;
  };

  const applicationCount = (job) => {
    return job.relationships.applications.data.length
  }

  const jobCluster = (job) => {
    const workstream = jobWorkstream(job);
    return workstreamCluster(workstream);
  };

  return [
    {
      Cell: (row: any) => {
        return (
          <Link target="_blank" href={adminJobPath(row.original.id)}>
            {row.original.attributes.title}
          </Link>
        );
      },
      Header: 'Title',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        const humanize = (str) => {
          if (!str) return 'No cluster detected';
          return str
            .replace(/^[\s_]+|[\s_]+$/g, '')
            .replace(/[_\s]+/g, ' ')
            .replace(/^[a-z]/, function (m) {
              return m.toUpperCase();
            });
        };

        return <div>{isLoading ? <Spinner small={true} /> : humanize(jobCluster(row.original))}</div>;
      },
      Header: 'Cluster',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{isLoading ? <Spinner small={true} /> : jobWorkstream(row.original)}</div>;
      },
      Header: 'Workstream',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{applicationCount(row.original)}</div>
      },
      Header: 'No. of applications',
      sortable: false,
    }
  ];
}
