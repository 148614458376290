import * as $ from 'jquery';
import {visit} from 'turbolinks';
import {
  Alerter,
  Button,
  Caption,
  Card,
  CardLevel,
  Col,
  DateInput,
  Divider,
  FormGroup,
  Frow,
  IconButton40,
  IconNames16,
  Input,
  Intent,
  Lead,
  NumericInput,
  PaddingLevel,
  Radio,
  Select,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import {CSSProperties} from 'react';
import {
  programmeCompanySubmissionsPath,
  thanksProgrammeCompanySubmissionsPath
} from '../../javascript/application/ts_routes';
import {createToast} from '../FlashToaster';
import {ajaxErrorString} from '../shared/ajaxErrorString';
import {getCsrfToken} from '../shared/csrfToken';

interface IContacts {
  name: string;
  email: '';
  phone: '';
}

export default function SubmissionForm({ companySubmission, organisationWorkstreamOptions, internshipSectorOptions, programmeName, programmeId, hideInternships, companyTimeline }) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [companyName, setCompanyName] = React.useState<string>(companySubmission.company_name)
  const [workstream, setWorkstream] = React.useState<string>(companySubmission.workstream)
  const [sponsorship, setSponsorship] = React.useState<string>(companySubmission.sponsorship)
  const [nonEligibleSponsorship, setNonEligibleSponsorship] = React.useState<string>(companySubmission.non_eligible_sponsorship)
  const [contactName, setContactName] = React.useState<string>(companySubmission.contact_name)
  const [contactMobileNumber, setContactMobileNumber] = React.useState<string>(companySubmission.contact_mobile_number)
  const [contactEmail, setContactEmail] = React.useState<string>(companySubmission.contact_email)
  const [secondaryContactName, setSecondaryContactName] = React.useState<string>(companySubmission.secondary_contact_name)
  const [secondaryContactMobileNumber, setSecondaryContactMobileNumber] = React.useState<string>(companySubmission.secondary_contact_mobile_number)
  const [secondaryContactEmail, setSecondaryContactEmail] = React.useState<string>(companySubmission.secondary_contact_email)
  const [internships, setInternships] = React.useState(companySubmission.internships || [{ region: '', location: '', workstream: companySubmission.workstream, number: 1 }])
  const [additionalPrimaryContacts, setAdditionalPrimaryContacts] = React.useState<IContacts[]>([])
  const [disabilityConfident, setDisabilityConfident] = React.useState();

  // Timeline dates
  const [reviewStartDate, setReviewStartDate] = React.useState(companyTimeline ? new Date(companyTimeline.review_start_date) : null)
  const [reviewEndDate, setReviewEndDate] = React.useState(companyTimeline ? new Date(companyTimeline.review_end_date) : null)
  const [assessmentStartDate, setAssessmentStartDate] = React.useState(companyTimeline ? new Date(companyTimeline.assessment_start_date) : null)
  const [assessmentEndDate, setAssessmentEndDate] = React.useState(companyTimeline ? new Date(companyTimeline.assessment_end_date) : null)
  const [offeringStartDate, setOfferingStartDate] = React.useState(companyTimeline ? new Date(companyTimeline.offering_start_date) : null)
  const [offeringEndDate, setOfferingEndDate] = React.useState(companyTimeline ? new Date(companyTimeline.offering_end_date) : null)
  const [hiredStartDate, setHiredStartDate] = React.useState(companyTimeline ? new Date(companyTimeline.hired_start_date) : null)
  const [hiredEndDate, setHiredEndDate] = React.useState(companyTimeline ? new Date(companyTimeline.hired_end_date) : null)

  // Simple phone number validations
  // Taken from here: https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
  //
  const validatePhoneNumber = (input_str) => {
    const re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]{8,14}$/g;

    return re.test(input_str);
  }

  const handleNameChange = (event) => setCompanyName(event.currentTarget.value)
  const handleWorkstreamChange = (selectedItem) => setWorkstream(selectedItem.value)
  const handleContactNameChange = (event) => setContactName(event.currentTarget.value)
  const handleContactNumberChange = (event) => setContactMobileNumber(event.currentTarget.value)
  const handleContactEmailChange = (event) => setContactEmail(event.currentTarget.value)

  const handleSecondaryContactNameChange = (event) => setSecondaryContactName(event.currentTarget.value)
  const handleSecondaryContactNumberChange = (event) => setSecondaryContactMobileNumber(event.currentTarget.value)
  const handleSecondaryContactEmailChange = (event) => setSecondaryContactEmail(event.currentTarget.value)

  const labelStyles = { fontWeight: 'bold', fontSize: '18px', marginBottom: 0 } as CSSProperties
  const labelHelperStyles = { marginTop: 0 } as CSSProperties
  const inputWrapperStyles = { maxWidth: '400px', margin: '0 auto' } as CSSProperties

  const selectedWorkstream = organisationWorkstreamOptions.find((workstreamOption) => workstreamOption.value === workstream)

  const handleAddContact = () => {
    setAdditionalPrimaryContacts([...additionalPrimaryContacts, { name: '', email: '', phone: '' }])
  }

  const handleSubmit = () => {
    if (hideInternships) {
      handleConfirm()
    } else {
      const internshipCount = internships.map((i) => parseInt(i.number, 10)).reduce((p, c) => p + c)
      Alerter.create({
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Yes',
        onConfirm: handleConfirm,
        text: `Are you sure you want to pledge ${internshipCount} ${internshipCount > 1 ? 'internships?' : 'internship?'}`,
      })
    }
  }

  const handleConfirm = () => {
    if (!validatePhoneNumber(contactMobileNumber)) {
      createToast({ type: 'error', text: 'Your primary contact number is invalid' })
      return;
    }
    if (!validatePhoneNumber(secondaryContactMobileNumber)) {
      createToast({ type: 'error', text: 'Your secondary contact number is invalid' })
      return;
    }
    additionalPrimaryContacts.forEach((contact) => {
      if (!validatePhoneNumber(secondaryContactMobileNumber)) {
        createToast({ type: 'error', text: `The phone number for ${contact.name} is invalid` })
        return;
      }
    })
    $.ajax({
      url: programmeCompanySubmissionsPath(programmeId),
      data: {
        authenticity_token: getCsrfToken(),
        company_submission: {
          company_name: companyName,
          workstream,
          sponsorship,
          disability_confident: disabilityConfident,
          non_eligible_sponsorship: nonEligibleSponsorship,
          contact_name: contactName,
          contact_mobile_number: contactMobileNumber,
          contact_email: contactEmail,
          secondary_contact_name: secondaryContactName,
          secondary_contact_mobile_number: secondaryContactMobileNumber,
          secondary_contact_email: secondaryContactEmail,
          additional_primary_contacts: additionalPrimaryContacts,
          internships
        },
        timelines: {
          review_start_date: reviewStartDate,
          review_end_date: reviewEndDate,
          assessment_start_date: assessmentStartDate,
          assessment_end_date: assessmentEndDate,
          offering_start_date: offeringStartDate,
          offering_end_date: offeringEndDate,
          hired_start_date: hiredStartDate,
          hired_end_date: hiredEndDate,
        },
        token: companySubmission.token
      },
      type: 'POST',
      dataType: 'json',
      beforeSend: () => setIsLoading(true),
      complete: () => setIsLoading(false),
      success: (response) => {
        setIsLoading(false)
        visit(thanksProgrammeCompanySubmissionsPath(programmeId, { token: response.data }))
      },
      error: (jqXhr) => {
        createToast({
          text: ajaxErrorString(jqXhr),
          type: 'error',
        });
      }
    });
  }

  const isFormValid = [
    companyName,
    workstream,
    sponsorship,
    nonEligibleSponsorship,
    contactName,
    contactMobileNumber,
    contactEmail,
    secondaryContactName,
    secondaryContactMobileNumber,
    secondaryContactEmail,
    // reviewStartDate,
    // reviewEndDate,
    // assessmentStartDate,
    programmeId === 2 ? disabilityConfident : true,
    // assessmentEndDate,
    // offeringStartDate,
    // offeringEndDate,
    // hiredStartDate,
    // hiredEndDate,
    ...additionalPrimaryContacts.map((contact) => contact.name),
    ...additionalPrimaryContacts.map((contact) => contact.phone),
    ...additionalPrimaryContacts.map((contact) => contact.email),
  ].every((attribute) => !!attribute)

  const radios = (nameOfAttribute) => {
    return [{label: 'Yes', value: '1',}, {label: 'No', value: '0'}].map((option) => {
      const onRadioChange = (event: React.FormEvent<HTMLInputElement>) => {
        if (nameOfAttribute === 'sponsorship') {
          setSponsorship(event.currentTarget.value)
        } else if (nameOfAttribute === 'disability_confident') {
          setDisabilityConfident(event.currentTarget.value)
        } else {
          setNonEligibleSponsorship(event.currentTarget.value)
        }
      };

      let isChecked = false
      if (nameOfAttribute === 'sponsorship') {
        isChecked = sponsorship === option.value
      }
      if (nameOfAttribute === 'ineligible_sponsorship') {
        isChecked = nonEligibleSponsorship === option.value
      }
      if (nameOfAttribute === 'disability_confident') {
        isChecked = disabilityConfident === option.value
      }

      return (
        <Radio
          key={option.label}
          onChange={onRadioChange}
          {...option}
          checked={isChecked || false}
        />
      );
    })
  }

  const handleReviewStartChange = (date) => setReviewStartDate(date)
  const handleReviewEndChange = (date) => setReviewEndDate(date)
  const handleAssessmentStartChange = (date) => setAssessmentStartDate(date)
  const handleAssessmentEndChange = (date) => setAssessmentEndDate(date)
  const handleOfferingStartChange = (date) => setOfferingStartDate(date)
  const handleOfferingEndChange = (date) => setOfferingEndDate(date)
  const handleHiredStartChange = (date) => setHiredStartDate(date)
  const handleHiredEndChange = (date) => setHiredEndDate(date)

  return(
    <>
      <Divider className="mar-v-5" muted={true} />
      <h2>Section One</h2>
      <Frow verticalGutters={40}>
        <Col width="1-1">
          <p style={labelStyles} className="mar-b-3">1.	What is your organisation's name?*</p>
          <div style={inputWrapperStyles}>
            <Input type="text" placeholder="Your organisation name..." value={companyName} onChange={handleNameChange} />
          </div>
        </Col>
        <Col width="1-1">
          <p style={labelStyles}>2. What overarching sector does your organisation operate in?*</p>
          <p style={labelHelperStyles}>Please choose an option from the list below that best describes your company’s overarching sector</p>
          <div style={inputWrapperStyles}>
            <Select
              items={organisationWorkstreamOptions}
              fill={true}
              buttonProps={{ large: true }}
              activeItem={selectedWorkstream}
              placeholder="Please select a sector..."
              onItemSelect={handleWorkstreamChange}
            />
          </div>
        </Col>
        <Col>
          <h2>Contact details</h2>
          <p>
            <b>Primary Contact(s)</b> are those who are directly involved with the programme day-to-day. We need at least one primary contact, but you may provide more than one. All primary contacts will be sent all comms.
          </p>
          <p>
            <b>A Secondary (senior) contact</b> is a senior decision-maker not involved in day-to-day aspects of the programme. They will only be contacted in the event we lose contact with primary contact(s) or deadlines are missed.
          </p>
        </Col>
        <Col width="1-1">
          <p style={labelStyles}>3. Primary contact name*</p>
          <p style={labelHelperStyles}>Please provide the name of the most appropriate primary contact at your organisation</p>
          <div style={inputWrapperStyles}>
            <Input type="text" placeholder="Contact name..." value={contactName} onChange={handleContactNameChange} />
          </div>
        </Col>
        <Col width="1-1">
          <p style={labelStyles}>4. Primary contact number*</p>
          <p style={labelHelperStyles}>Please provide the mobile number of the most appropriate primary contact contact at your organisation</p>
          <div style={inputWrapperStyles}>
            <Input type="text" placeholder="Contact mobile number..." value={contactMobileNumber} onChange={handleContactNumberChange} />
          </div>
        </Col>
        <Col width="1-1">
          <p style={labelStyles}>5. Primary contact email*</p>
          <p style={labelHelperStyles}>Please provide the email address for the most appropriate primary contact at your organisation</p>
          <div style={inputWrapperStyles}>
            <Input type="email" placeholder="Contact email..." value={contactEmail} onChange={handleContactEmailChange} />
          </div>
        </Col>
        <Col width="1-1">
          <Button minimal={true} icon={IconNames16.ADD} text="Add another primary contact" onClick={handleAddContact} />
        </Col>
        {additionalPrimaryContacts.length > 0 && (<Col width="1-1">
          <AdditionalContacts contacts={additionalPrimaryContacts} {...{ setAdditionalPrimaryContacts }} />
        </Col>)}
        <Col width="1-1">
          <p style={labelStyles} className="mar-b-2">6. Secondary (senior) contact name*</p>
          <p style={labelHelperStyles}>Please provide the name of the most appropriate secondary (senior) contact at your organisation</p>
          <div style={inputWrapperStyles}>
            <Input type="text" placeholder="Secondary contact name..." value={secondaryContactName} onChange={handleSecondaryContactNameChange} />
          </div>
        </Col>
        <Col width="1-1">
          <p style={labelStyles} className="mar-b-2">7. Secondary (senior) contact number*</p>
          <p style={labelHelperStyles}>Please provide the mobile number of the most appropriate secondary (senior) contact at your organisation</p>
          <div style={inputWrapperStyles}>
            <Input type="text" placeholder="Secondary contact mobile number..." value={secondaryContactMobileNumber} onChange={handleSecondaryContactNumberChange} />
          </div>
        </Col>
        <Col width="1-1">
          <p style={labelStyles} className="mar-b-2">8. Secondary (senior) contact email*</p>
          <p style={labelHelperStyles}>Please provide the email address of the most appropriate secondary (senior) contact at your organisation</p>
          <div style={inputWrapperStyles}>
            <Input type="email" placeholder="Secondary contact email..." value={secondaryContactEmail} onChange={handleSecondaryContactEmailChange} />
          </div>
        </Col>

        <Col width="1-1">
          <h2>Visas</h2>
          <p>The 10,000 Interns Foundation partners with two organisations AIESEC and BUNAC to provide Government Authorised Exchange visa -Temporary Work (GAE) sponsorship for eligible candidates. This visa was formerly known as the Tier 5 visa. With this in mind:</p>
          <p style={labelStyles}>9. a) Is your organisation willing to consider applicants eligible to apply to AIESEC or BUNAC for GAE visa sponsorship?*</p>
          <p style={labelHelperStyles}>Applications to these organisations can only be made after an internship has been offered. Selecting “Yes” will result in additional candidate allocations. You will receive an additional allocation of candidates who require visa sponsorship on top of your initial allocation of candidates with an existing right to work in the UK.</p>
          <div style={inputWrapperStyles}>
            <Frow flexDirection="row" flexWrap="nowrap" gutters={16} justifyContent="center">
              {radios('sponsorship')}
            </Frow>
          </div>
        </Col>

        <Col width="1-1">
          <p style={labelStyles}>9. b) Is your organisation willing to provide visa sponsorship for selected applicants not eligible for sponsorship by AIESEC or BUNAC?*</p>
          <p style={labelHelperStyles}>Selecting “Yes” will result in additional candidate allocations. You will receive an additional allocation of candidates who require visa sponsorship on top of your initial allocation of candidates with an existing right to work in the UK.</p>
          <div style={inputWrapperStyles}>
            <Frow flexDirection="row" flexWrap="nowrap" gutters={16} justifyContent="center">
              {radios('ineligible_sponsorship')}
            </Frow>
          </div>
        </Col>

        {programmeId === 2 && (<Col width="1-1">
          <p style={labelStyles}>10. Is your organisation Disability Confident?*</p>
          <p style={labelHelperStyles}>Please check your status <a href="https://www.gov.uk/guidance/disability-confident-how-to-sign-up-to-the-employer-scheme" target="_blank" style={{ textDecoration: 'underline', color: '#850D3F' }}>here</a></p>
          <div style={inputWrapperStyles}>
            <Frow flexDirection="row" flexWrap="nowrap" gutters={16} justifyContent="center">
              {radios('disability_confident')}
            </Frow>
          </div>
        </Col>)}
        {!hideInternships && (<Col width="1-1">
          <Lead>
            Thank you for completing section one. Please now tell us a little more about the internships you are offering.
          </Lead>
        </Col>)}
      </Frow>
      {!hideInternships && (
        <>
          <Divider className="mar-v-5" muted={true} />
          <h2>Section Two*</h2>

          <p>The following section will ask you to categorise each of your internships based on workstream and location. For the purpose of this exercise, you may wish to consider “workstream” as synonymous with “internal department”.</p>

          <p>Different organisations have different terminology for their internal workstreams, and our list is not exhaustive, so we ask that you categorise your internships to the best of your ability based on what the role will entail. Please click <a target="_blank" style={{ textDecoration: 'underline', color: '#850D3F' }} href="https://10000internsfoundation.com/our-programmes#nav-tab">here</a> to view our glossary of workstream definitions.</p>

          <p>If the role you are offering is a rotation of various departments, offers more general insight into your wider industry, or is very specific to your sector, please categorise your internship according to the sector in which your organisation belongs.</p>

          <p><em>Example 1: A charity offering an internship in their fundraising department should categorise their internship under “non-for-profit”</em></p>

          <p><em>Example 2: An investment management firm offering two internships in a sales team and two internships in a product team should offer four internships under “Investment Management”</em></p>

          <p>Departments that are considered to be in “corporate functions” should be categorised according to what best describes the role.</p>

          <p><em>Example 3: A Tech firm offering 2 internships in their software engineering department, two internships in their finance department and two internships in compliance should assign two internships to “Technology”, two internships to “Accountancy & Finance” and two internships to “Legal Services & Compliance”</em></p>

          <div className="mar-v-3">
            <Internships {...{ internships, setInternships, internshipSectorOptions }} />
          </div>

          <p>
            Please remember that the minimum requirements of the internship(s) that you are offering <b>must pay a minimum of the Local Living Wage and be a length of at least six (6) weeks</b>.
            Local living wage of Greater London is £13.50 and for all other regions it is £12.
          </p>

          {/*<h2 className="mar-t-5">Recruitment Timeline</h2>*/}
          {/*<p>Please consider the following timelines for different stages of allocation and recruitment;</p>*/}

          {/*<ul style={{ textAlign: 'left' }}>*/}
          {/*  <li>*/}
          {/*    <b>Wednesday, 8th November 2023:</b> <br/>*/}
          {/*    You will receive your first candidate slate(s) on Pinpoint to start reviewing their applications*/}
          {/*  </li>*/}
          {/*  <li style={{ marginTop: '10px' }}>*/}
          {/*    <b>Wednesday, 8th November – Friday, 17th November 2023:</b> <br/>*/}
          {/*    <b>Adding candidates:</b> If you would like to diversify your slate and review additional candidates, you can request for additional candidates through Pinpoint. <br/> <br/>*/}
          {/*    <b>Releasing candidates:</b> You also have the option to release candidates back to our wider pool for exceptional cases where a candidate cannot be considered at all. <br/> <br/>*/}
          {/*    Release requests should be based on a quick skim through your candidate slates to identify only a few candidates who you cannot consider for your role due to location of internship, year of study, and level of education (undergraduate/postgraduate). Candidates are not informed when they have been released and will be redistributed to another organisation that can consider them. <br/>*/}
          {/*    Please note that this is an optional feature of the programme that should be pursued only for unavoidable circumstances. <br/> <br/>*/}
          {/*    <b><em>If you do not require any slate adjustment, you can start reviewing applications from Monday, 13 November 2023.</em></b>*/}
          {/*  </li>*/}
          {/*  <li style={{ marginTop: '10px' }}>*/}
          {/*    <b>Monday, 20 November – Friday 24 November 2023:</b> <br/>*/}
          {/*    During this week, you are required to make first contact with all candidates on your slates that include your organisation’s name, the role they are being considered for, and a confirmation that you have received their application. We recommend you to attach a job description in your email. <br/> <br/>*/}
          {/*    If you do not reach out to your candidates by the 24/11 deadline, we will send them an automated email including the details of your pledge.*/}
          {/*  </li>*/}
          {/*</ul>*/}

          {/*<p>*/}
          {/*  Please complete the following dates for each recruitment stage. We have provided our preferred timeline as a reference. You can either select the same dates or choose dates that are suitable for your organisation. Please be aware that the hiring of interns must be finalised by Sunday, 31 March 2024.*/}
          {/*</p>*/}

          {/*<Frow verticalGutters={24}>*/}
          {/*  <Col width="1-1">*/}
          {/*    <p style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', textAlign: 'left' }}>Application review*</p>*/}
          {/*    <Frow verticalGutters={40} gutters={40}>*/}
          {/*      <Col sm={{ width: '1-2' }}>*/}
          {/*        <FormGroup label="Start date" style={{ textAlign: 'left', marginBottom: 0 }} helperText="10KIF Start Date: Monday, 13 November 2023">*/}
          {/*          <DateInput value={reviewStartDate} onChange={handleReviewStartChange} initialMonth={new Date('2023-11-13')} fill={true} />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col sm={{ width: '1-2' }}>*/}
          {/*        <FormGroup label="End date" style={{ textAlign: 'left', marginBottom: 0 }} helperText="10KIF End Date: Friday, 08 December 2023">*/}
          {/*          <DateInput value={reviewEndDate} onChange={handleReviewEndChange} initialMonth={new Date('2023-12-08')} fill={true} />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*    </Frow>*/}
          {/*  </Col>*/}

          {/*  <Col width="1-1">*/}
          {/*    <p style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', textAlign: 'left' }}>Interview/Assessment*</p>*/}
          {/*    <Frow verticalGutters={40} gutters={40}>*/}
          {/*      <Col sm={{ width: '1-2' }}>*/}
          {/*        <FormGroup label="Start date" style={{ textAlign: 'left', marginBottom: 0 }} helperText="10KIF Start Date: Monday, 11 December 2023">*/}
          {/*          <DateInput value={assessmentStartDate} onChange={handleAssessmentStartChange} initialMonth={new Date('2023-12-11')} fill={true} />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col sm={{ width: '1-2' }}>*/}
          {/*        <FormGroup label="End date" style={{ textAlign: 'left', marginBottom: 0 }} helperText="10KIF End Date: Friday, 02 February 2024">*/}
          {/*          <DateInput value={assessmentEndDate} onChange={handleAssessmentEndChange} initialMonth={new Date('2024-02-02')} fill={true} />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*    </Frow>*/}
          {/*  </Col>*/}

          {/*  <Col width="1-1">*/}
          {/*    <p style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', textAlign: 'left' }}>Offering Internship*</p>*/}
          {/*    <Frow verticalGutters={40} gutters={40}>*/}
          {/*      <Col sm={{ width: '1-2' }}>*/}
          {/*        <FormGroup label="Start date" style={{ textAlign: 'left', marginBottom: 0 }} helperText="10KIF Start Date: Monday, 05 February 2024">*/}
          {/*          <DateInput value={offeringStartDate} onChange={handleOfferingStartChange} initialMonth={new Date('2024-02-05')} fill={true} />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col sm={{ width: '1-2' }}>*/}
          {/*        <FormGroup label="End date" style={{ textAlign: 'left', marginBottom: 0 }} helperText="10KIF End Date: Friday, 01 March 2024">*/}
          {/*          <DateInput value={offeringEndDate} onChange={handleOfferingEndChange} initialMonth={new Date('2024-03-01')} fill={true} />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*    </Frow>*/}
          {/*  </Col>*/}

          {/*  <Col width="1-1">*/}
          {/*    <p style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px', textAlign: 'left' }}>Hired*</p>*/}
          {/*    <Frow verticalGutters={40} gutters={40}>*/}
          {/*      <Col sm={{ width: '1-2' }}>*/}
          {/*        <FormGroup label="Start date" style={{ textAlign: 'left', marginBottom: 0 }} helperText="10KIF Start Date: Monday, 04 March 2024">*/}
          {/*          <DateInput value={hiredStartDate} onChange={handleHiredStartChange} initialMonth={new Date('2024-03-04')} fill={true} />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col sm={{ width: '1-2' }}>*/}
          {/*        <FormGroup label="End date" style={{ textAlign: 'left', marginBottom: 0 }} helperText="10KIF End Date: Sunday, 31 March 2024">*/}
          {/*          <DateInput value={hiredEndDate} onChange={handleHiredEndChange} initialMonth={new Date('2024-03-31')} fill={true} />*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*    </Frow>*/}
          {/*  </Col>*/}
          {/*</Frow>*/}

          {programmeId === 2 && (
            <>
              <h2 className="mar-t-5">Adjustments Statement + Guidelines</h2>
              <p>
                Once you receive your allocated candidates, it is your responsibility to reach out to them individually to discuss reasonable adjustment requests. While candidates will be allocated to you based on their interests and locations, please discuss remote work as a potential adjustment for all candidates.
              </p>
              <p>
                We expect all organisations to make a substantial effort to meet reasonable adjustment requirements, which include but are not limited to:
              </p>
              <ul style={{ textAlign: 'left' }}>
                <li>
                  Providing extra time for tasks/assessments/interviews
                </li>
                <li>
                  Providing closed captions or sign language interpretation
                </li>
                <li>
                  Offering online/remote work
                </li>
                <li>
                  Providing individual spaces within the office if requested
                </li>
                <li>
                  Allowing personal assistants to accompany the candidate
                </li>
              </ul>
              <p>
                To support you in providing such adjustments and managing expectations, we will be hosting training sessions with our disability charity partners. Please reach out to us at <a href="mailto:company@10000ableinterns.com" style={{ textDecoration: 'underline', color: '#850D3F' }}>company@10000ableinterns.com</a> for additional questions or guidance.
              </p>
            </>
          )}

          <p className="mar-t-5">Thank you for completing second section, this is your pledge to the 10,000 {programmeName} Foundation, and we ask that you commit to taking on the number of interns you have pledged.</p>
          <p>Please review your answers and click submit to confirm your pledge and participation in this cycle.</p>
        </>
      )}

      <Button
        text="Submit"
        className="mar-t-5"
        large={true}
        fill={true}
        style={{ backgroundColor: '#000', color: '#FFF' }}
        onClick={handleSubmit}
        loading={isLoading}
        disabled={!isFormValid}
      />

      <p>Please read our privacy policy <a style={{ color: '#850D3F' }} target="_blank" href="https://10000internsfoundation.com/privacy-policy/">here</a></p>
    </>
  )
}

export function Internships({ internships, setInternships, internshipSectorOptions }) {

  const handleNewInternship = () => {
    setInternships([...internships, { location: '', workstream: '', number: 1 }])
  }

  const handleUpdate = (index, internship = null) => {
    const updatedInternships = [...internships]
    if (internship) {
      updatedInternships.splice(index, 1, internship)
    } else {
      updatedInternships.splice(index, 1)
    }

    setInternships(updatedInternships)
  }

  return(
    <Frow verticalGutters={24}>
      <Col width="1-1">
        <Frow verticalGutters={24}>
          {internships.map((internship, index) => {
            return (
              <Col width="1-1" key={index}>
                <Internship {...{ handleUpdate, index, internship, internshipSectorOptions }} />
              </Col>
            )
          })}
        </Frow>
      </Col>
      <Col width="1-1">
        <Button minimal={true} large={true} text="Add an internship" onClick={handleNewInternship} />
      </Col>
    </Frow>
  )
}

interface IInternship {
  handleUpdate(index: number, internship?: any): void;
  index: number;
  internship: { location: string; workstream: string; number: number; region: string; pay: number; title: string }
  internshipSectorOptions: any;
}

function Internship({ handleUpdate, index, internship, internshipSectorOptions }: IInternship) {
  const handleWorkstreamChange = (selectedItem) => {
    const updatedInternship = {...internship}
    updatedInternship.workstream = selectedItem.value
    handleUpdate(index, updatedInternship)
  }
  const handleRegionChange = (selectedItem) => {
    const updatedInternship = {...internship}
    updatedInternship.region = selectedItem.value
    handleUpdate(index, updatedInternship)
  }
  const handleNumberChange = (number) => {
    if (isNaN(number)) return;

    const updatedInternship = {...internship}
    updatedInternship.number = number
    handleUpdate(index, updatedInternship)
  }

  const handlePayChange = (number, numberValue) => {
    if (isNaN(number)) return;

    const updatedInternship = {...internship}
    updatedInternship.pay = numberValue
    handleUpdate(index, updatedInternship)
  }

  const handleTitleChange = (event) => {
    const updatedInternship = {...internship}
    updatedInternship.title = event.currentTarget.value
    handleUpdate(index, updatedInternship)
  }

  const handleDelete = () => handleUpdate(index)
  const selectedWorkstream = internshipSectorOptions.find((workstreamOption) => workstreamOption.value === internship.workstream)

  const regions = [
    'London',
    'North East',
    'North West',
    'Yorkshire',
    'East Midlands',
    'West Midlands',
    'South East',
    'East of England',
    'South West',
    'Scotland',
    'Highlands',
    'Isle of Man',
    'Northern Ireland',
    'Wales'
  ]

  const regionOptions = regions.map((region) => ({ value: region, label: region }))
  const selectedRegion = regionOptions.find((regionOption) => regionOption.value === internship.region)

  return(
    <Card level={CardLevel.OUTLINED} paddingLevel={PaddingLevel.REGULAR}>
      <h3 className="mar-t-0">Internship {index + 1}</h3>
      <Frow justifyContent="space-between">
        <div className="bp3-col--flex-grow-1">
          <Frow gutters={16} verticalGutters={16}>
            <Col md={{ width: '1-2' }}>
              <FormGroup label="Job title" style={{ textAlign: 'left', marginBottom: 0 }}>
                <Input type="text" value={internship.title} onChange={handleTitleChange} />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-2' }}>
              <FormGroup label="Sector/Workstream" style={{ textAlign: 'left', marginBottom: 0 }}>
                <Select
                  items={internshipSectorOptions}
                  fill={true}
                  buttonProps={{ large: true }}
                  activeItem={selectedWorkstream}
                  placeholder="Please select..."
                  onItemSelect={handleWorkstreamChange}
                />
                {!!selectedWorkstream && selectedWorkstream.description && <p style={{ textAlign: 'left', marginBottom: 0 }}><em>{selectedWorkstream.description}</em></p>}
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Region" style={{ textAlign: 'left', marginBottom: 0 }}>
                <Select
                  items={regionOptions}
                  fill={true}
                  buttonProps={{ large: true }}
                  activeItem={selectedRegion}
                  placeholder="Please select a region..."
                  onItemSelect={handleRegionChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Number of internships" style={{ textAlign: 'left', marginBottom: 0 }}>
                <NumericInput value={internship.number} onValueChange={handleNumberChange} min={1} fill={true} />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Hourly pay" style={{ textAlign: 'left', marginBottom: 0 }}>
                <NumericInput value={internship.pay} onValueChange={handlePayChange} min={1} fill={true} />
              </FormGroup>
            </Col>
            <Col>
              <Button text="Remove" onClick={handleDelete} outlined={true} intent={Intent.DANGER} />
            </Col>
          </Frow>
        </div>
      </Frow>
    </Card>
  )
}

interface IAdditionalContacts {
  contacts: IContacts[]
  setAdditionalPrimaryContacts(contacts: IContacts[]): void;
}

function AdditionalContacts({ contacts, setAdditionalPrimaryContacts }: IAdditionalContacts) {

  const handleContactChange = (newContact, index) => {
    const contactArray = [...contacts]
    contactArray.splice(index, 1, newContact)
    setAdditionalPrimaryContacts(contactArray)
  }

  const handleRemoveContact = (index) => {
    const contactArray = [...contacts]
    contactArray.splice(index, 1)
    setAdditionalPrimaryContacts(contactArray)
  }

  return(
    <Frow gutters={16} verticalGutters={16}>
      {contacts.map((contact, index) => {

        const handleNameChange = (event) => {
          const newContact = { ...contact, name: event.currentTarget.value }
          handleContactChange(newContact, index)
        }

        const handleEmailChange = (event) => {
          const newContact = { ...contact, email: event.currentTarget.value }
          handleContactChange(newContact, index)
        }

        const handlePhoneChange = (event) => {
          const newContact = { ...contact, phone: event.currentTarget.value }
          handleContactChange(newContact, index)
        }

        const handleDelete = () => {
          handleRemoveContact(index)
        }

        return(
          <Col key={`contact-${index}`}>
            <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.CONDENSED}>
              <Frow justifyContent="space-between" alignItems="center" className="mar-b-2">
                <div>
                  <h3 style={{ textAlign: 'left' }} className="mar-t-0 mar-b-1">Primary contact {index + 2}</h3>
                  <Caption>Please note that name, email and phone number are all required</Caption>
                </div>
                <div>
                  <IconButton40 icon={IconNames16.DELETE} intent={Intent.DANGER} onClick={handleDelete} />
                </div>
              </Frow>
              <Frow justifyContent="space-between">
                <div className="bp3-col--flex-grow-1">
                  <Frow gutters={16} verticalGutters={16}>
                    <Col md={{ width: '1-3' }}>
                      <FormGroup label="Name" style={{ marginBottom: 0 }}>
                        <Input type="text" placeholder="Name...." value={contact.name} onChange={handleNameChange} />
                      </FormGroup>
                    </Col>
                    <Col md={{ width: '1-3' }}>
                      <FormGroup label="Phone number" style={{ marginBottom: 0 }}>
                        <Input type="text" placeholder="Phone number...." value={contact.phone} onChange={handlePhoneChange} />
                      </FormGroup>
                    </Col>
                    <Col md={{ width: '1-3' }}>
                      <FormGroup label="Email" style={{ marginBottom: 0 }}>
                        <Input type="email" placeholder="Email...." value={contact.email} onChange={handleEmailChange} />
                      </FormGroup>
                    </Col>
                  </Frow>
                </div>
              </Frow>
            </Card>
          </Col>
        )
      })}
    </Frow>
  )
}