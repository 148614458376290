import { Button, Callout, Dialog, FormGroup, Intent, ProgressBar, TextArea } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { createToast } from '../../../../FlashToaster';
import { promiseAllWithProgress } from '../../../../shared/promiseAllWithProgress';

export function RejectDialog({ fetchData, handleClose, selection, setSelection }) {
  const [reasonForApproval, setReasonForApproval] = React.useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const handleApprovalReasonChange = (event) => {
    setReasonForApproval(event.target.value);
  };

  const toastKey = 'rejectAllocationRequest';

  const handleSubmit = () => {
    setIsSubmitting(true);
    promiseAllWithProgress({
      callback: (progress) => displayProgressToast(progress),
      promises: selection.map((releaseRequest) => approveReleaseRequest(releaseRequest)),
    }).then(() => {
      displaySuccessToast();
      setSelection([]);
      fetchData();
      setIsSubmitting(false);
      handleClose();
    });
  };

  async function approveReleaseRequest(releaseRequest) {
    releaseRequest.status = 'rejected';
    releaseRequest.response = reasonForApproval;
    await releaseRequest.save();
  }

  function displaySuccessToast() {
    setTimeout(() => {
      createToast({
        key: toastKey,
        text: 'Successfully updated requests',
        type: 'success',
      });
    }, 1000);
  }

  function displayProgressToast(progress) {
    const toastContent = (
      <>
        Updating allocation requests {progress.complete} of {progress.total}
        <div style={{ marginTop: '4px' }}>
          <ProgressBar value={progress.percentage / 100} intent={Intent.NONE} />
        </div>
      </>
    );

    createToast({
      isLoading: true,
      key: toastKey,
      text: toastContent,
    });
  }

  return (
    <Dialog title="Approve request" isOpen={true} onClose={handleClose}>
      <Callout intent={Intent.PRIMARY} className="mar-b-3">
        The reason you are approving this release request will be sent to the primary contact
      </Callout>
      <FormGroup label="Reason for approval">
        <TextArea value={reasonForApproval} onChange={handleApprovalReasonChange} />
      </FormGroup>
      <div className="mar-t-2">
        <Button
          onClick={handleSubmit}
          text="Reject"
          large={true}
          fill={true}
          disabled={!reasonForApproval}
          loading={isSubmitting}
        />
      </div>
    </Dialog>
  );
}
