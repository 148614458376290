import { Link } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { adminCompanyPath } from '../../../../javascript/application/ts_routes';

export function companyColumns(isLoading, included) {
  return [
    {
      Cell: (row: any) => {
        return <Link target="_blank" href={adminCompanyPath(row.original.id)}>{row.original.attributes.name}</Link>;
      },
      Header: 'Name',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.relationships.jobs.data.length}</div>
      },
      Header: 'No. of Jobs',
      sortable: false,
    }
  ]
}
