import * as $ from 'jquery';
import * as React from "react";
import {Alerter, Button} from "@pinpointhq/thumbtack";
import {Internships} from "./SubmissionForm";
import {visit} from 'turbolinks';
import {createToast} from "../FlashToaster";
import {
  programmeAdditionalSubmissionPath, thanksProgrammeAdditionalSubmissionsPath,
} from "../../javascript/application/ts_routes";
import {getCsrfToken} from "../shared/csrfToken";
import {ajaxErrorString} from "../shared/ajaxErrorString";

export default function AdditionalSubmissionForm({ additionalSubmission, internshipSectorOptions, programme, region, workstream }) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [internships, setInternships] = React.useState(additionalSubmission.internships || [{ region, location: '', workstream, number: 1 }])

  const handleSubmit = () => {
    const internshipCount = internships.map((i) => parseInt(i.number, 10)).reduce((p, c) => p + c)
    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      onConfirm: handleConfirm,
      text: `Are you sure you want to request ${internshipCount} additional ${internshipCount > 1 ? 'internships?' : 'internship?'}`,
    })
  }

  const handleConfirm = () => {
    $.ajax({
      url: programmeAdditionalSubmissionPath(programme.id, additionalSubmission.id),
      data: {
        authenticity_token: getCsrfToken(),
        additional_submission: {
          internships
        },
      },
      type: 'PATCH',
      dataType: 'json',
      beforeSend: () => setIsLoading(true),
      complete: () => setIsLoading(false),
      success: (response) => {
        setIsLoading(false)
        visit(thanksProgrammeAdditionalSubmissionsPath(programme.id, { token: response.data }))
      },
      error: (jqXhr) => {
        createToast({
          text: ajaxErrorString(jqXhr),
          type: 'error',
        });
      }
    });
  }

  return(
    <>
      <p>The following section will ask you to categorise each of your internships based on workstream and location. For the purpose of this exercise, you may wish to consider “workstream” as synonymous with “internal department”.</p>

      <p>Different organisations have different terminology for their internal workstreams, and our list is not exhaustive, so we ask that you categorise your internships to the best of your ability based on what the role will entail.</p>

      <p>If the role you are offering is a rotation of various departments, offers more general insight into your wider industry, or is very specific to your sector, please categorise your internship according to the sector in which your organisation belongs.</p>

      <p><em>Example 1: A charity offering an internship in their fundraising department should categorise their internship under “non-for-profit”</em></p>

      <p><em>Example 2: An investment management firm offering two internships in a sales team and two internships in a product team should offer four internships under “Investment Management”</em></p>

      <p>Departments that are considered to be in “corporate functions” should be categorised according to what best describes the role.</p>

      <p><em>A Tech firm offering 2 internships in their software engineering department, two internships in their finance department and two internships in compliance should assign two internships to “Technology”, two internships to “Accountancy & Finance” and two internships to “Legal Services & Compliance”</em></p>

      <div className="mar-v-3">
        <Internships {...{ internships, setInternships, internshipSectorOptions }} />
      </div>

      <p>Please review your answers and click submit to confirm your additional allocation request.</p>

      <Button
        text="Submit"
        className="mar-t-5"
        large={true}
        fill={true}
        style={{ backgroundColor: '#000', color: '#FFF' }}
        onClick={handleSubmit}
        loading={isLoading}
      />

      <p>Please read our privacy policy <a style={{ color: '#850D3F' }} target="_blank" href="https://www.10000blackinterns.com/privacy-policy">here</a></p>
    </>
  )
}