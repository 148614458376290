import * as React from 'react';
import {
  Card,
  CardLevel,
  Classes,
  Colors,
  Heading2,
  PaddingLevel,
  Tab,
  Tabs
} from "@pinpointhq/thumbtack";
import {InternshipData} from "./candidateDashboard/InternshipData";
import {ApplicationData} from "./candidateDashboard/ApplicationData";

export default function CandidateDashboard({ cycle, programme }) {
  const [selectedTab, setSelectedTab] = React.useState<string>('applicationData')
  const tabMap = {
    internshipData: 'Internship Data',
    applicationData: 'Application Data',
    recruitmentData: 'Recruitment Data'
  }

  const handleTabChange = (selectedTabId) => {
    setSelectedTab(selectedTabId)
  }

  const components = {
    internshipData: InternshipData,
    applicationData: ApplicationData
  }

  const Component = components[selectedTab];

  return(
    <>
      <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.NONE} className="mar-b-5">
        <Tabs id="candidate-dashboard-tabs" selectedTabId={selectedTab} onChange={handleTabChange}>
          <Tab id="applicationData">
            Application Data
          </Tab>
          <Tab id="internshipData">
            Internship Data
          </Tab>
          {/*<Tab id="recruitmentData">*/}
          {/*  Recruitment Data*/}
          {/*</Tab>*/}
          <div className={Classes.FLEX_EXPANDER} style={{ backgroundColor: Colors.SAGE_50 }} />
        </Tabs>
        <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
          <Heading2 className="mar-b-0">{tabMap[selectedTab]}</Heading2>
        </Card>
      </Card>
      <Component {...{ cycle, programme }} />
    </>
  )
}