import { Body, Button, Card, CardLevel, Divider, Frow, IconNames16 } from '@pinpointhq/thumbtack';
import * as React from 'react';

export interface IActionBarProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Items to place within the action bar
   */
  children: React.ReactNode;
  /**
   * Determines whether the Card has an elevated state
   * @default false
   */
  elevated?: boolean;
  /**
   * Whether the action should be visible or not
   */
  isOpen: boolean;
  /**
   * Whether we should show cancel text (the icon should always show)
   */
  showCancelText?: boolean;
  /**
   * The text to show on the left hand side (first item)
   * Usually something like 'X Candidates Selected'
   */
  text?: string;
  /**
   * The type of card to use
   * @default CardLevel.FILLED
   */
  cardLevel?: CardLevel;
  /**
   * The icon name for the cancel button
   * @default CLOSE
   */
  cancelIcon?: 'CLOSE' | 'SENDING';
  /**
   * The text to show within the cancel button
   * Will only show cancel button if onCancel is provided
   * @default 'Cancel'
   */
  cancelText?: string;
  /**
   * Whether the cancel button is disabled
   * @default false
   */
  cancelButtonDisabled?: boolean;
  /**
   * The height of the dividers
   * @default 24
   */
  dividerHeight?: number;
  /**
   * Callback function to run if cancel button is clicked
   * Will only show the cancel button if this is provided
   */
  onCancel?(): void;
}

function ItemDivider({ dividerHeight }: { dividerHeight: number }) {
  return <Divider muted={true} className="mar-h-1" style={{ height: `${dividerHeight}px` }} />;
}

const defaultStyles = { padding: '4px 4px 4px 28px' };

export function ActionBar({
  cancelButtonDisabled = false,
  cancelIcon = 'CLOSE',
  children,
  dividerHeight = 24,
  elevated,
  isOpen,
  text,
  cardLevel,
  cancelText,
  onCancel,
  showCancelText = true,
  ...props
}: IActionBarProps) {
  if (!isOpen) return null;

  const items = React.Children.toArray(children);

  // Contains Flex fix for IE 11
  return (
    <ActionBarCard elevated={elevated} cardLevel={cardLevel} {...props}>
      <div className="frow frow--items-center frow--justify-between">
        <div style={{ flex: '1 0 0%' }}>
          <div className="frow frow--items-center">
            {text && <Body className="mar-r-2">{text}</Body>}
            {items.map((item, index) => (
              <React.Fragment key={index}>
                {index === 0 && text && <ItemDivider {...{ dividerHeight }} />}
                {item}
              </React.Fragment>
            ))}
          </div>
        </div>
        {onCancel ? (
          <div>
            <div className="frow frow--items-center">
              <ItemDivider {...{ dividerHeight }} />
              <Button
                icon={IconNames16[cancelIcon]}
                onClick={onCancel}
                text={showCancelText ? cancelText || 'Cancel' : ''}
                minimal={true}
                disabled={cancelButtonDisabled}
              />
            </div>
          </div>
        ) : null}
      </div>
    </ActionBarCard>
  );
}

interface IActionBarCardProps extends Omit<React.ComponentProps<typeof Card>, 'level'> {
  /**
   * Items to place within the action bar
   */
  children: React.ReactNode;
  /**
   * Determines whether the Card has an elevated state
   * @default false
   */
  elevated?: boolean;
  /**
   * The type of card to use
   * @default CardLevel.FILLED
   */
  cardLevel?: CardLevel;
}

function ActionBarCard({ children, elevated, cardLevel, style, ...props }: IActionBarCardProps) {
  return (
    <Card
      elevated={elevated || false}
      level={cardLevel || CardLevel.FILLED}
      padded={false}
      style={{ ...defaultStyles, ...style }}
      {...props}
    >
      {children}
    </Card>
  );
}

// for wrapping right-aligned 'empty' action bars
export function ActionBarWrapper({ children, ...props }: IActionBarCardProps) {
  return (
    <ActionBarCard cardLevel={CardLevel.NONE} {...props}>
      <Frow alignItems="center" justifyContent="space-between" flexWrap="nowrap" gutters={16}>
        <div />
        <div>{children}</div>
      </Frow>
    </ActionBarCard>
  );
}
