import * as React from 'react';
import useInterval from './useInterval';

//
const useFiniteInterval = (callback, delay, iterations) => {
  const [count, setCount] = React.useState<number>(0);

  const { start: startInterval, stop: stopInterval, isOperating } = useInterval(() => {
    setCount(count + 1);
    callback();
  }, delay);

  React.useEffect(() => {
    if (count > iterations) {
      stop();
    }
  }, [count]);

  function start() {
    startInterval();
  }

  function stop() {
    stopInterval();
  }

  return {
    isOperating,
    start,
    stop,
  };
};

export default useFiniteInterval;
