import {Body, Card, CardLevel, PaddingLevel, Subheading1} from "@pinpointhq/thumbtack";
import ReactTable from "react-table";
import NewPaginationComponent from "../../../shared/NewPaginationComponent";
import * as React from "react";
import {useSpraypaintTableNamedParams} from "../../../shared/hooks/useSpraypaintTable";
import {Internship} from "../../../../../javascript/models";

export function Problems() {
  const { tableProps } = useSpraypaintTableNamedParams(
    {
      initialScope: Internship.where({ problem: true }),
      searchIdentifier: 'internship-problems',
    }
  )

  const columns = [
    {
      accessor: 'companyName',
      Header: 'Organisation',
      sortable: false,
    },
    {
      accessor: 'problem',
      Header: 'Problem',
      sortable: false,
    },
    {
      accessor: 'action',
      Header: 'Action Taken',
      sortable: false,
    },
  ]

  const paginationProps = React.useCallback(
    () => (tableProps),
    [tableProps],
  );

  return(
    <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
      <Subheading1>Problems</Subheading1>
      <Body className="mar-t-1 mar-b-3" muted={true}>An overview of internship problems and the actions taken</Body>
      <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
        <ReactTable
          data={[]}
          columns={columns}
          PaginationComponent={NewPaginationComponent}
          getPaginationProps={paginationProps}
          className="-unthemed"
          minRows={1}
        />
      </Card>
    </Card>
  )
}