import * as React from 'react';
import { Spinner } from '@pinpointhq/thumbtack';

export default function LoadingSpinner() {
  const style: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    height: '120px',
    justifyContent: 'center',
    width: '100%',
  };
  return (
    <div style={style}>
      <Spinner />
    </div>
  );
}
