import * as React from 'react';

import {Body, Card, CardLevel, Classes, Col, Frow, PaddingLevel, Subheading1,} from '@pinpointhq/thumbtack';
import useCustomEventEffect from "../../../shared/hooks/useCustomEventEffect";
import {candidateQualityAdminProgrammeInsightsJobSeekersPath} from "../../../../javascript/application/ts_routes";
import LoadingSpinner from "../../../shared/LoadingSpinner";

export default function CandidateQuality({ cycleId, programmeId }) {
  const [data, setData] = React.useState<any>([]);
  const [errors, setErrors] = React.useState<any>();
  const [isLoaded, setIsLoaded] = React.useState(false);
  useCustomEventEffect('dashboard-filter-change', (event) => fetchData(event.detail));

  React.useEffect(() => {
    fetchData({ cycleId: cycleId, allocationType: null });
  }, []);

  const fetchUrl = candidateQualityAdminProgrammeInsightsJobSeekersPath(programmeId)

  async function fetchData(args = { cycleId: null, allocationType: null }) {
    const { allocationType, cycleId } = args;
    let url = `${fetchUrl}?`
    // const url = cycleId ? `${fetchUrl}?cycle_id=${cycleId}` : fetchUrl
    if (cycleId) {
      url = `${url}cycle_id=${cycleId}&`
    }
    if (allocationType) {
      url = `${url}allocation_type=${allocationType}`
    }
    await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.errors) {
          setErrors(json.errors);
        } else {
          setErrors(null);
          setData(json);
        }
      })
      .then(() => {
        setIsLoaded(true);
      });
  }

  return(
    <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
      <div className="mar-b-2">
        <div className="frow frow--items-start frow--justify-between frow--nowrap frow--items-center">
          <div className="col-flex-grow-1">
            <Subheading1>Candidate Quality</Subheading1>
            <Body muted={true} className="mar-t-1">
              % of candidates that have given less than 225 words for total and/or 75 words for each sector
            </Body>
          </div>
        </div>
      </div>
      {isLoaded ? (
        <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
          <Frow gutters={40}>
            <Col width="1-2">
              <ul className={Classes.LIST_TABULAR}>
                {Object.keys(data).slice(0, 22).map((dataKey) => {
                  const dataObject = data[dataKey]
                  if (dataObject.total === 0) return null;

                  return(
                    <li key={dataKey}>
                      <Body>{dataKey}</Body>
                      <div>
                        <Frow gutters={8} flexWrap="nowrap">
                          <div>
                            {dataObject.low_quality} of {dataObject.total}
                          </div>
                          <div>
                            <Body semiBold={true}>{((dataObject.low_quality / dataObject.total) * 100).toFixed(1)}%</Body>
                          </div>
                        </Frow>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </Col>
            <Col width="1-2">
              <ul className={Classes.LIST_TABULAR}>
                {Object.keys(data).slice(23, 60).map((dataKey) => {
                  const dataObject = data[dataKey]
                  if (dataObject.total === 0) return null;

                  return(
                    <li key={dataKey}>
                      <Body>{dataKey}</Body>
                      <div>
                        <Frow gutters={8} flexWrap="nowrap">
                          <div>
                            {dataObject.low_quality} of {dataObject.total}
                          </div>
                          <div>
                            <Body semiBold={true}>{((dataObject.low_quality / dataObject.total) * 100).toFixed(1)}%</Body>
                          </div>
                        </Frow>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </Col>
          </Frow>
        </Card>
      ) : <LoadingSpinner />}
    </Card>
  )
}