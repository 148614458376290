import * as React from 'react';
import {Card, CardLevel} from "@pinpointhq/thumbtack";
import {InsightsCard} from "./CandidatesByPreference";
import ReactApexChart, { Props as ApexChartProps } from 'react-apexcharts';
import LoadingSpinner from "../../../shared/LoadingSpinner";

const Chart = ReactApexChart as unknown as React.ComponentType<ApexChartProps>;

interface ILineChart {
  cycleId?: string;
  fetchUrl: string;
  subheading: string;
  insightDescription: string;
}

export default function CandidateInsightLineChart(props: ILineChart) {
  const { fetchUrl, subheading, insightDescription, cycleId = 2 } = props;
  const [data, setData] = React.useState<any>({ options: [], series: [] });
  const [errors, setErrors] = React.useState<any>();
  const [isLoaded, setIsLoaded] = React.useState(false);

  async function fetchData(cycleId = null) {
    const url = cycleId ? `${fetchUrl}?cycle_id=${cycleId}` : fetchUrl
    await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.errors) {
          setErrors(json.errors);
        } else {
          setErrors(null);
          setData(json);
        }
      })
      .then(() => {
        setIsLoaded(true);
      });
  }

  React.useEffect(() => {
    fetchData(cycleId);
  }, []);

  const options = {
    chart: {
      id: "basic-bar"
    },
    colors: ['#455c51', '#32B2C4', '#36bc73'],
    xaxis: {
      categories: data.options
    }
  }

  return(
    <>
      <InsightsCard level={CardLevel.RAISED} subheading={subheading} description={insightDescription}>
        <Card level={CardLevel.FILLED} condensed={true}>
          {isLoaded ? <Chart
            options={options}
            series={data.series}
            type="line"
            height="300"
          /> : <LoadingSpinner />}
        </Card>
      </InsightsCard>
    </>
  )
}