import {
  Button,
  Caption,
  Card,
  CardLevel,
  Classes,
  Col,
  FormGroup,
  Frow,
  Heading2,
  IconButton40,
  IconNames16,
  Intent,
  Menu,
  MenuItem,
  MultiSelect,
  PaddingLevel,
  Popover,
  Super2,
  Tooltip,
  WorkflowTag,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import ReactTable from 'react-table';
import { Candidate, Cycle, Programme } from '../../../javascript/models';
import { createToast } from '../../FlashToaster';
import useVisibility from '../../shared/hooks/useVisibility';
import Search from '../../shared/Search';
import { useSpraypaintNamedParams } from '../shared/hooks/useSpraypaint';
import { useSpraypaintTableNamedParams } from '../shared/hooks/useSpraypaintTable';
import NewPaginationComponent from '../shared/NewPaginationComponent';
import { EditDrawer } from './table/EditDrawer';
import { RedFlagDialog } from './table/RedFlagDialog';
import { ShowDrawer } from './table/ShowDrawer';

export default function Table() {
  const identifier = 'candidates';
  const { tableProps, isLoading, meta, query, setQuery, fetchData } = useSpraypaintTableNamedParams({
    initialScope: Candidate.includes(['job_seekers'])
      .selectExtra({
        job_seekers: ['programme_cycle_name'],
      })
      .order({ created_at: 'desc' }),
    searchIdentifier: identifier,
    initialQuery: { cycle_id: [], programme_id: [] },
  });
  const [selectedCandidate, setSelectedCandidate] = React.useState<Candidate>(null);
  const [editingCandidate, setEditingCandidate] = React.useState<Candidate>(null);
  const [redFlagCandidate, setRedFlagCandidate] = React.useState<Candidate>(null);

  const { data: cycles, isLoading: isCyclesLoading } = useSpraypaintNamedParams({ initialScope: Cycle.scope() });
  const { data: programmes, isLoading: isProgrammesLoading } = useSpraypaintNamedParams({
    initialScope: Programme.scope(),
  });

  const { isOpen: isFiltersOpen, toggleOpen: toggleFiltersOpen } = useVisibility(false);

  const handleSearch = (searchString) => {
    setQuery({ ...query, search: searchString });
  };

  const handleIdSearch = (searchString) => {
    setQuery({ ...query, id_search: searchString });
  };

  const handleCycleChange = (selectedItems) => {
    setQuery({ ...query, cycle_id: selectedItems.map((item) => item.value) });
  };
  const handleProgrammeChange = (selectedItems) => {
    setQuery({ ...query, programme_id: selectedItems.map((item) => item.value) });
  };

  const cycleOptions = cycles.map((cycle) => {
    return { label: cycle.name, value: cycle.id };
  });

  const programmeOptions = programmes.map((programme) => {
    return { label: programme.name, value: programme.id };
  });

  const selctedCycleOptions = cycleOptions.filter((cycle) => query.cycle_id.includes(cycle.value));
  const selctedProgrammeOptions = programmeOptions.filter((programme) => query.programme_id.includes(programme.value));

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  const columns = [
    {
      Cell: (row: { original: Candidate }) => {
        const handleCandidateClick = () => setSelectedCandidate(row.original);
        return (
          <div>
            <span onClick={handleCandidateClick} className={Classes.LINK}>
              {row.original.fullName}
            </span>
          </div>
        );
      },
      Header: 'Name',
      accessor: 'fullName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Cell: (row) => {
        return <div>{row.original.redFlag ? 'Yes' : 'No'}</div>;
      },
      Header: 'Red Flag',
      accessor: 'redFlag',
      width: 100,
    },
    {
      Cell: (row) => {
        return <div>{row.original.eligible ? 'Yes' : 'No'}</div>;
      },
      Header: 'Eligible',
      accessor: 'eligible',
      width: 110,
    },
    {
      Cell: (row: { original: Candidate }) => {
        const { jobSeekers } = row.original;

        const cycles = jobSeekers.reverse().map((jobSeeker) => jobSeeker.programmeCycleName);
        const firstCycle = cycles[0];

        return (
          <Frow gutters={8} alignItems="center">
            <div>
              <WorkflowTag className="bp3-minimal" text={firstCycle} />
            </div>
            {cycles.length > 1 && (
              <div>
                <Tooltip content={cycles.slice(1).join(', ')}>
                  <Caption> + {cycles.length - 1} more</Caption>
                </Tooltip>
              </div>
            )}
          </Frow>
        );
      },
      Header: 'Programmes',
      accessor: 'id',
    },
    {
      Cell: (row) => {
        const candidate = row.original;
        const handleRedFlagClick = () => setRedFlagCandidate(candidate);
        const handleRemoveRedFlagClick = () => {
          candidate.redFlag = false;
          candidate.redFlagReason = null;
          candidate.save().then((success) => {
            if (success) {
              createToast({ type: 'success', text: 'Red flag removed' });
              fetchData();
            }
          });
        };
        return (
          <div>
            <Popover>
              <IconButton40 icon={IconNames16.OVERFLOW} />
              <Menu>
                <MenuItem intent={Intent.NONE} text="Edit" onClick={() => setEditingCandidate(candidate)} />
                {row.original.redFlag ? (
                  <MenuItem intent={Intent.NONE} text="Remove red flag" onClick={handleRemoveRedFlagClick} />
                ) : (
                  <MenuItem intent={Intent.DANGER} text="Red flag" onClick={handleRedFlagClick} />
                )}
              </Menu>
            </Popover>
          </div>
        );
      },
      Header: '',
      accessor: 'eligible',
      width: 80,
      sortable: false,
    },
  ];

  return (
    <>
      <Card level={CardLevel.FILLED}>
        <div className="frow frow--items-center">
          <Super2 className={isLoading && 'bp3-skeleton'}>{meta ? meta.stats.total.count : '100'}</Super2>
          <Heading2 style={{ margin: '0 0 0 16px' }} className={!isLoading ? '' : 'bp3-skeleton'}>
            Candidates
          </Heading2>
        </div>
      </Card>
      <div className="pad-a-5">
        <Frow gutters={16} verticalGutters={16} className="mar-b-1">
          <div className="bp3-col--flex-grow-1">
            <Search target={identifier} handleSearch={handleSearch} searchPlaceholderText="Search name or email" />
          </div>
          <div className="bp3-col--flex-grow-1">
            <Search target={identifier} handleSearch={handleIdSearch} searchPlaceholderText="Search ID" />
          </div>
          <div>
            <Button icon={IconNames16.FILTER} minimal={true} text="Filters" onClick={toggleFiltersOpen} large={true} />
          </div>
        </Frow>
        {isFiltersOpen && (
          <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
            <Frow gutters={16}>
              <Col md={{ width: '1-2' }}>
                <FormGroup label="Cycle">
                  <MultiSelect
                    fill={true}
                    items={cycleOptions}
                    loading={isCyclesLoading}
                    handleChange={handleCycleChange}
                    selectedItems={selctedCycleOptions}
                  />
                </FormGroup>
              </Col>
              <Col md={{ width: '1-2' }}>
                <FormGroup label="Programme">
                  <MultiSelect
                    fill={true}
                    items={programmeOptions}
                    loading={isProgrammesLoading}
                    handleChange={handleProgrammeChange}
                    selectedItems={selctedProgrammeOptions}
                  />
                </FormGroup>
              </Col>
            </Frow>
          </Card>
        )}
        <ReactTable
          {...tableProps}
          columns={columns}
          PaginationComponent={NewPaginationComponent}
          getPaginationProps={paginationProps}
          className="-unthemed"
          minRows={1}
        />
      </div>
      {selectedCandidate && (
        <ShowDrawer
          handleClose={() => setSelectedCandidate(null)}
          isOpen={selectedCandidate !== null}
          candidate={selectedCandidate}
        />
      )}
      {editingCandidate && (
        <EditDrawer candidate={editingCandidate} fetchData={fetchData} handleClose={() => setEditingCandidate(null)} />
      )}
      {redFlagCandidate && (
        <RedFlagDialog
          handleClose={() => setRedFlagCandidate(null)}
          candidate={redFlagCandidate}
          fetchData={fetchData}
          isOpen={redFlagCandidate !== null}
        />
      )}
    </>
  );
}
