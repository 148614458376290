import * as React from 'react';
import { Card, CardLevel, PaddingLevel, Subheading1 } from '@pinpointhq/thumbtack';

export default function HeadlineNumber({ number, title }) {
  return(
    <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.CONDENSED}>
      <Subheading1>{title}</Subheading1>
      <div className='number number--large'>{number}</div>
    </Card>
  )
}
