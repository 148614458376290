import * as React from 'react';

import { Body, Classes, IBodyProps } from '@pinpointhq/thumbtack';
import sanitizeHtml from 'sanitize-html';

function sanitizeContentStripAll(dirtyContent: string) {
  return sanitizeHtml(dirtyContent, {
    allowedAttributes: {},
    allowedTags: [],
  }) as string;
}

const simpleFormatRE1 = /\r\n?/g;
const simpleFormatRE2 = /\n\n+/g;
const simpleFormatRE3 = /([^\n]\n)(?=[^\n])/g;
function simpleFormat(str) {
  let fstr = str;
  if (!fstr) return fstr;

  fstr = fstr.replace(simpleFormatRE1, '\n'); // \r\n and \r -> \n
  fstr = fstr.replace(simpleFormatRE2, '</p>\n\n<p>'); // 2+ newline  -> paragraph
  fstr = fstr.replace(simpleFormatRE3, '$1<br/>'); // 1 newline   -> br
  fstr = '<p>' + fstr + '</p>';
  return fstr;
}

export default simpleFormat;

export interface ISanitizedRunningTextProps extends IBodyProps {
  text: string;
}

export function SanitizedRunningText({ text, ...props }: ISanitizedRunningTextProps) {
  return (
    <Body
      className={Classes.RUNNING_TEXT}
      dangerouslySetInnerHTML={{ __html: simpleFormat(sanitizeContentStripAll(text)) }}
      {...props}
    />
  );
}
