import * as React from 'react';
import { FormGroup, Frow, Select } from "@pinpointhq/thumbtack";
import useCustomEventEffect from "../../shared/hooks/useCustomEventEffect";

export default function Filters({ cycleOptions, defaultCycleId }) {
  const [selectedCycleId, setSelectedCycleId] = React.useState(defaultCycleId)
  const [selectedTypeValue, setSelectedTypeValue] = React.useState('all')
  const phaseOptions = [
    { label: 'All candidates', value: 'all' },
    { label: 'Allocation one (active)', value: 'allocation_one' },
    { label: 'Allocation two', value: 'allocation_two' },
  ]
  const { dispatch: dispatchFilterChange } = useCustomEventEffect('dashboard-filter-change');

  const handleSelect = (selectedItem) => {
    setSelectedCycleId(selectedItem.value)
    dispatchFilterChange({ cycleId: selectedItem.value, allocationType: selectedTypeValue })
  }

  const handlePhaseSelect = (selectedItem) => {
    setSelectedTypeValue(selectedItem.value)
    dispatchFilterChange({ cycleId: selectedCycleId, allocationType: selectedItem.value })
  }

  const selectedCycle = cycleOptions.find((cycleOption) => cycleOption.value === selectedCycleId)
  const selectedType = phaseOptions.find((cycleOption) => cycleOption.value === selectedTypeValue)

  return(
    <Frow gutters={8} alignItems="center">
      <div>
        <FormGroup label="Cycle">
          <Select onItemSelect={handleSelect} items={cycleOptions} buttonProps={{ large: true }} activeItem={selectedCycle} />
        </FormGroup>
      </div>
      <div>
        <FormGroup label="Allocation phase">
          <Select onItemSelect={handlePhaseSelect} items={phaseOptions} buttonProps={{ large: true }} activeItem={selectedType} />
        </FormGroup>
      </div>
    </Frow>
  )
}