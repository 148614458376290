export function ajaxErrorString(jqXhr) {
  if (jqXhr.status === 0) {
    return 'The request was blocked by the browser. Are you using an ad blocker?';
  }
  if (jqXhr.status === 500) {
    return 'Unknown server error';
  }
  if (jqXhr.responseJSON && jqXhr.responseJSON.errors) {
    return jqXhr.responseJSON.errors.map(e => e.title).join(', ');
  }
  if (jqXhr.status === 401) {
    return jqXhr.responseJSON.message;
  }
  return 'Unknown server error';
}