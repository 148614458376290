import * as React from 'react';
import { Card, CardLevel, PaddingLevel } from '@pinpointhq/thumbtack';
import { CardListWithChart, InsightsCard } from '../../dashboard/insights/CandidatesByPreference';

export default function Internships({ fetchUrl, insight }) {
  const [data, setData] = React.useState<any>([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    await fetch(fetchUrl, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((json) => {
        setData(json);
      })
      .then(() => {
        setIsLoaded(true);
      });
  }

  return(
    <InsightsCard
      level={CardLevel.RAISED}
      subheading={insight}
      description={`Internships broken down by ${insight}`}
    >
      <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.CONDENSED}>
        <CardListWithChart
          {...{ array: data, isLoading: !isLoaded }}
          placeholderText="There are currently no internships"
        />
      </Card>
    </InsightsCard>
  )
}
