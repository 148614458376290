import {
  Card,
  CardLevel,
  Divider,
  Drawer,
  IResponsiveTabItem,
  ISelectOption,
  PaddingLevel,
  ResponsiveTabs,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { CompaniesCycle, Company, Contact, Cycle, Job } from '../../../javascript/models';
import { DrawerHeader } from '../../shared/DrawerHeader';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { Contacts } from '../programmes/companies/show/Contacts';
import useSpraypaintFind from '../shared/hooks/useSpraypaintFind';
import { CompanyJobs } from './sections/CompanyJobs';
import { PledgeTable } from './sections/PledgeTable';
import { Submissions } from './sections/Submissions';

export function OrganisationDrawer({
  company: propCompany,
  cycles,
  handleClose,
  workstreamOptions,
  regionOptions,
  degreeSubjectOptions,
}: {
  company: Company;
  cycles: Cycle[];
  handleClose(): void;
  workstreamOptions: ISelectOption[];
  regionOptions: ISelectOption[];
  degreeSubjectOptions: ISelectOption[];
}) {
  const [selectedTab, setSelectedTab] = React.useState<string>('contacts');
  const contactScope = Contact.scope();

  const companyCycleScope = CompaniesCycle.order({ cycle_id: 'desc' });
  const jobScope = Job.order({ cycle_id: 'desc', programme_id: 'desc' }).includes(['cycle', 'programme']);
  const { data: company, isLoading, fetchData } = useSpraypaintFind(
    Company.includes([
      'contacts',
      { companies_cycles: { company_submission: ['saved_internships'] } },
      { jobs: ['cycle', 'programme'] },
    ])
      .selectExtra(['allocation_information'])
      .merge({ contact: contactScope, jobs: jobScope, companies_cycles: companyCycleScope }),
    propCompany.id,
  );

  React.useEffect(() => {
    if (selectedTab) {
      const element = document.getElementById(selectedTab);
      if (element) {
        element.scrollIntoView({ block: 'start', inline: 'nearest' });
      }
    }
  }, [selectedTab]);

  const tabs = [
    { text: 'Contacts', id: 'contacts' },
    { text: 'Pledges', id: 'pledges' },
    { text: 'Jobs', id: 'jobs' },
    { text: 'Submissions', id: 'submissions' },
  ];

  const handleTabChange = (tab: IResponsiveTabItem) => {
    setSelectedTab(tab.id as string);
  };

  return (
    <Drawer isOpen={true} onClose={handleClose} maxWidth={1600}>
      <DrawerHeader title={`${propCompany.name} - ${propCompany.uid}`} handleClose={handleClose} />
      <Divider muted={true} />
      <div>
        <ResponsiveTabs
          id="approval-tabs"
          defaultSelectedTabId={selectedTab}
          muted={true}
          items={tabs}
          onTabChange={handleTabChange}
        />
      </div>
      <div style={{ overflowY: 'scroll' }}>
        <Card
          paddingLevel={PaddingLevel.REGULAR}
          level={CardLevel.NONE}
          cardInnerProps={{ className: 'pad-h-5 pad-v-3' }}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <div id="contacts">
                <Contacts company={company} isLoading={isLoading} fetchData={fetchData} />
              </div>
              <Divider className="mar-v-3" muted={true} />
              <div id="pledges">
                <PledgeTable company={company} cycles={cycles} />
              </div>
              <Divider className="mar-v-3" muted={true} />
              <div id="jobs">
                <CompanyJobs
                  company={company}
                  fetchData={fetchData}
                  workstreamOptions={workstreamOptions}
                  regionOptions={regionOptions}
                  degreeSubjectOptions={degreeSubjectOptions}
                />
              </div>
              <Divider className="mar-v-3" muted={true} />
              <div id="submissions">
                <Submissions company={company} />
              </div>
            </div>
          )}
        </Card>
      </div>
    </Drawer>
  );
}
