import * as React from 'react';
import {Body, Card, CardLevel, Classes, Colors, Frow, PaddingLevel} from "@pinpointhq/thumbtack";

export default function RaisedList({ listItems }: { listItems: { label: string; value: string }[] }) {
  return(
    <Card
      level={CardLevel.RECESSED}
      paddingLevel={PaddingLevel.REGULAR}
      style={{ borderLeft: `4px solid ${Colors.IVORY_30}` }}
      cardInnerProps={{ style: { paddingTop: '0', paddingBottom: '0' } }}
    >
      <ul className={Classes.LIST_TABULAR}>
        {listItems.map((item) => {
          return(
            <li style={{ padding: '12px 0' }} key={item.label}>
              <Frow style={{ width: '100%' }}>
                <div className="col-flex-grow-1">
                  <Body muted={true}>{item.label}</Body>
                </div>
                <div>
                  <Body semiBold={true} className="text-right">{item.value}</Body>
                </div>
              </Frow>
            </li>
          )
        })}
      </ul>
    </Card>
  )
}