import * as React from 'react';
import * as $ from 'jquery';
import {Body, Button, Card, CardLevel, Classes, Frow, Link, PaddingLevel, Subheading1} from '@pinpointhq/thumbtack';
import PaginationComponent from '../candidates/awaiting/PaginationComponent';
import ReactTable from 'react-table';
import { visit } from 'turbolinks';
import {adminProgrammeCompanyPath} from "../../../javascript/application/ts_routes";

export default function InternshipTable({ fetchUrl, exportUrl, programmeId }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState();

  const columns = [
    {
      Cell: (row: any) => {
        return (
          <Link className={Classes.LINK} href={adminProgrammeCompanyPath(programmeId, row.original.attributes.company_id)}>
            {row.original.attributes.company_name}
          </Link>
        )
      },
      Header: 'Organisation',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.workstream}</div>,
      Header: 'Workstream',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.contact_name}</div>,
      Header: 'Contact name',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.attributes.contact_mobile_number}</div>;
      },
      Header: 'Contact number',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.attributes.contact_email}</div>;
      },
      Header: 'Contact email',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.total_pledge}</div>,
      Header: 'Total pledge',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.status}</div>,
      Header: 'Status',
      sortable: false,
    },
  ]

  React.useEffect(() => {
    getRecords();
  }, []);

  function getRecords() {
    $.ajax({
      url: fetchUrl,
      type: 'GET',
      dataType: 'json',
      cache: false,
      success: (data) => setData(data),
    });
  }

  const handleExport = () => {
    visit(exportUrl)
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 5000)
  }

  return(
    <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.REGULAR}>
      <Frow justifyContent="space-between" alignItems="center" className="mar-b-3">
        <div>
          <Subheading1 className="mar-b-1">Submission details</Subheading1>
          <Body muted={true}>Export the table to get a full breakdown</Body>
        </div>
        <div>
          <Button text="Export" minimal={true} onClick={handleExport} loading={isLoading} />
        </div>
      </Frow>
      <ReactTable
        columns={columns}
        data={data && data.data}
        PaginationComponent={PaginationComponent}
        className="-unthemed"
        minRows={1}
        pageSize={10}
      />
    </Card>
  )
}
