import * as React from "react";

export function dispatch<T = any>(identifier: string, detail?: T) {
  document.dispatchEvent(
    new CustomEvent<T>(identifier, { detail })
  );
}

export default function useCustomEventEffect<T = any>(
  identifier: string,
  callback?: (event?: CustomEvent<T>) => any
) {
  React.useEffect(() => {
    if (callback) {
      document.addEventListener(identifier, callback);
      return () => {
        document.removeEventListener(identifier, callback);
      };
    }
  }, [document, callback]);

  const effectDispatch = (detail?: T) => {
    dispatch<T>(identifier, detail);
  };

  return { dispatch: effectDispatch };
}
