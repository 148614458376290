import {
  Button,
  Callout,
  Card,
  CardLevel,
  Classes,
  Col,
  Drawer,
  FormGroup,
  Frow,
  Intent,
  ISelectOption,
  MultiSelect,
  PaddingLevel,
  Select,
  Subheading2,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { AllocationRun, Job } from '../../../javascript/models';
import { createToast } from '../../FlashToaster';
import { DrawerFooter } from '../../shared/DrawerFooter';
import { DrawerHeader } from '../../shared/DrawerHeader';
import { useSpraypaintNamedParams } from '../shared/hooks/useSpraypaint';

interface ICreateDrawer {
  allocationRun: AllocationRun;
  educationLevels: ISelectOption[];
  yearsOfGraduation: ISelectOption[];
  degreeSubjects: ISelectOption[];
  regionOptions: ISelectOption[];
  skillOptions: ISelectOption[];
  workstreamOptions: ISelectOption[];
  handleClose: () => void;
  fetchData: () => void;
}

interface ICondition {
  year_of_graduation?: string[];
  degree_subject?: string[];
  level_of_education?: string[];
  skills?: string[];
}

interface IJobAttributes {
  id: string;
  job_id?: string;
  selected_workstreams?: string[];
  selected_regions?: string[];
  conditions: ICondition[];
}

export function CreateDrawer({
  allocationRun,
  fetchData,
  educationLevels,
  yearsOfGraduation,
  degreeSubjects,
  handleClose,
  regionOptions,
  skillOptions,
  workstreamOptions,
}: ICreateDrawer) {
  const [jobs, setJobs] = React.useState<IJobAttributes[]>([{ id: Math.random().toString(), conditions: [{}] }]);

  const handleAddJob = () => {
    setJobs([...jobs, { id: Math.random().toString(), conditions: [{}] }]);
  };

  const { data: savedJobs, isLoading: isJobsLoading } = useSpraypaintNamedParams({
    initialScope: Job.where({ cycle_id: 4 }).per(10000),
  });

  const jobOptions = savedJobs.map((job) => ({
    label: `${job.companyName} - ${job.title} - ${job.region} - ${job.workstream}`,
    value: job.id,
  }));

  const handleSave = () => {
    allocationRun.runAttributes = jobs;
    allocationRun.save().then((success) => {
      if (success) {
        createToast({ text: 'Successfully created allocation run', type: 'success' });
        fetchData();
        handleClose();
      } else {
        createToast({ text: 'Unable to create allocation run', type: 'error' });
      }
    });
  };

  return (
    <Drawer maxWidth={800} isOpen={true} onClose={handleClose} canOutsideClickClose={false} canEscapeKeyClose={false}>
      <DrawerHeader title="New Allocation Run" handleClose={handleClose} />
      <Card paddingLevel={PaddingLevel.EXPANDED} level={CardLevel.NONE} style={{ overflowY: 'scroll' }}>
        <Frow flexDirection="column" verticalGutters={16}>
          {jobs.map((job) => {
            const handleJobUpdate = (updatedJob: IJobAttributes) => {
              setJobs(jobs.map((j) => (j.id === job.id ? updatedJob : j)));
            };

            const handleJobSelect = (selectedItem: ISelectOption) => {
              const selectedJob = savedJobs.find((job) => job.id === selectedItem.value);

              const updatedJob = { ...job };
              updatedJob.job_id = selectedItem.value;
              if (selectedJob) {
                updatedJob.selected_workstreams = [selectedJob.workstream];
                updatedJob.selected_regions = [selectedJob.region];
              }
              handleJobUpdate(updatedJob);
            };

            const selectedJob = jobOptions.find((option) => option.value === job.job_id);

            const handleAddCondition = () => {
              const updatedJob = { ...job, conditions: [...job.conditions, {}] };
              setJobs(jobs.map((j) => (j.id === job.id ? updatedJob : j)));
            };

            const selectedWorkstreamOptions = workstreamOptions.filter(
              (option) => job.selected_workstreams && job.selected_workstreams.includes(option.value),
            );

            const selectedRegionOptions = regionOptions.filter(
              (option) => job.selected_regions && job.selected_regions.includes(option.value),
            );

            const handleWorkstreamChange = (selectedItems) => {
              const updatedJob = { ...job };
              updatedJob.selected_workstreams = selectedItems.map((item) => item.value);
              handleJobUpdate(updatedJob);
            };

            const handleRegionChange = (selectedItems) => {
              const updatedJob = { ...job };
              updatedJob.selected_regions = selectedItems.map((item) => item.value);
              handleJobUpdate(updatedJob);
            };

            return (
              <Col key={job.id}>
                <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.FILLED}>
                  <Frow verticalGutters={16} flexDirection="column">
                    <Col>
                      <FormGroup label="Internship">
                        <Select
                          items={jobOptions}
                          onItemSelect={handleJobSelect}
                          buttonProps={{ large: true }}
                          fill={true}
                          activeItem={selectedJob}
                        />
                      </FormGroup>
                      <FormGroup
                        label="Workstream/s"
                        helperText="We will overwrite this field from the internship when you select it"
                      >
                        <MultiSelect
                          items={workstreamOptions}
                          fill={true}
                          selectedItems={selectedWorkstreamOptions}
                          tagInputProps={{ tagProps: { minimal: true, className: Classes.MUTED } }}
                          handleChange={handleWorkstreamChange}
                        />
                      </FormGroup>
                      <FormGroup
                        label="Region/s"
                        helperText="We will overwrite this field from the internship when you select it"
                      >
                        <MultiSelect
                          items={regionOptions}
                          fill={true}
                          selectedItems={selectedRegionOptions}
                          tagInputProps={{ tagProps: { minimal: true, className: Classes.MUTED } }}
                          handleChange={handleRegionChange}
                        />
                      </FormGroup>
                      <Callout intent={Intent.PRIMARY} className="mar-b-3">
                        Conditions act as OR logic so year of graduation is 2026 and level of education is Penultimate
                        year will be one condition OR year of graduation is 2025 and level of education is final year
                        will be another condition. Things you want in both need to be included in both
                      </Callout>
                      <Frow verticalGutters={16} flexDirection="column">
                        {job.conditions.map((condition, index) => {
                          const updateCondition = (key: string, value: string[]) => {
                            const updatedCondition = { ...condition, [key]: value };
                            const updatedJobConditions = job.conditions.map((c, i) => {
                              return i === index ? updatedCondition : c;
                            });
                            const updatedJob = { ...job, conditions: updatedJobConditions };
                            handleJobUpdate(updatedJob);
                          };

                          const handleEducationLevelChange = (selectedItems: ISelectOption[]) => {
                            updateCondition(
                              'level_of_education',
                              selectedItems.map((item) => item.value),
                            );
                          };

                          const handleYearOfGradutaionChange = (selectedItems: ISelectOption[]) => {
                            updateCondition(
                              'year_of_graduation',
                              selectedItems.map((item) => item.value),
                            );
                          };

                          const handleDegreeSubjectChange = (selectedItems: ISelectOption[]) => {
                            updateCondition(
                              'degree_subject',
                              selectedItems.map((item) => item.value),
                            );
                          };

                          const handleSkillChange = (selectedItems: ISelectOption[]) => {
                            updateCondition(
                              'skills',
                              selectedItems.map((item) => item.value),
                            );
                          };

                          return (
                            <Col key={index}>
                              <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.OUTLINED}>
                                <Subheading2 className="mar-b-2">Condition {index + 1}</Subheading2>
                                <FormGroup label="Level of education">
                                  <MultiSelect
                                    items={educationLevels}
                                    handleChange={handleEducationLevelChange}
                                    fill={true}
                                    tagInputProps={{ tagProps: { minimal: true, className: Classes.MUTED } }}
                                  />
                                </FormGroup>
                                <FormGroup label="Year of graduation">
                                  <MultiSelect
                                    items={yearsOfGraduation}
                                    handleChange={handleYearOfGradutaionChange}
                                    fill={true}
                                    tagInputProps={{ tagProps: { minimal: true, className: Classes.MUTED } }}
                                  />
                                </FormGroup>
                                <FormGroup label="Degree">
                                  <MultiSelect
                                    items={degreeSubjects}
                                    handleChange={handleDegreeSubjectChange}
                                    fill={true}
                                    tagInputProps={{ tagProps: { minimal: true, className: Classes.MUTED } }}
                                  />
                                </FormGroup>
                                <FormGroup label="Skills">
                                  <MultiSelect
                                    items={skillOptions}
                                    handleChange={handleSkillChange}
                                    fill={true}
                                    tagInputProps={{ tagProps: { minimal: true, className: Classes.MUTED } }}
                                  />
                                </FormGroup>
                              </Card>
                            </Col>
                          );
                        })}
                        <Col>
                          <Button minimal={true} fill={true} onClick={handleAddCondition}>
                            Add Condition
                          </Button>
                        </Col>
                      </Frow>
                    </Col>
                  </Frow>
                </Card>
              </Col>
            );
          })}
          <Col>
            <Button onClick={handleAddJob} minimal={true} fill={true}>
              Add Job
            </Button>
          </Col>
        </Frow>
      </Card>
      <DrawerFooter>
        <Frow justifyContent="space-between" gutters={16} alignItems="center" style={{ width: '100%' }}>
          <div>
            <Button minimal={true} text="Cancel" onClick={handleClose} />
          </div>
          <div>
            <Button text="Create" onClick={handleSave} />
          </div>
        </Frow>
      </DrawerFooter>
    </Drawer>
  );
}
