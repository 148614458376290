import { useEffect, useState } from 'react';

export function useSpraypaintFind<TModel = any, TMeta = any>(
  model: any,
  id: number | string,
  initialMeta: TMeta = null,
  initialExtraParams: Record<string, any> | undefined = null,
) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [data, setData] = useState<TModel>(null);
  const [meta, setMeta] = useState<TMeta>(initialMeta);
  const [extraParams, setExtraParams] = useState(initialExtraParams);

  let scope = model.scope();
  if (extraParams) scope = scope.extraParams(extraParams);

  useEffect(() => {
    fetchData();
  }, [id, extraParams]);

  function fetchData() {
    if (!id) {
      setIsLoading(false);
      return Promise.resolve();
    }

    setIsLoading(true);

    return scope
      .find(id)
      .then(({ data: spraypaintData, meta: spraypaintMeta }) => {
        setError(null);
        setData(spraypaintData as unknown as TModel);
        setMeta(spraypaintMeta as TMeta);
        return spraypaintData as unknown as TModel;
      })
      .catch((e) => {
        setError(e);
      })
      .then((passThrough) => {
        setIsLoading(false);
        setIsInitialLoading(false);
        return passThrough;
      });
  }

  return {
    data,
    error,
    extraParams,
    fetchData,
    isInitialLoading,
    isLoading,
    meta,
    scope,
    setExtraParams,
  };
}

export default useSpraypaintFind;
