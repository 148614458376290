const tieredUniversities = require('../tieredUniversities.json');

function tieredUniversity(university: string) {
  if (tieredUniversities[university]) {
    return tieredUniversities[university];
  }
  return "3"
}

export default tieredUniversity;
