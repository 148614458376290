import * as React from 'react';
import { visit } from 'turbolinks';
import { Card, CardLevel, Col, Frow, Lead } from '@pinpointhq/thumbtack';
import {
  adminProgrammeBookBuildingPath,
} from "../../../javascript/application/ts_routes";

interface IIndex {
  programmes: { id: string; name: string; logo_url: string }[]
}

export default function Index({ programmes }: IIndex) {
  return(
    <Frow gutters={16}>
      {programmes.map((programme) => {
        const handleClick = () => visit(adminProgrammeBookBuildingPath(programme.id))
        return(
          <Col key={programme.id} md={{ width: '1-3' }}>
            <Card level={CardLevel.OUTLINED} interactive={true} onClick={handleClick}>
              <div style={{ textAlign: 'center' }}>
                {programme.logo_url && (<img style={{ height: '40px' }} src={programme.logo_url}/>)}
              </div>
            </Card>
          </Col>
        )
      })}
    </Frow>
  )
}