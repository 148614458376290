import * as React from 'react';
import { Scope } from 'spraypaint';
import { useSpraypaintNamedParams } from './useSpraypaint';

const transformSortForGraphiti = (sorts) => {
  const sortsForSpraypaint = sorts.map(({ id, desc }) => ({ [id]: desc ? 'desc' : 'asc' }));
  return Object.assign({}, ...sortsForSpraypaint);

  // const sortsForSpraypaint = sorts.filter(({ id }) => id).map(({ id, desc }) => ({ [id]: desc ? 'desc' : 'asc' }));
  // return Object.assign({}, ...sortsForSpraypaint);
};

interface IUseSpraypaintTableParams {
  initialScope: Scope;
  searchIdentifier: string;
  initialQuery?: any;
  initialPageSize?: number;
  initialMeta?: any;
  initialSort?: any;
  initialExtraParams?: Record<string, unknown>;
}

export const useSpraypaintTableNamedParams = ({
  initialScope,
  searchIdentifier,
  initialQuery = null,
  initialPageSize = null,
  initialMeta = null,
  initialSort = [],
  initialExtraParams = null,
}: IUseSpraypaintTableParams) =>
  useSpraypaintTable(
    initialScope,
    searchIdentifier,
    initialQuery,
    initialPageSize,
    initialMeta,
    initialSort,
    initialExtraParams,
  );

/**
 * @deprecated (TimKrins) use useSpraypaintTableNamedParams instead please
 */
export function useSpraypaintTable(
  initialScope: Scope,
  searchIdentifier: string,
  initialQuery = null,
  initialPageSize: number = null,
  initialMeta = null,
  initialSort = [],
  initialExtraParams: Record<string, unknown> = null,
) {
  const applyStatsToScope = (inputScope: Scope) => inputScope.stats({ total: 'count' });
  const {
    data,
    error,
    extraParams,
    fetchData,
    isInitialLoading,
    isLoading,
    meta,
    scope,
    order,
    page,
    pageSize,
    query,
    setExtraParams,
    setOrder,
    setPage,
    setPageSize,
    setQuery,
    setScope: setUnderlyingScope,
  } = useSpraypaintNamedParams({
    initialScope: applyStatsToScope(initialScope),
    initialQuery: initialQuery || {},
    initialPageSize: initialPageSize || 10,
    initialMeta,
    initialExtraParams,
    initialOrder: transformSortForGraphiti(initialSort),
  });

  const setScope = (inputScope: Scope) => setUnderlyingScope(applyStatsToScope(inputScope));

  const total: number = meta && meta.stats && meta.stats.total && meta.stats.total.count ? meta.stats.total.count : 0;
  const pages = Math.ceil(total / pageSize) || 1;

  function setSearch(searchTerm: string) {
    handlePageChange(0);
    setQuery({ search: searchTerm });
  }

  const { search } = query as { search?: string };

  React.useEffect(() => {
    const eventListenerName = `${searchIdentifier}:search`;
    document.addEventListener(eventListenerName, handleSearch);

    return () => {
      document.removeEventListener(eventListenerName, handleSearch);
    };
  }, []);

  function handlePageSizeChange(newPageSize: number) {
    if (newPageSize) setPageSize(newPageSize);
  }

  function handlePageChange(newZeroIndexedPage: number) {
    // PageNumber is 0 indexed in ReactTable but starts for 1 for Graphiti
    setPage(newZeroIndexedPage + 1);
  }

  function handleSearch(event) {
    setSearch(event.detail.searchTerm);
  }

  const zeroIndexedPage = page - 1;

  function handleSort(sorts) {
    setOrder(transformSortForGraphiti(sorts));
  }

  const tableProps = {
    data,
    /**
     * Takes a page, with zero representing the first page.
     * @param {number} page - The zero-indexed page.
     */
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    isInitialLoading,
    isLoading,
    /**
     * `manual` means that we will handling the sorting and pagination server-side
     */
    manual: true,
    meta: { pages, total },
    /**
     * The current page, with the first page being zero.
     */
    page: zeroIndexedPage || 0,
    pageSize,
    pages,
    setPageSize,
  };

  return {
    data,
    error,
    extraParams,
    fetchData,
    isInitialLoading,
    isLoading,
    meta,
    order,
    page,
    pageSize,
    query,
    scope,
    search,
    setExtraParams,
    setOrder,
    setPage,
    setPageSize,
    setQuery,
    setScope,
    setSearch,
    tableProps,
  };
}

export default useSpraypaintTable;
