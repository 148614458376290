import { Alerter, Button, Dialog, FormGroup, Frow, ISelectOption, RadioSelect, Select } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { createToast } from '../../../../../../FlashToaster';

export function ChangeRegionDialog({ fetchData, handleClose, isOpen, job, regionOptions }) {
  const [selectedRegion, setSelectedRegion] = React.useState<string>();
  const [removeExistingCandidates, setRemoveExistingCandidates] = React.useState<string>('false');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleSelect = (selectedOption: ISelectOption) => {
    setSelectedRegion(selectedOption.value);
  };

  const key = 'job-updating';

  const handleSubmit = () => {
    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      onConfirm: () => {
        setIsLoading(true);
        createToast({ type: 'success', isLoading: true, text: 'Updating region', key });
        job.region = selectedRegion;
        job.removeExistingCandidates = removeExistingCandidates === 'true';
        job.save().then((success) => {
          if (success) {
            createToast({ type: 'success', text: 'Successfully changed job region', key });
            fetchData();
          } else {
            createToast({ type: 'error', text: 'Unable to change region', key });
          }
          setIsLoading(false);
        });
      },
      text:
        "Are you sure you want to change the region? We won't automatically allocate a new slate you will need to do that after changing the region",
    });
  };

  const selectedOption = regionOptions.find((option) => option.value === selectedRegion);

  const radios = [
    { value: 'false', label: 'No' },
    { value: 'true', label: 'Yes' },
  ].map((applicationOption) => {
    const handleChange = (selectedItem) => {
      if (selectedItem.currentTarget.checked) setRemoveExistingCandidates(applicationOption.value);
    };

    return (
      <div className="col-flex-grow-1" key={applicationOption.label}>
        <RadioSelect
          label={applicationOption.label}
          onChange={handleChange}
          checked={applicationOption.value === removeExistingCandidates}
        />
      </div>
    );
  });

  return (
    <Dialog title="Change region" isOpen={isOpen} onClose={handleClose}>
      <FormGroup label="New region">
        <Select
          onItemSelect={handleSelect}
          items={regionOptions}
          fill={true}
          buttonProps={{ large: true }}
          activeItem={selectedOption}
        />
      </FormGroup>
      <FormGroup label="Remove existing candidates" helperText="Do you want to remove the existing slate?">
        <Frow flexDirection="row" flexWrap="nowrap" justifyContent="center">
          {radios}
        </Frow>
      </FormGroup>
      <Button
        fill={true}
        text="Update"
        large={true}
        disabled={!selectedRegion}
        onClick={handleSubmit}
        loading={isLoading}
      />
    </Dialog>
  );
}
