import { Checkbox as ThumbtackCheckbox } from '@pinpointhq/thumbtack';
import * as React from 'react';

interface ICheckboxProps<T extends { id?: string }> {
  data: T[];
  resource: T;
  selection: T[];
  setSelection(selection: T[]): void;
}

export function Checkbox<T extends { id?: string }>({ data, resource, selection, setSelection }: ICheckboxProps<T>) {
  const selectionIds = selection.map(({ id }) => id);
  const isChecked = selectionIds.includes(resource.id);

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    return handleUpdateCheckbox();
  };

  function handleUpdateCheckbox() {
    if (isChecked) {
      setSelection(selection.filter((item) => item.id !== resource.id));
    } else {
      setSelection([...selection, resource]);
    }
  }

  return (
    <div role="presentation" onMouseDown={onClick}>
      <ThumbtackCheckbox checked={isChecked} className="mar-b-0" />
    </div>
  );
}
