import {
  Body,
  Callout,
  Card,
  CardLevel,
  Classes,
  Dialog,
  Frow,
  Intent,
  ISelectOption,
  PaddingLevel,
  ProgressBar,
  Select,
  Subheading1,
  Tooltip,
  WorkflowTag,
} from '@pinpointhq/thumbtack';
import { truncateMiddle } from '@pinpointhq/thumbtack/dist/common/helpers';
import { format } from 'date-fns';
import * as React from 'react';
import ReactTable from 'react-table';
import { AllocationRequest } from '../../../javascript/models';
import { createToast } from '../../FlashToaster';
import { Checkbox } from '../../shared/Checkbox';
import { HeaderCheckbox } from '../../shared/HeaderCheckbox';
import useVisibility from '../../shared/hooks/useVisibility';
import { promiseAllWithProgress } from '../../shared/promiseAllWithProgress';
import { SanitizedRunningText } from '../../shared/SanitizedRunningText';
import Search from '../../shared/Search';
import { AllocateDialog } from '../shared/AllocateDialog';
import { useSpraypaintTableNamedParams } from '../shared/hooks/useSpraypaintTable';
import NewPaginationComponent from '../shared/NewPaginationComponent';
import { AllocationRequestActionBar } from './allocationRequests/AllocationRequestActionBar';
import { RejectDialog } from './allocationRequests/RejectDialog';

interface IAllocationRequests {
  cycleId: string;
  degreeSubjectOptions: ISelectOption[];
  regionOptions: ISelectOption[];
  workstreamOptions: ISelectOption[];
}

export default function AllocationRequests({
  cycleId,
  degreeSubjectOptions,
  regionOptions,
  workstreamOptions,
}: IAllocationRequests) {
  const [selectedReason, setSelectedReason] = React.useState();
  const [searchTerm, setSearchTerm] = React.useState<string>();
  const [status, setStatus] = React.useState<string>('all');
  const [selection, setSelection] = React.useState<AllocationRequest[]>([]);
  const { isOpen: allocateOpen, handleClose: handleAllocateClose, handleOpen: handleAllocateOpen } = useVisibility();
  const { isOpen: rejectOpen, handleClose: handleRejectClose, handleOpen: handleRejectOpen } = useVisibility();

  const { tableProps, fetchData } = useSpraypaintTableNamedParams({
    initialScope: AllocationRequest.includes([{ job: ['company'] }])
      .where({ cycle_id: cycleId })
      .order({ id: 'desc' }),
    searchIdentifier: 'allocation-requests',
  });

  const columns = [
    {
      Cell: ({ original }) => <Checkbox resource={original} data={tableProps.data} {...{ selection, setSelection }} />,
      Header: () => <HeaderCheckbox selection={selection} setSelection={setSelection} data={tableProps.data} />,
      accessor: 'id',
      className: '-checkbox',
      headerClassName: '-checkbox',
      // Max width is the checkbox size (16px) + 8px of padding either side. Needs to be 40 due to IE..
      maxWidth: 40,
      sortable: false,
    },
    {
      Cell: (row: any) => {
        const status = row.original.status;

        if (status === 'completed') {
          return <WorkflowTag text="Completed" intent={Intent.SUCCESS} />;
        }

        if (status === 'rejected') {
          return <WorkflowTag text="Rejected" intent={Intent.DANGER} />;
        }

        return <WorkflowTag text="Outstanding" intent={Intent.NONE} />;
      },
      Header: 'Status',
      sortable: false,
      width: 140,
    },
    {
      Cell: (row: any) => {
        return (
          <div>
            <Tooltip content={row.original.job.company ? row.original.job.company.name : 'No organisation'}>
              {row.original.job.company ? row.original.job.company.name : 'No organisation'}
            </Tooltip>
          </div>
        );
      },
      Header: 'Organisation',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return (
          <div>
            <Tooltip content={row.original.job.title}>{row.original.job.title}</Tooltip>
          </div>
        );
      },
      Header: 'Internship',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return (
          <div>
            {row.original.job.applicationsCount} ({row.original.job.additionalAllocationCount})
          </div>
        );
      },
      Header: 'Applications',
      sortable: false,
      width: 140,
    },
    {
      Cell: (row: any) => {
        const handleClick = () => setSelectedReason(row.original.reason);
        return (
          <div onClick={handleClick}>
            <span className={Classes.LINK}>{truncateMiddle(row.original.reason, 10, 10, '...')}</span>
          </div>
        );
      },
      Header: 'Reason',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{format(row.original.createdAt, 'DD/MM/YYYY [at] HH:mm')}</div>;
      },
      Header: 'Requested',
      sortable: false,
    },
  ];

  const identifier = 'allocation-request';

  React.useEffect(() => {
    document.addEventListener(`${identifier}:search`, handleSearch);
    return () => {
      document.removeEventListener(`${identifier}:search`, handleSearch);
    };
  }, [searchTerm]);

  const handleSearch = (event) => setSearchTerm(event.detail.searchTerm);

  const handleClose = () => setSelectedReason(null);

  const handleStatusChange = (item) => setStatus(item.value);

  const statuses = [
    { value: 'all', label: 'All Statuses' },
    { value: 'outstanding', label: 'Outstanding' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'completed', label: 'Completed' },
  ];

  const selectStatus = statuses.find((s) => s.value === status);

  const handleExport = () => {
    // visit(exportAdminProgrammeAllocationRequestsPath(programmeId, { search: searchTerm, status, cycle_id: cycleId }));
  };

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  const handleCancel = () => {
    handleRejectClose();
    handleAllocateClose();
    setSelection([]);
  };

  const toastKey = 'allocation-request-toast';

  const handleSubmit = ({
    allocationAmount,
    selectedSectors,
    selectedEducation,
    selectedGraduation,
    selectedDegree,
    selectedLocations,
  }) => {
    promiseAllWithProgress({
      callback: (progress) => displayProgressToast(progress),
      promises: selection.map((allocationRequest) =>
        approveAllocationRequest(
          allocationRequest,
          allocationAmount,
          selectedSectors,
          selectedEducation,
          selectedGraduation,
          selectedDegree,
          selectedLocations,
        ),
      ),
    }).then(() => {
      displaySuccessToast();
      setSelection([]);
      fetchData();
      handleAllocateClose();
    });
  };

  async function approveAllocationRequest(
    allocationRequest,
    allocationAmount,
    selectedSectors,
    selectedEducation,
    selectedGraduation,
    selectedDegree,
    selectedLocations,
  ) {
    allocationRequest.status = 'completed';
    allocationRequest.allocationOptions = {
      amount: allocationAmount,
      workstreams: selectedSectors,
      education_levels: selectedEducation,
      graduation_years: selectedGraduation,
      degree_subjects: selectedDegree,
      regions: selectedLocations,
    };
    await allocationRequest.save();
  }

  function displaySuccessToast() {
    setTimeout(() => {
      createToast({
        key: toastKey,
        text: 'Successfully updated requests',
        type: 'success',
      });
    }, 1000);
  }

  function displayProgressToast(progress) {
    const toastContent = (
      <>
        Updating allocation requests {progress.complete} of {progress.total}
        <div style={{ marginTop: '4px' }}>
          <ProgressBar value={progress.percentage / 100} intent={Intent.NONE} />
        </div>
      </>
    );

    createToast({
      isLoading: true,
      key: toastKey,
      text: toastContent,
    });
  }

  const callout = (
    <Callout intent={Intent.PRIMARY} title="Allocate candidates" className="mar-b-3">
      We will allocate candidates to all selected allocation requests that have not already been completed. Any complete
      requests will be skipped.
    </Callout>
  );

  return (
    <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
      <div className="mar-b-2">
        <Subheading1>Allocation Requests</Subheading1>
        <Body muted={true} className="mar-t-1">
          Organisations that have requested additional candidates
        </Body>
      </div>
      <div className="mar-b-2">
        <div style={{ display: selection.length > 0 ? 'block' : 'none' }}>
          <div>
            <AllocationRequestActionBar
              onCancel={handleCancel}
              handleAllocationOpen={handleAllocateOpen}
              handleRejectOpen={handleRejectOpen}
            />
          </div>
        </div>
        <Frow gutters={16} verticalGutters={16} style={{ display: selection.length > 0 ? 'none' : 'flex' }}>
          <div className="bp3-col--flex-grow-1">
            <Search target={identifier} />
          </div>
          <div>
            <Select
              onItemSelect={handleStatusChange}
              items={statuses}
              activeItem={selectStatus}
              buttonProps={{ large: true }}
            />
          </div>
          <div>{/* <Button minimal={true} text="Export" large={true} onClick={handleExport} /> */}</div>
        </Frow>
      </div>
      <ReactTable
        {...tableProps}
        columns={columns}
        // PaginationComponent={PaginationComponent}
        PaginationComponent={NewPaginationComponent}
        getPaginationProps={paginationProps}
        className="-unthemed"
        minRows={1}
        pageSize={10}
      />
      <Dialog title="Reason" isOpen={!!selectedReason} onClose={handleClose}>
        <SanitizedRunningText text={selectedReason} muted={true} />
      </Dialog>
      {allocateOpen && (
        <AllocateDialog
          callout={callout}
          isOpen={true}
          degreeSubjectOptions={degreeSubjectOptions}
          regionOptions={regionOptions}
          workstreamOptions={workstreamOptions}
          handleSubmit={handleSubmit}
          handleClose={handleAllocateClose}
        />
      )}
      {rejectOpen && (
        <RejectDialog
          selection={selection}
          setSelection={setSelection}
          handleClose={handleRejectClose}
          fetchData={fetchData}
        />
      )}
    </Card>
  );
}
