import { Button, Frow, IconNames16 } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { ActionBar } from '../../../../shared/ActionBar';

export function ReleaseRequestActionBar({ handleApproveOpen, handleRejectOpen, onCancel }) {
  return (
    <ActionBar isOpen={true} onCancel={onCancel}>
      <div style={{ padding: '1px 0' }}>
        <Frow gutters={8}>
          <div>
            <Button icon={IconNames16.ADD} minimal={true} text="Approve" onClick={handleApproveOpen} />
          </div>
          <div>
            <Button icon={IconNames16.CLOSE} minimal={true} text="Reject" onClick={handleRejectOpen} />
          </div>
        </Frow>
      </div>
    </ActionBar>
  );
}
