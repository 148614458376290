import { Classes, Colors, Heading2, IconButton40, IconNames16 } from '@pinpointhq/thumbtack';
import * as React from 'react';

interface IDrawerHeaderProps {
  additionalActions?: React.ReactNode;
  title: string;
  handleClose(): void;
}

export function DrawerHeader({
                               additionalActions,
                               handleClose,
                               title,
                               children,
                             }: React.PropsWithChildren<IDrawerHeaderProps>) {
  return (
    <div
      className={Classes.DRAWER_HEADER}
      style={{
        backgroundColor: Colors.IVORY_05,
        boxShadow: 'none',
        flexWrap: 'wrap',
        margin: '0',
        padding: '24px 40px',
        alignItems: 'center',
      }}
    >
      <div>
        <Heading2 style={{ marginRight: '24px' }}>{title}</Heading2>
      </div>
      <div>
        {additionalActions}
        <IconButton40
          className={Classes.DIALOG_CLOSE_BUTTON}
          icon={IconNames16.CLOSE}
          aria-label="Close"
          onClick={handleClose}
        />
      </div>
      {children}
    </div>
  );
}
