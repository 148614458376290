import { useEffect, useState } from 'react';

// based on react-use (unlicence)
const useLocalStorage = <T>(key: string, initialValue?: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const serializer = JSON.stringify;
  const deserializer = JSON.parse;

  const [state, setState] = useState<T>(() => {
    try {
      const localStorageValue = localStorage.getItem(key);
      if (localStorageValue !== null) {
        return deserializer(localStorageValue);
      }

      if (initialValue) localStorage.setItem(key, serializer(initialValue));
      return initialValue;
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(key, serializer(state));
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. Also JSON.stringify can throw.
    }
  }, [state]);

  return [state, setState];
};

export default useLocalStorage;
