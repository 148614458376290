import { Button, Callout, Dialog, FormGroup, Intent, TextArea } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { BulkReleaseRequest } from '../../../../../javascript/models';
import { createToast } from '../../../../FlashToaster';

interface IApproveDialog {
  bulkReleaseRequest: BulkReleaseRequest;
  fetchData: () => void;
  handleClose: () => void;
}

export function ApproveDialog({ bulkReleaseRequest, fetchData, handleClose }: IApproveDialog) {
  const [reasonForApproval, setReasonForApproval] = React.useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const handleApprovalReasonChange = (event) => {
    setReasonForApproval(event.target.value);
  };

  const toastKey = 'rejectAllocationRequest';

  const handleSubmit = () => {
    bulkReleaseRequest.status = 'approved';
    bulkReleaseRequest.response = reasonForApproval;
    setIsSubmitting(true);
    bulkReleaseRequest.save().then((success) => {
      if (success) {
        createToast({ type: 'success', text: 'Successfully approved request', key: toastKey });
        fetchData();
        setIsSubmitting(false);
        handleClose();
      } else {
        setIsSubmitting(false);
        createToast({ type: 'error', text: 'Unable to approve request', key: toastKey });
      }
    });
  };

  return (
    <Dialog title="Approve request" isOpen={true} onClose={handleClose}>
      <Callout intent={Intent.PRIMARY} className="mar-b-3">
        The reason you are approving this release request will be sent to the primary contact in an email
      </Callout>
      <FormGroup label="Reason for approval">
        <TextArea value={reasonForApproval} onChange={handleApprovalReasonChange} />
      </FormGroup>
      <div className="mar-t-2">
        <Button
          onClick={handleSubmit}
          text="Approve"
          large={true}
          fill={true}
          disabled={!reasonForApproval}
          loading={isSubmitting}
        />
      </div>
    </Dialog>
  );
}
