import * as React from 'react';

const useInterval = (callback, delay) => {
  const [isOperating, setIsOperating] = React.useState<boolean>(false);
  const [id, setId] = React.useState<number>(null);

  const savedCallback = React.useRef(null);

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function handler() {
      savedCallback.current();
    }

    if (isOperating && !id) {
      const intervalId = window.setInterval(handler, delay);
      setId(intervalId);
      return () => clearInterval(intervalId);
    }
  }, [isOperating]);

  React.useEffect(() => {
    if (!isOperating) {
      clearInterval(id);
      setId(null);
    }
  }, [isOperating]);

  function start() {
    setIsOperating(true);
  }

  function stop() {
    setIsOperating(false);
  }

  return {
    isOperating,
    start,
    stop,
  };
};

export default useInterval;
