import * as React from 'react';
import { Caption, IconButton32, IconNames8 } from '@pinpointhq/thumbtack';

interface IProps {
  data: any;
  page: any;
  canPrevious: boolean;
  canNext: boolean;
  pageSize: number;
  onPageChange(page: any): void;
}

export default class PaginationComponent extends React.Component<IProps> {
  constructor(props) {
    super(props);
  }

  public startNumber() {
    if (this.props.data.length < 1) return 0;

    return this.props.pageSize * this.props.page + 1;
  }

  public endNumber() {
    let endNumber = this.startNumber() - 1 + this.props.pageSize;
    if (endNumber > this.props.data.length) {
      endNumber = this.props.data.length;
    }
    return endNumber;
  }

  public render() {
    return (
      <div className="pad-v-2 pad-h-3">
        <div className="frow frow--items-center frow--justify-between frow--gutters-2x">
          <div>
            <Caption muted={true}>
              Showing {this.startNumber()} to {this.endNumber()} of {this.props.data.length}
            </Caption>
          </div>
          <div>
            <div className="frow frow--gutters frow--nowrap">
              <IconButton32
                icon={IconNames8.CARET_LEFT}
                onClick={() => this.props.onPageChange(this.props.page - 1)}
                disabled={!this.props.canPrevious}
                title="Previous"
              />
              <IconButton32
                icon={IconNames8.CARET_RIGHT}
                onClick={() => this.props.onPageChange(this.props.page + 1)}
                disabled={!this.props.canNext}
                title="Next"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
