import * as React from 'react';
import ReactTable from 'react-table';
import PaginationComponent from '../../../shared/table/PaginationComponent';
import humanize from '../../../shared/utilities/humanize';

export function AllocationTable({ applications }) {
  const columns = [
    {
      Cell: (row) => {
        return <div>{row.original.job.title}</div>;
      },
      Header: 'Internship title',
      sortable: false,
    },
    {
      Cell: (row) => {
        return <div>{row.original.job.company ? row.original.job.company.name : 'No company'}</div>;
      },
      Header: 'Company',
      sortable: false,
    },
    {
      Cell: (row) => {
        return <div>{humanize(row.original.status)}</div>;
      },
      Header: 'Status',
      sortable: false,
    },
    {
      Cell: (row) => {
        return <div>{row.original.programmeCycleName}</div>;
      },
      Header: 'Cycle',
      sortable: false,
    },
  ];

  const paginationProps = React.useCallback(() => {
    return {
      meta: {
        total: applications.length,
      },
      pageNumber: 0,
    };
  }, [applications]);

  return (
    <ReactTable
      columns={columns}
      data={applications}
      PaginationComponent={PaginationComponent}
      getPaginationProps={paginationProps}
      className="-unthemed"
      minRows={1}
    />
  );
}
