import {
  Button,
  Card,
  CardLevel,
  Col,
  FormGroup,
  Frow,
  ISelectOption,
  PaddingLevel,
  Select,
} from '@pinpointhq/thumbtack';
import { visit } from 'turbolinks';
import * as React from 'react';
import { useState } from 'react';
import useVisibility from '../../../shared/hooks/useVisibility';
import Search from '../../../shared/Search';
import DateRangeFilter from "../../../shared/DateRangeFilter";
import {exportAdminProgrammeCompanyTimelinesPath} from "../../../../javascript/application/ts_routes";

interface IDateProps {
  gt?: string;
  lt?: string
}

interface IFilters {
  first_contact_date?: IDateProps;
  start_interviews?: IDateProps;
  make_offers?: IDateProps;
  finalise_offers?: IDateProps;
  complete_hires?: IDateProps;
  internship_start_date?: IDateProps;
  internship_end_date?: IDateProps;
}

export default function Filters({ cycleId, cycleOptions, handleApplyFilters, searchTerm, setSearchTerm, setCycleId, programmeId }) {
  const { isOpen, toggleOpen } = useVisibility(false)
  const [filters, setFilters] = useState<IFilters>({})
  const identifier = 'company-timelines-index'

  React.useEffect(() => {
    document.addEventListener(`${identifier}:search`, handleSearch);
    return () => {
      document.removeEventListener(`${identifier}:search`, handleSearch);
    };
  }, [searchTerm]);

  const handleSearch = (event) => setSearchTerm(event.detail.searchTerm);
  const handleApply = () => handleApplyFilters(filters)
  const handleCycleChange = (selectedItem: ISelectOption) => setCycleId(selectedItem.value)

  const activeCycle = cycleOptions.find((option) => option.value === cycleId)

  const firstContactDateChange = (startDate, endDate) => setFilters({ first_contact_date: { gt: startDate, lt: endDate } })
  const interviewDateChange = (startDate, endDate) => setFilters({ start_interviews: { gt: startDate, lt: endDate } })
  const makeOfferDateChange = (startDate, endDate) => setFilters({ make_offers: { gt: startDate, lt: endDate } })
  const finalizeOfferDateChange = (startDate, endDate) => setFilters({ finalise_offers: { gt: startDate, lt: endDate } })
  const completeHireDateChange = (startDate, endDate) => setFilters({ complete_hires: { gt: startDate, lt: endDate } })
  const startDateChange = (startDate, endDate) => setFilters({ internship_start_date: { gt: startDate, lt: endDate } })
  const endDateChange = (startDate, endDate) => setFilters({ internship_end_date: { gt: startDate, lt: endDate } })

  const handleExport = () => {
    visit(exportAdminProgrammeCompanyTimelinesPath(programmeId, { filters: { ...filters, cycle_id: cycleId }, search: searchTerm }))
  }

  return(
    <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.EXPANDED}>
      <Frow gutters={16} verticalGutters={16}>
        <Col width="1-1">
          <Frow gutters={16} verticalGutters={16} alignItems="flex-end">
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Cycle" style={{ marginBottom: 0 }}>
                <Select
                  activeItem={activeCycle}
                  items={cycleOptions}
                  fill={true}
                  buttonProps={{ large: true }}
                  onItemSelect={handleCycleChange}
                />
              </FormGroup>
            </Col>
          </Frow>
        </Col>
        <div className="bp3-col--flex-grow-1">
          <Search target={identifier} />
        </div>
        <div>
          <Frow>
            <div>
              <Button minimal={true} text="Export" large={true} onClick={handleExport} />
            </div>
            <div>
              <Button minimal={true} text="Filters" large={true} onClick={toggleOpen} />
            </div>
          </Frow>
        </div>
      </Frow>
      {isOpen && (
        <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR} className="mar-t-2">
          <Frow gutters={16} verticalGutters={16} alignContent="flex-end" alignItems="flex-end" className="mar-b-2">
            <Col md={{ width: '1-2' }} lg={{ width: '1-3' }}>
              <DateRangeFilter label="First contact" handleDateChange={firstContactDateChange} />
            </Col>
            <Col md={{ width: '1-2' }} lg={{ width: '1-3' }}>
              <DateRangeFilter label="Interviews" handleDateChange={interviewDateChange} />
            </Col>
            <Col md={{ width: '1-2' }} lg={{ width: '1-3' }}>
              <DateRangeFilter label="Make offer" handleDateChange={makeOfferDateChange} />
            </Col>
            <Col md={{ width: '1-2' }} lg={{ width: '1-3' }}>
              <DateRangeFilter label="Finalize offer" handleDateChange={finalizeOfferDateChange} />
            </Col>
            <Col md={{ width: '1-2' }} lg={{ width: '1-3' }}>
              <DateRangeFilter label="Complete hires" handleDateChange={completeHireDateChange} />
            </Col>
            <Col md={{ width: '1-2' }} lg={{ width: '1-3' }}>
              <DateRangeFilter label="Start date" handleDateChange={startDateChange} />
            </Col>
            <Col md={{ width: '1-2' }} lg={{ width: '1-3' }}>
              <DateRangeFilter label="End date" handleDateChange={endDateChange} />
            </Col>
            <Col md={{ width: '1-2' }} lg={{ width: '1-3' }}>
              <Button large={true} fill={true} text="Apply" onClick={handleApply} />
            </Col>
          </Frow>
        </Card>
      )}
    </Card>
  )
}

