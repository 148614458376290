import * as React from 'react';

import { IndicatorTag, Intent } from '@pinpointhq/thumbtack';

import tieredUniversity from '../../../../shared/utilities/tieredUniversity';

export default function University({ answer }: { candidate: any; answer: any }) {
  const choice = answer.attributes.multiple_choice_answer[0];

  const ChoiceTag = () => {
    if (!choice) return null;
    const universityTier = tieredUniversity(choice);

    if (!universityTier) return <span />

    return (
      <IndicatorTag intent={Intent.SUCCESS} className="mar-l-2" outline={true}>
        {tieredUniversity(choice)}
      </IndicatorTag>
    );
  };
  return (
    <div>
      {choice}
      {ChoiceTag()}
    </div>
  );
}
