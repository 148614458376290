import * as React from 'react';

import * as pluralize from 'pluralize';
import classNames from 'classnames';

import PaginationComponent from '../shared/table/PaginationComponent';
import ReactTable, { TableProps } from 'react-table';
import {
  adminApiCompaniesPath,
  adminApiJobsPath,
} from '../../javascript/application/ts_routes';
import { Callout, Card, CardLevel, Heading2, Intent, Super2 } from '@pinpointhq/thumbtack';
import LoadingSpinner from '../shared/LoadingSpinner';
import useDebounce from '../shared/hooks/useDebounce';
import Search from '../shared/Search';
import { companyColumns, jobColumns } from './table/columns';

interface ITable {
  identifier: string;
  defaultPageSize: number;
  resourceName: string;
}

export default function Index(props: ITable) {
  const { identifier, defaultPageSize, resourceName } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [included, setIncluded] = React.useState([]);
  const [meta, setMeta] = React.useState(null);
  const [pageSize, setPageSize] = React.useState<number>(defaultPageSize);
  const [page, setPage] = React.useState<number>(0);
  const [searchTerm, setSearchTerm] = React.useState<string>(null);
  const [hasSearchChanged, setHasSearchChanged] = React.useState<boolean>(false);

  const [errors, setErrors] = React.useState<any>(null);

  const debouncedSearchChange = useDebounce(hasSearchChanged, 500);

  const resourceOptions = {
    Job: {
      indexPath: adminApiJobsPath,
      columns: jobColumns
    },
    Company: {
      indexPath: adminApiCompaniesPath,
      columns: companyColumns
    }
  }

  const indexPath = resourceOptions[resourceName].indexPath;

  React.useEffect(() => {
    if (!isLoading && debouncedSearchChange) {
      fetchWithSearch();
    }
  }, [debouncedSearchChange, isLoading]);

  function fetchWithSearch() {
    if (!isLoading) {
      setIsLoading(true);
      setHasSearchChanged(false);
    }
  }

  React.useEffect(() => {
    setIsLoading(true);
  }, [page, pageSize]);

  React.useEffect(() => {
    if (isLoading) getRecords();
  }, [isLoading]);

  React.useEffect(() => {
    document.addEventListener(`${identifier}:search`, handleSearch);
    return () => {
      document.removeEventListener(`${identifier}:search`, handleSearch);
    };
  }, [page, pageSize, searchTerm]);

  function handleSearch(event) {
    setSearchTerm(event.detail.searchTerm);
    setHasSearchChanged(true);
  }

  // PageNumber is 0 indexed in ReactTable but starts for 1 for Graphiti
  async function getRecords() {
    setIsLoading(true);
    await fetch(
      indexPath({
        page: page + 1,
        page_size: pageSize,
        search_term: searchTerm,
      }),
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => res.json())
      .then((json) => {
        if (json.errors) {
          setErrors(json.errors);
        } else {
          setErrors(null);
          setData(json.data);
          setIncluded(json.included);
          const totalRecords = json.meta.stats.total.count;
          setMeta({
            total: totalRecords,
            pages: Math.ceil(totalRecords / pageSize),
          });
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  function handlePageChange(newPage: number) {
    setPage(newPage);
  }

  function handlePageSizeChange(newPageSize: number) {
    if (newPageSize) {
      setPageSize(newPageSize);
    }
    handlePageChange(0);
  }

  const pageNumber = page || 0;

  const pages = meta ? meta.pages : -1;

  const paginationProps = () => ({ meta, pageNumber });

  if (errors) {
    return (
      <>
        <Card level={CardLevel.FILLED}>
          <Callout
            intent={Intent.DANGER}
            title={`Oops! There was a problem when trying to retrieve ${pluralize(resourceName)}.`}
          >
            {errors.map((error, index) => {
              return <p key={index}>{error.title}</p>;
            })}
          </Callout>
        </Card>
      </>
    );
  }

  return (
    <>
      <Card level={CardLevel.FILLED}>
        <div className="frow frow--items-center">
          <Super2 className={isLoading && 'bp3-skeleton'}>{meta ? meta.total : '100'}</Super2>
          <Heading2 style={{ margin: '0 0 0 16px' }}>
            {meta ? pluralize(resourceName, meta.total, false) : pluralize(resourceName)}
          </Heading2>
        </div>
      </Card>
      <div className="pad-a-5 pad-b-0">
        <Search target={identifier} />
      </div>
      <div className="pad-a-5">
        <ReactTable
          columns={resourceOptions[resourceName].columns(isLoading, included)}
          {...{
            data,
            identifier,
            meta,
            pages,
            pageSize,
            PaginationComponent,
            LoadingComponent,
          }}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          getPaginationProps={paginationProps}
          manual={true}
          loading={isLoading}
          showPagination={true}
          resizable={false}
          className="-unthemed"
          minRows={0}
        />
      </div>
    </>
  );
}

const LoadingComponent = (props: TableProps) => {
  const className = classNames('-loading', { '-active': props.loading });

  return (
    <div className={className}>
      <div className="-loading-inner">
        <LoadingSpinner />
      </div>
    </div>
  );
};
