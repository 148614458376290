import { Card, CardLevel, Classes, Drawer, PaddingLevel } from '@pinpointhq/thumbtack';
import * as React from 'react';
import ReactTable from 'react-table';
import { BulkReleaseRequest, Candidate, ReleaseRequest } from '../../../../../javascript/models';
import { DrawerHeader } from '../../../../shared/DrawerHeader';
import PaginationComponent from '../../../candidates/awaiting/PaginationComponent';
import { ShowDrawer } from '../../../candidates/table/ShowDrawer';

interface ReleaseRequestDialog {
  bulkReleaseRequest: BulkReleaseRequest;
  handleClose: () => void;
}

export function ReleaseRequestDialog({ handleClose, bulkReleaseRequest }: ReleaseRequestDialog) {
  const [viewingCandidate, setViewingCandidate] = React.useState<Candidate>();
  const columns = [
    {
      Cell: ({ original }: { original: ReleaseRequest }) => {
        const handleClick = () => {
          setViewingCandidate(original.application.jobSeeker.candidate);
        };
        return (
          <div onClick={handleClick}>
            <span className={Classes.LINK}>{original.application.jobSeeker.fullName}</span>
          </div>
        );
      },
      Header: 'Full name',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: ReleaseRequest }) => {
        return <div>{original.application.jobSeeker.email}</div>;
      },
      Header: 'Email',
      sortable: false,
    },
  ];

  return (
    <>
      <Drawer maxWidth={900} isOpen={true} onClose={handleClose}>
        <DrawerHeader title="Release request" handleClose={handleClose} />
        <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.NONE} style={{ overflowY: 'scroll' }}>
          <ReactTable
            columns={columns}
            data={bulkReleaseRequest.releaseRequests}
            PaginationComponent={PaginationComponent}
            className="-unthemed"
            minRows={1}
          />
        </Card>
      </Drawer>
      {viewingCandidate && (
        <ShowDrawer candidate={viewingCandidate} handleClose={() => setViewingCandidate(undefined)} isOpen={true} />
      )}
    </>
  );
}
