import { IndicatorTag, Intent, Link } from '@pinpointhq/thumbtack';
import { adminProgrammeCandidatePath } from '../../../../javascript/application/ts_routes';
import tieredUniversity from '../../../shared/utilities/tieredUniversity';
import * as React from 'react';

export function columns({ data, selection, setSelection, programmeId }) {
  const [selectAll, setSelectAll] = React.useState<boolean>(false)

  const isSelected = React.useCallback(
    (row) => {
      return selection.includes(row.original)
    }, [selection]
  )

  const deselectRow = (row) => {
    const updatedSelection = selection.filter((selection) => selection !== row.original);
    setSelectAll(false);
    setSelection(updatedSelection)
  }

  const selectRow = (row) => {
    const updatedSelection = [...selection];
    updatedSelection.push(row.original);
    setSelection(updatedSelection)
    setSelectAll(false)
  }

  return [
    {
      Header: () => (
        <span className="pretty p-icon p-smooth mar-r-0">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={(e) => {
                if (selectAll) {
                  setSelection([]);
                  setSelectAll(false)
                } else {
                  setSelection(data.data)
                  setSelectAll(true)
                }
              }}
            />
            <span className="state p-primary">
              <i className="icon fa fa-check"></i>
              <label></label>
            </span>
          </span>
      ),
      // Max width is the checkbox size (16px) + 8px of padding either side. Needs to be 40 due to IE..
      maxWidth: 40,
      headerClassName: '-checkbox',
      className: '-checkbox',
      accessor: 'id',
      sortable: false,
      Cell: (row) => {
        return (
          <span className="pretty p-icon p-smooth mar-r-0">
              <input
                type="checkbox"
                onChange={(e) => {
                  if (isSelected(row)) {
                    deselectRow(row);
                  } else {
                    selectRow(row);
                  }
                }}
                checked={selectAll || isSelected(row)}
              />
              <span className="state p-primary">
                <i className="icon fa fa-check"></i>
                <label></label>
              </span>
            </span>
        );
      },
    },
    {
      Cell: (row: any) => {
        return (
          <Link target="_blank" href={adminProgrammeCandidatePath(programmeId, row.original.attributes.pinpoint_job_seeker_id)}>
            {row.original.attributes.full_name}
          </Link>
        );
      },
      Header: 'Name',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.email}</div>,
      Header: 'Email',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return (
          <>
            {row.original.attributes.university ? row.original.attributes.university : 'None specified'}
            <IndicatorTag intent={Intent.SUCCESS} className="mar-l-2" outline={true}>
              {tieredUniversity(row.original.attributes.university)}
            </IndicatorTag>
          </>
        );
      },
      Header: 'University',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return(
          <div>{row.original.attributes.application_count}</div>
        );
      },
      Header: 'Total',
      maxWidth: 100,
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return(
          <div>{row.original.attributes.rejected_applications_count}</div>
        );
      },
      Header: 'Rejected',
      maxWidth: 100,
      sortable: false
    },
    {
      Cell: (row: any) => {
        return(
          <div>{row.original.attributes.review_applications_count}</div>
        );
      },
      Header: 'Review',
      maxWidth: 100,
      sortable: false
    },
    {
      Cell: (row: any) => {
        return(
          <div>{row.original.attributes.interview_applications_count}</div>
        );
      },
      Header: 'Interview',
      maxWidth: 100,
      sortable: false
    },
    {
      Cell: (row: any) => {
        return(
          <div>{row.original.attributes.offer_applications_count}</div>
        );
      },
      Header: 'Offer',
      maxWidth: 100,
      sortable: false
    },
    {
      Cell: (row: any) => {
        return(
          <div>{row.original.attributes.hired_applications_count}</div>
        );
      },
      Header: 'Hired',
      maxWidth: 100,
      sortable: false
    }
  ]
}
