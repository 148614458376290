import { Colors } from '@pinpointhq/thumbtack';
import * as React from 'react';

interface IDrawerFooterProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isStd?: boolean;
}

export function DrawerFooter(props: IDrawerFooterProps) {
  const { className, ...rest } = props;

  const { children, ...otherRest } = rest;
  return (
    <div
      data-testid="drawer-footer"
      className={`${className || ''}`}
      style={{
        borderTop: `1px solid ${Colors.IVORY_20}`,
        boxShadow: 'none',
        margin: 0,
        padding: '24px 40px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      {...otherRest}
    >
      {/* Work around for the lack of `Classes.DRAWER_FOOTER` which adds a :before element */}
      <div
        style={{
          position: 'absolute',
          top: '-40px',
          bottom: '100%',
          width: '100%',
        }}
      />
      {children}
    </div>
  );
}
