import * as React from 'react';
import { round } from 'lodash';

import {
  Body,
  Card,
  CardLevel,
  Col,
  Empty,
  Frow,
  ICardProps,
  ISpinnerProps,
  Spinner,
  Subheading1,
} from '@pinpointhq/thumbtack';
import {
  workstreamStatsAdminInsightsJobsPath,
} from '../../../../javascript/application/ts_routes';
import classNames from 'classnames';

export default function JobStats() {
  const [data, setData] = React.useState<any>([]);
  const [errors, setErrors] = React.useState<any>();
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    await fetch(workstreamStatsAdminInsightsJobsPath(), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.errors) {
          setErrors(json.errors);
        } else {
          setErrors(null);
          setData(json);
        }
      })
      .then(() => {
        setIsLoaded(true);
      });
  }
  return (
    <Frow verticalGutters={40}>
      <Col md={{ width: '1-1' }}>
        <InsightCard
          subheading="Workstreams by Weighted Size"
          description="Here is a summary of the number of interested candidates per available job for each workstream."
          level={CardLevel.RAISED}
        >
          <Card level={CardLevel.FILLED} condensed={true}>
            <CardProgressList {...{ array: data.workstream_score, isLoading: !isLoaded }} />
          </Card>
        </InsightCard>
      </Col>
      <Col md={{ width: '1-1' }}>
        <InsightCard
          subheading="Workstreams by Jobs"
          description="Here is a breakdown of the number of jobs per workstream."
          level={CardLevel.RAISED}
        >
          <Card level={CardLevel.FILLED} condensed={true}>
            <CardProgressList {...{ array: data.workstreams_by_jobs, isLoading: !isLoaded }} />
          </Card>
        </InsightCard>
      </Col>
    </Frow>
  );
}

function CardHeader({ subheading, description }) {
  return (
    <div className="mar-b-2">
      <div className="frow frow--items-start frow--justify-between frow--nowrap frow--items-center">
        <div className="col-flex-grow-1">
          {subheading && <Subheading1>{subheading}</Subheading1>}
          {description && (
            <Body muted={true} className="mar-t-1">
              {description}
            </Body>
          )}
        </div>
      </div>
    </div>
  );
}

interface ICard extends ICardProps {
  subheading?: string | JSX.Element;
  description?: string;
  menuItems?: React.ReactNode;
}

function InsightCard({ children, description, menuItems, subheading, ...cardProps }: ICard) {
  return (
    <Card {...cardProps}>
      <CardHeader {...{ description, menuItems, subheading }} />
      {children}
    </Card>
  );
}

interface ICardProgressListProps {
  colorMap?: { [key: string]: string };
  array: { key: string; label: string; value: number; handleClick?: () => any }[];
  emptyText?: string;
  isLoading: boolean;
  suffixWithPercent?: boolean;
  handleItemClick?(key: string): any;
}

function CardProgressList(props: ICardProgressListProps) {
  const { colorMap, emptyText, handleItemClick, array, isLoading, suffixWithPercent = false } = props;

  if (isLoading) return <LoadingSpinner />;

  if (!array || Object.keys(array).length === 0) {
    return <Empty text={emptyText} />;
  }

  const maxCount = Math.max(...array.map(({ value }) => value)) || 1;
  const items = array.map((item, index) => {
    // the bar percentage is based on the largest value in the array
    const percentage = Math.floor((item.value / maxCount) * 100);
    const roundedValue = round(item.value, 1);
    const value = suffixWithPercent ? `${roundedValue}%` : roundedValue;

    return (
      <CardProgressListItem
        key={index}
        handleItemClick={handleItemClick || item.handleClick}
        itemKey={item.key}
        itemLabel={item.label}
        {...{ colorMap, value, percentage, index }}
      />
    );
  });

  return <div className="admin-card-list">{items}</div>;
}

CardProgressList.defaultProps = {
  emptyText: 'There are currently no candidates',
};

interface ICardProgressListItemProps {
  colorMap: { [key: string]: string };
  value: string | number;
  index?: number;
  itemKey: string;
  itemLabel: string;
  percentage: number;
  handleItemClick?(itemKey: string): void;
}

function CardProgressListItem(props: ICardProgressListItemProps) {
  const { colorMap, value, itemKey, itemLabel, percentage, handleItemClick } = props;

  // let color = colorMap ? colorMap[itemKey] : 'sage-500';
  let color = 'sage-500';

  if (!color) {
    color = Object.values(colorMap)[props.index];
  }

  const content = (
    <div className="frow frow--items-center">
      <div className="col-1-3">{itemLabel}</div>
      <div className="col-1-6">
        <span className="number number--bold">{value}</span>
      </div>
      <div className="col-1-2">
        <ProgressBar {...{ color, percentage }} />
      </div>
    </div>
  );

  if (handleItemClick) {
    const handleClick = () => handleItemClick(itemKey);

    return (
      <a className="admin-card-list__item" onClick={handleClick}>
        {content}
      </a>
    );
  }

  return <div className="admin-card-list__item">{content}</div>;
}

interface IProps {
  percentage: number;
  color: string;
}

function ProgressBar(props: IProps) {
  const { color, percentage } = props;
  const style = { width: `${percentage}%` };

  const className = classNames('progress-bar', { [`progress-bar--${color}`]: color });

  return (
    <div className={className} title={`${percentage}%`}>
      <div className="progress-bar__progress" style={style} />
    </div>
  );
}

interface ILoadingSpinnerProps extends ISpinnerProps {
  /**
   * The height of the loading spinner container
   */
  height?: string;
  /**
   * The minimum width of the loading spinner container
   */
  // minWidth?: string;
  /**
   * The width of the loading spinner container as the container is a block
   * @default 100%
   */
  width?: string;
}

function LoadingSpinner({ height, width, ...spinnerProps }: ILoadingSpinnerProps) {
  const style: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    height: height || '120px',
    justifyContent: 'center',
    width: width || '100%',
  };
  return (
    <div style={style}>
      <Spinner {...spinnerProps} />
    </div>
  );
}
