import * as React from 'react';
import * as $ from 'jquery';
import { startCase } from 'lodash';

import { Card, CardLevel, Heading2, Link, Super2 } from '@pinpointhq/thumbtack';
import ReactTable from 'react-table';
import PaginationComponent from './../awaiting/PaginationComponent';

import { adminProgrammeCandidatePath } from '../../../../javascript/application/ts_routes';
import Filters from '../table/Filters';

export default function Index({ exportPath, path, programmeId }) {
  const [selection, setSelection] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState();
  const [searchTerm, setSearchTerm] = React.useState<string>()

  React.useEffect(() => {
    getRecords({});
  }, []);

  const handleApplyFilters = (filters) => {
    getRecords(filters)
  }

  function getRecords(filters) {
    $.ajax({
      url: path,
      data: { filters: filters, search: searchTerm },
      type: 'GET',
      dataType: 'json',
      cache: false,
      beforeSend: () => setIsLoading(true),
      complete: () => setIsLoading(false),
      success: (data) => setData(data),
    });
  }

  const columns = [
    {
      Cell: (row: any) => {
        return (
          <Link target="_blank" href={adminProgrammeCandidatePath(programmeId, row.original.attributes.pinpoint_job_seeker_id)}>
            {row.original.attributes.full_name}
          </Link>
        );
      },
      Header: 'Name',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.email}</div>,
      Header: 'Email',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.company}</div>,
      Header: 'Company',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{startCase(row.original.attributes.status)}</div>,
      Header: 'Status',
      sortable: false,
      width: 100
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.workstream}</div>,
      Header: 'Workstream',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.sponsorship_required}</div>,
      Header: 'Sponsorship?',
      sortable: false,
      width: 80
    },
    {
      Cell: (row: any) => <div>{startCase(row.original.attributes.education_level)}</div>,
      Header: 'Education Level',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.year_of_graduation}</div>,
      Header: 'Graduation Year',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.university}</div>,
      Header: 'University',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.degree_subject}</div>,
      Header: 'Degree Subject',
      sortable: false,
    },
  ]

  return (
    <>
      <Card level={CardLevel.FILLED}>
        <div className="frow frow--items-center">
          <Super2 className={isLoading && 'bp3-skeleton'}>{data ? data.meta.total : '100'}</Super2>
          <Heading2 style={{ margin: '0 0 0 16px' }} className={data ? '' : 'bp3-skeleton'}>
            {data ? 'Candidates' : 'Loading'}
          </Heading2>
        </div>
      </Card>
      <Filters
        {...{ handleApplyFilters, selection, searchTerm, setSearchTerm, setSelection }}
        exportPath={exportPath}
        meta={data ? data.meta : null}
      />
      <div className="pad-a-5">
        <ReactTable
          columns={columns}
          data={data && data.data}
          PaginationComponent={PaginationComponent}
          className="-unthemed"
          minRows={1}
        />
      </div>
    </>
  );
}
