import * as React from 'react';
import {Body, Card, CardLevel, Classes, Link, PaddingLevel, Subheading1} from "@pinpointhq/thumbtack";
import ReactTable from "react-table";
import {useSpraypaintTableNamedParams} from "../../shared/hooks/useSpraypaintTable";
import {Company} from "../../../../javascript/models";
import NewPaginationComponent from "../../shared/NewPaginationComponent";
import {format} from "date-fns";
import {adminProgrammeCompanyPath} from "../../../../javascript/application/ts_routes";

export default function Blacklist({ programme }) {
  const { tableProps } = useSpraypaintTableNamedParams(
    {
      initialScope: Company.where({ blacklisted: true }).includes(['blacklisted_by']),
      searchIdentifier: 'companies',
    }
  )

  const columns = [
    {
      Cell: (row: any) => {
        return (
          <Link className={Classes.LINK} href={adminProgrammeCompanyPath(programme.id, row.original.id)}>
            {row.original.name}
          </Link>
        )
      },
      accessor: 'name',
      Header: 'Organisation',
      sortable: false,
    },
    {
      accessor: 'blacklistReason',
      Header: 'Reason',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return(
          <div>{row.original.blacklistedBy.fullName}</div>
        )
      },
      Header: 'Team Member',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return(
          <div>{format(row.original.blacklistedAt, 'MMMM Do YYYY')}</div>
        )
      },
      Header: 'Date',
      sortable: false,
      width: 150
    },
  ]

  const paginationProps = React.useCallback(
    () => (tableProps),
    [tableProps],
  );

  return(
    <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.REGULAR}>
      <Subheading1>Blocklist</Subheading1>
      <Body className="mar-t-1 mar-b-3" muted={true}>A overview of the blocklisted organisations</Body>
      <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
        <ReactTable
          {...tableProps}
          columns={columns}
          PaginationComponent={NewPaginationComponent}
          getPaginationProps={paginationProps}
          className="-unthemed"
          minRows={1}
        />
      </Card>
    </Card>
  )
}