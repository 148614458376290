import {
  Alerter,
  Button,
  Card,
  CardLevel,
  Drawer,
  FormGroup,
  Frow,
  ISelectOption,
  NumericInput,
  PaddingLevel,
  RadioSelect,
  Select,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { Job } from '../../../../../../../javascript/models';
import { createToast } from '../../../../../../FlashToaster';
import { DrawerHeader } from '../../../../../../shared/DrawerHeader';

interface IEditDrawer {
  job: Job;
  workstreamOptions: ISelectOption[];
  regionOptions: ISelectOption[];
  fetchData: () => void;
  handleClose: () => void;
}

export function EditDrawer({ handleClose, fetchData, job, regionOptions, workstreamOptions }: IEditDrawer) {
  const [title, setTitle] = React.useState<string>(job.title);
  const [headcount, setHeadcount] = React.useState<number>(job.headcount);
  const [selectedRegion, setSelectedRegion] = React.useState<string>(job.region);
  const [selectedWorkstream, setSelectedWorkstream] = React.useState<string>(job.workstream);
  const [removeExistingCandidates, setRemoveExistingCandidates] = React.useState<string>('false');

  const handleJobTitle = (event) => setTitle(event.target.value);
  const handleHeadcountChange = (value) => setHeadcount(value);
  const handleRegionSelect = (selectedOption: ISelectOption) => setSelectedRegion(selectedOption.value);
  const handleWorkstreamSelect = (selectedOption: ISelectOption) => setSelectedWorkstream(selectedOption.value);

  const selectedRegionOption = regionOptions.find((option) => option.value === selectedRegion);
  const selectedWorkstreamOption = workstreamOptions.find((option) => option.value === selectedWorkstream);

  const radios = [
    { value: 'false', label: 'No' },
    { value: 'true', label: 'Yes' },
  ].map((applicationOption) => {
    const handleChange = (selectedItem) => {
      if (selectedItem.currentTarget.checked) setRemoveExistingCandidates(applicationOption.value);
    };

    return (
      <div className="col-flex-grow-1" key={applicationOption.label}>
        <RadioSelect
          label={applicationOption.label}
          onChange={handleChange}
          checked={applicationOption.value === removeExistingCandidates}
        />
      </div>
    );
  });

  const key = 'job-update';

  const handleSubmit = () => {
    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      onConfirm: () => {
        createToast({ type: 'success', isLoading: true, text: 'Updating workstream', key });
        job.workstream = selectedWorkstream;
        job.region = selectedRegion;
        job.title = title;
        job.headcount = headcount;
        job.removeExistingCandidates = removeExistingCandidates === 'true';
        job.save().then((success) => {
          if (success) {
            createToast({ type: 'success', text: 'Successfully updated job', key });
            fetchData();
          } else {
            createToast({ type: 'error', text: 'Unable to update job', key });
          }
        });
      },
      text: "Are you sure you want to update this job? We won't make any changes to the existing slate",
    });
  };

  const disabled = !selectedWorkstream || !selectedRegion || !title || !headcount;

  return (
    <Drawer isOpen={true} maxWidth={600}>
      <DrawerHeader title="Edit Job" handleClose={handleClose} />
      <Card paddingLevel={PaddingLevel.EXPANDED} level={CardLevel.NONE} style={{ overflowY: 'scroll' }}>
        <FormGroup label="Title">
          <input type="text" value={title} onChange={handleJobTitle} />
        </FormGroup>
        <FormGroup label="Headcount">
          <NumericInput value={headcount} onValueChange={handleHeadcountChange} fill={true} />
        </FormGroup>
        <FormGroup label="New region">
          <Select
            onItemSelect={handleRegionSelect}
            items={regionOptions}
            fill={true}
            buttonProps={{ large: true }}
            activeItem={selectedRegionOption}
          />
        </FormGroup>
        <FormGroup label="New workstream">
          <Select
            onItemSelect={handleWorkstreamSelect}
            items={workstreamOptions}
            fill={true}
            buttonProps={{ large: true }}
            activeItem={selectedWorkstreamOption}
          />
        </FormGroup>
        <FormGroup label="Remove existing candidates" helperText="Do you want to remove the existing slate?">
          <Frow flexDirection="row" flexWrap="nowrap" justifyContent="center">
            {radios}
          </Frow>
        </FormGroup>
        <Button fill={true} text="Update" large={true} disabled={disabled} onClick={handleSubmit} />
      </Card>
    </Drawer>
  );
}
