import {Body, Card, CardLevel, Empty, PaddingLevel, Subheading1} from "@pinpointhq/thumbtack";
import * as React from "react";

export function InternshipsTakingPlace() {
  return(
    <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
      <Subheading1>Internships taking place</Subheading1>
      <Body className="mar-t-1 mar-b-3" muted={true}>Cumulative total number of internships</Body>
      <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
        <Empty text="Currently empty because we don't ask for length of internships" />
      </Card>
    </Card>
  )
}