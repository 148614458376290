import { Col, Frow, ISelectOption } from '@pinpointhq/thumbtack';
import * as React from 'react';
import AllocationRequests from '../dashboard/AllocationRequests';
import { ReleaseTable } from './recruitmentDashboard/ReleaseTable';

interface IRecruitmentDashboard {
  cycle: { id: string };
  programme: { id: string };
  workstreamOptions: ISelectOption[];
  regionOptions: ISelectOption[];
  degreeSubjectOptions: ISelectOption[];
}

export default function RecruitmentDashboard({
  cycle,
  programme,
  workstreamOptions,
  regionOptions,
  degreeSubjectOptions,
}: IRecruitmentDashboard) {
  return (
    <>
      <Frow flexDirection="column" verticalGutters={40}>
        <Col>
          <AllocationRequests
            cycleId={cycle.id}
            workstreamOptions={workstreamOptions}
            regionOptions={regionOptions}
            degreeSubjectOptions={degreeSubjectOptions}
          />
        </Col>
        <Col>
          <ReleaseTable programmeId={programme.id} cycleId={cycle.id} />
        </Col>
      </Frow>
    </>
  );
}
