import * as React from 'react';
import * as $ from 'jquery';
import { Button, Dialog, FormGroup, Input, Select } from "@pinpointhq/thumbtack";
import {
  adminCompanyCompaniesContactPath,
  adminCompanyCompaniesContactsPath,
} from "../../../../javascript/application/ts_routes";
import {getCsrfToken} from "../../../shared/csrfToken";
import {createToast} from "../../../FlashToaster";
import {ajaxErrorString} from "../../../shared/ajaxErrorString";

interface IEditContactDialog {
  companyId: string;
  editingContact: {
    id?: string;
    name: string;
    phone: string;
    email: string;
    contactType: string;
  }
  handleClose(): void;
}

export default function EditContactDialog({ companyId, editingContact, handleClose }: IEditContactDialog) {
  const [name, setName] = React.useState(editingContact.name)
  const [phone, setPhone] = React.useState(editingContact.phone)
  const [email, setEmail] = React.useState(editingContact.email)
  const [contactType, setContactType] = React.useState(editingContact.contactType)
  const [isLoading, setIsLoading] = React.useState(false)

  const handleNameChange = (event) => setName(event.currentTarget.value)
  const handlePhoneChange = (event) => setPhone(event.currentTarget.value)
  const handleEmailChange = (event) => setEmail(event.currentTarget.value)
  const handleSelect = (selectedItem) => setContactType(selectedItem.value)

  const handleSave = () => {
    if (editingContact.id) {
      handleUpdate()
    } else {
      handleCreate()
    }
  }

  const handleUpdate = () => {
    $.ajax({
      url: adminCompanyCompaniesContactPath(companyId, editingContact.id),
      data: {
        authenticity_token: getCsrfToken(),
        contact: {
          name,
          email,
          phone,
          contact_type: contactType
        },
      },
      type: 'PATCH',
      dataType: 'json',
      beforeSend: () => setIsLoading(true),
      complete: () => setIsLoading(false),
      success: (response) => {
        createToast({ type: 'success', text: 'Successfully updated contact' })
        setIsLoading(false)
        window.location.reload()
      },
      error: (jqXhr) => {
        createToast({
          text: ajaxErrorString(jqXhr),
          type: 'error',
        });
      }
    });
  }

  const handleCreate = () => {
    $.ajax({
      url: adminCompanyCompaniesContactsPath(companyId),
      data: {
        authenticity_token: getCsrfToken(),
        contact: {
          name,
          email,
          phone,
          contact_type: contactType
        },
      },
      type: 'POST',
      dataType: 'json',
      beforeSend: () => setIsLoading(true),
      complete: () => setIsLoading(false),
      success: (response) => {
        createToast({ type: 'success', text: 'Successfully created contact' })
        setIsLoading(false)
        window.location.reload()
      },
      error: (jqXhr) => {
        createToast({
          text: ajaxErrorString(jqXhr),
          type: 'error',
        });
      }
    });
  }

  const contactTypeOptions = [
    { label: 'Primary', value: 'primary' },
    { label: 'Secondary', value: 'secondary' },
  ]

  const selectedContactType = contactTypeOptions.find((option) => option.value === contactType)

  return(
    <Dialog title="Contact" isOpen={true} onClose={handleClose}>
      <>
        <FormGroup label="Name">
          <Input type="text" value={name} onChange={handleNameChange} />
        </FormGroup>
        <FormGroup label="Phone">
          <Input type="text" value={phone} onChange={handlePhoneChange} />
        </FormGroup>
        <FormGroup label="Email">
          <Input type="email" value={email} onChange={handleEmailChange} />
        </FormGroup>
        <FormGroup label="Type">
          <Select
            fill={true}
            onItemSelect={handleSelect}
            items={contactTypeOptions}
            activeItem={selectedContactType}
            buttonProps={{ large: true }}
          />
        </FormGroup>
        <Button
          text="Save"
          large={true}
          onClick={handleSave}
          disabled={!name || !phone || !email || !contactType}
          loading={isLoading}
        />
      </>
    </Dialog>
  )
}
