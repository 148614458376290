import {
  Alerter,
  Button,
  Caption,
  Card,
  CardLevel,
  Classes,
  Col,
  FormGroup,
  Frow,
  Heading2,
  IconButton40,
  IconNames16,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  MultiSelect,
  PaddingLevel,
  Popover,
  Super2,
  Switch,
  Tooltip,
  WorkflowTag,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import ReactTable from 'react-table';
import { CompaniesCycle, Company, Cycle, Programme } from '../../../javascript/models';
import { createToast } from '../../FlashToaster';
import Search from '../../shared/Search';
import useVisibility from '../../shared/hooks/useVisibility';
import humanize from '../../shared/utilities/humanize';
import NewPaginationComponent from '../shared/NewPaginationComponent';
import { useSpraypaintNamedParams } from '../shared/hooks/useSpraypaint';
import { useSpraypaintTableNamedParams } from '../shared/hooks/useSpraypaintTable';
import { NewOrganisationForm } from './NewOrganisationForm';
import { OrganisationDrawer } from './OrganisationDrawer';
import { EditForm } from './sections/EditForm';

export default function Table({ degreeSubjectOptions, workstreamOptions, regionOptions, sectorOptions }) {
  const identifier = 'company-index';
  const [selectedCompany, setSelectedCompany] = React.useState<Company | null>(null);
  const [editingCompany, setEditingCompany] = React.useState<Company | null>(null);
  const { isOpen, handleClose, handleOpen } = useVisibility(false);

  const companiesCycleScope = CompaniesCycle.selectExtra(['cycleName', 'programmeName']);
  const { tableProps, meta, isLoading, query, setQuery, fetchData } = useSpraypaintTableNamedParams({
    initialScope: Company.includes(['companiesCycles'])
      .merge({ companiesCycle: companiesCycleScope })
      .order({ id: 'desc' }),
    searchIdentifier: 'companyCycles',
    initialQuery: { cycle_id: [], programme_id: [], zero_hire: null },
  });
  const { data: cycles, isLoading: isCyclesLoading } = useSpraypaintNamedParams({ initialScope: Cycle.scope() });
  const { data: programmes, isLoading: isProgrammesLoading } = useSpraypaintNamedParams({
    initialScope: Programme.scope(),
  });
  const { isOpen: isFiltersOpen, toggleOpen: toggleFiltersOpen } = useVisibility(false);

  const handleSearch = (searchString: String) => {
    setQuery({ ...query, search: searchString });
  };

  const handleIdSearch = (searchString: String) => {
    setQuery({ ...query, id_search: searchString });
  };

  const handleCycleChange = (selectedItems) => {
    setQuery({ ...query, cycle_id: selectedItems.map((item) => item.value) });
  };
  const handleProgrammeChange = (selectedItems) => {
    setQuery({ ...query, programme_id: selectedItems.map((item) => item.value) });
  };

  const handleSwitch = (event) => {
    setQuery({ ...query, zero_hire: event.target.checked ? true : null });
  };

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  const columns = [
    // Make this an action for a drawer
    {
      Cell: ({ original }: { original: Company }) => {
        const handleClick = () => setSelectedCompany(original);
        return (
          <div>
            <span className={Classes.LINK} onClick={handleClick}>
              {original.name}
            </span>
          </div>
        );
      },
      Header: 'Name',
      accessor: 'name',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: Company }) => {
        return (
          <div>
            {original.registeredCompanyNumber}
            {original.uniqueDivisionIdentifier ? `/${original.uniqueDivisionIdentifier}` : ``}
          </div>
        );
      },
      Header: 'UID',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: Company }) => {
        return <div>{original.zeroHire ? 'Yes' : 'No'}</div>;
      },
      Header: 'Zero Hire',
      accessor: 'zeroHire',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: Company }) => {
        return <div>{humanize(original.membershipStatus)}</div>;
      },
      Header: 'Membership',
      accessor: 'membershipStatus',
      sortable: false,
      width: 160,
    },
    {
      Cell: ({ original }: { original: Company }) => {
        const cycles = original.companiesCycles
          .reverse()
          .map((companiesCycle) => `${companiesCycle.cycleName} - ${companiesCycle.programmeName}`);
        const firstCycle = cycles[0];

        return (
          <Frow gutters={8} alignItems="center">
            <div>
              <WorkflowTag className="bp3-minimal" text={firstCycle} />
            </div>
            {cycles.length > 1 && (
              <div>
                <Tooltip content={cycles.slice(1).join(', ')}>
                  <Caption> + {cycles.length - 1} more</Caption>
                </Tooltip>
              </div>
            )}
          </Frow>
        );
      },
      Header: 'Cycles',
      accessor: 'companiesCycles',
      sortable: false,
      width: 200,
    },
    {
      Cell: (row: any) => {
        const handleClick = () => setEditingCompany(row.original);

        const handleDeleteClick = () => {
          Alerter.create({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, delete',
            intent: Intent.DANGER,
            onConfirm: () => {
              row.original.destroy().then((success) => {
                if (success) {
                  createToast({ type: 'success', text: 'Company deleted' });
                  fetchData();
                } else {
                  createToast({ type: 'error', text: 'Unable to delete company' });
                }
              });
            },
            text: 'Are you sure you want to delete the company? You cannot undo this action.',
          });
        };

        return (
          <div>
            <Popover>
              <IconButton40 icon={IconNames16.OVERFLOW} />
              <Menu>
                <MenuItem text="Edit" onClick={handleClick} />
                <MenuDivider />
                <MenuItem text="Delete" intent={Intent.DANGER} onClick={handleDeleteClick} />
              </Menu>
            </Popover>
          </div>
        );
      },
      Header: '',
      sortable: false,
      width: 80,
    },
  ];

  const handleEditClose = () => setEditingCompany(null);

  const cycleOptions = cycles.map((cycle) => {
    return { label: cycle.name, value: cycle.id };
  });

  const programmeOptions = programmes.map((programme) => {
    return { label: programme.name, value: programme.id };
  });

  const selctedCycleOptions = cycleOptions.filter((cycle) => query.cycle_id.includes(cycle.value));
  const selctedProgrammeOptions = programmeOptions.filter((programme) => query.programme_id.includes(programme.value));

  return (
    <>
      <Card level={CardLevel.FILLED}>
        <Frow justifyContent="space-between" alignItems="center">
          <div>
            <Frow alignItems="center">
              <div>
                <Super2 className={isLoading && 'bp3-skeleton'}>{meta ? meta.stats.total.count : '100'}</Super2>
              </div>
              <div>
                {' '}
                <Heading2 style={{ margin: '0 0 0 16px' }} className={!isLoading ? '' : 'bp3-skeleton'}>
                  Organisations
                </Heading2>
              </div>
            </Frow>
          </div>
          <div>
            <Button text="Add new organisation" onClick={handleOpen} />
          </div>
        </Frow>
        <div className="frow frow--items-center"></div>
      </Card>
      <div className="pad-a-5">
        <Frow gutters={16} verticalGutters={16} className="mar-b-1">
          <div className="bp3-col--flex-grow-1">
            <Search target={identifier} handleSearch={handleSearch} searchPlaceholderText="Search name" />
          </div>
          <div className="bp3-col--flex-grow-1">
            <Search target={identifier} handleSearch={handleIdSearch} searchPlaceholderText="Search ID" />
          </div>
          <div>
            <Button icon={IconNames16.FILTER} minimal={true} text="Filters" onClick={toggleFiltersOpen} large={true} />
          </div>
        </Frow>
        {isFiltersOpen && (
          <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
            <Frow gutters={16}>
              <Col md={{ width: '1-3' }}>
                <FormGroup label="Cycle">
                  <MultiSelect
                    fill={true}
                    items={cycleOptions}
                    loading={isCyclesLoading}
                    handleChange={handleCycleChange}
                    selectedItems={selctedCycleOptions}
                  />
                </FormGroup>
              </Col>
              <Col md={{ width: '1-3' }}>
                <FormGroup label="Programme">
                  <MultiSelect
                    fill={true}
                    items={programmeOptions}
                    loading={isProgrammesLoading}
                    handleChange={handleProgrammeChange}
                    selectedItems={selctedProgrammeOptions}
                  />
                </FormGroup>
              </Col>
              <Col md={{ width: '1-3' }}>
                <FormGroup label="Zero hires">
                  <Switch
                    large={true}
                    innerLabel="Included"
                    innerLabelChecked="Zero Hires Only"
                    onChange={handleSwitch}
                  />
                </FormGroup>
              </Col>
            </Frow>
          </Card>
        )}
        <ReactTable
          {...tableProps}
          columns={columns}
          PaginationComponent={NewPaginationComponent}
          getPaginationProps={paginationProps}
          className="-unthemed"
          minRows={1}
        />
      </div>
      {selectedCompany && programmes.length > 0 && (
        <OrganisationDrawer
          company={selectedCompany}
          handleClose={() => setSelectedCompany(null)}
          cycles={cycles}
          workstreamOptions={workstreamOptions}
          regionOptions={regionOptions}
          degreeSubjectOptions={degreeSubjectOptions}
        />
      )}
      {editingCompany && (
        <EditForm
          fetchData={fetchData}
          company={editingCompany}
          handleClose={handleEditClose}
          companySectorOptions={sectorOptions}
        />
      )}
      {isOpen && (
        <NewOrganisationForm
          handleClose={handleClose}
          workstreamOptions={workstreamOptions}
          fetchData={fetchData}
          regionOptions={regionOptions}
          sectorOptions={sectorOptions}
        />
      )}
    </>
  );
}
