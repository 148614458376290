import * as React from 'react';
import {
  Alerter,
  Empty,
  Heading2,
  IconButton40,
  IconNames16,
  Intent, ISelectOption,
  Menu,
  MenuDivider,
  MenuItem,
  Popover
} from "@pinpointhq/thumbtack";
import humanize from "../../../../../shared/utilities/humanize";
import ReactTable from "react-table";
import {CompaniesCycle, Company, Job} from "../../../../../../javascript/models";
import {AllocateDialog} from "../../../../shared/AllocateDialog";
import {CenteredContent} from "../../../../../shared/CenteredContent";
import {createToast} from "../../../../../FlashToaster";
import {ChangeWorkstreamDialog} from "./jobs/ChangeWorkstreamDialog";

export function Jobs({ activeCycle, company, fetchData, workstreamOptions }: { activeCycle: CompaniesCycle, company: Company, fetchData(): void, workstreamOptions: ISelectOption[] }) {
  const [selectedJobId, setSelectedJobId] = React.useState<string>()
  const [selectedWorkstreamChangeJob, setSelectedWorkstreamChangeJob] = React.useState<Job>()

  const jobs = company.jobs.filter((job) => activeCycle && (job.cycleId === activeCycle.cycleId && job.programmeId === activeCycle.programmeId))

  const internships = activeCycle && activeCycle.companySubmission ?
    activeCycle.companySubmission.savedInternships :
    []

  const handleClose = () => setSelectedJobId(null)

  const key = 'job-update'

  const columns = [
    {
      accessor: 'title',
      Header: 'Title',
    },
    {
      accessor: 'workstream',
      Header: 'Workstream',
    },
    {
      Cell: (row) => {
        return <div>{humanize(row.original.progress)}</div>
      },
      accessor: 'progress',
      Header: 'Progress',
      maxWidth: 160,
    },
    {
      accessor: 'region',
      Header: 'Region',
      maxWidth: 160,
    },
    {
      accessor: 'headcount',
      Header: 'Headcount',
      maxWidth: 120,
    },
    {
      accessor: 'applicationsCount',
      Header: 'Applications',
      maxWidth: 140,
    },
    {
      Cell: ({ original }) => {
        const handleAllocate = () => {
          setSelectedJobId(original.id)
        }

        const handleDelete = () => {
          Alerter.create({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, delete',
            intent: Intent.NONE,
            onConfirm: () => {
              createToast({ type: 'success', key, isLoading: true, text: 'Deleting job' })
              original.destroy().then((success) => {
                if (success) {
                  createToast({ type: 'success', key, text: 'Successfully deleted job' })
                  fetchData();
                } else {
                  createToast({ type: 'error', key, text: 'Unable to delete job' })
                }
              })
            },
            text: 'Are you sure you want to delete this internship? Any candidates allocated will be prioritised for future allocations. This action cannot be undone',
          });
        }

        const handleChangeWorkstream = () => {
          setSelectedWorkstreamChangeJob(original)
        }

        const closeKey = 'job-close'

        const handleCloseJob = () => {
          Alerter.create({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, close',
            intent: Intent.NONE,
            onConfirm: () => {
              createToast({ type: 'success', key: closeKey, isLoading: true, text: 'Closing job' })
              original.closeJob = true
              original.save().then((success) => {
                if (success) {
                  createToast({ type: 'success', key: closeKey, text: 'Successfully closed job' })
                  fetchData();
                } else {
                  createToast({ type: 'error', key: closeKey, text: 'Unable to close job' })
                }
              })
            },
            text: "Only close a job if the organisation has told you they won't be fulfilling their pledge for this role. We will close the job on the companies side and unsuccessful candidates will be prioritised for future allocations"
          })
        }

        const handleWithdrawJob = () => {
          Alerter.create({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, withdraw',
            intent: Intent.NONE,
            onConfirm: () => {
              createToast({ type: 'success', key: closeKey, isLoading: true, text: 'Withdrawing job' })
              original.withdrawJob = true
              original.save().then((success) => {
                if (success) {
                  createToast({ type: 'success', key: closeKey, text: 'Successfully withdrawing job' })
                  fetchData();
                } else {
                  createToast({ type: 'error', key: closeKey, text: 'Unable to withdraw job' })
                }
              })
            },
            text: "Only withdraw a job if the organisation has told you they won't be fulfilling their pledge for this role. We will close the job on the companies side and unsuccessful candidates will be prioritised for future allocations"
          })
        }

        return(
          <Popover>
            <IconButton40 icon={IconNames16.OVERFLOW} aria-label="Job menu" />
            <Menu>
              <MenuItem text="Allocate" onClick={handleAllocate} />
              <MenuItem text="Change Workstream" onClick={handleChangeWorkstream} />
              <MenuItem text="Close" onClick={handleCloseJob} />
              <MenuItem text="Withdraw" onClick={handleWithdrawJob} />
              <MenuDivider />
              <MenuItem text="Delete" onClick={handleDelete} intent={Intent.DANGER} />
            </Menu>
          </Popover>
        )
      },
      Header: '',
      accessor: 'overflow',
      className: 'text-right',
      maxWidth: 80,
      sortable: false,
    }
  ]

  const internshipColumns = [
    {
      Cell: ({ original }) => {
        return <div>{original.region} - {original.workstream}</div>
      },
      accessor: 'internship',
      Header: 'Internship',
    },
    {
      accessor: 'length',
      Header: 'Length of internship',
    },
    {
      accessor: 'pay',
      Header: 'Internship pay',
    },
    {
      accessor: 'number',
      Header: 'Pledge',
      maxWidth: 100,
    },
    {
      Cell: ({ original }) => {
        return(
          <div></div>
          // <Popover>
          //   <IconButton40 icon={IconNames16.OVERFLOW} aria-label="Job menu" />
          //   <Menu>
          //     <MenuItem text="Allocate" />
          //   </Menu>
          // </Popover>
        )
      },
      Header: '',
      accessor: 'overflow',
      className: 'text-right',
      maxWidth: 80,
      sortable: false,
    }
  ]

  const handleWorkstreamClose = () => setSelectedWorkstreamChangeJob(null)

  return(
    <CenteredContent maxWidth={1200}>
      <Heading2 className="mar-b-3">Jobs</Heading2>
      <div className="mar-b-5">
        {jobs.length > 0 ? (
          <ReactTable
            columns={columns}
            data={jobs}
            showPagination={false}
            className="-unthemed"
            minRows={1}
          />) : (
          <Empty text="No jobs exist for this company for the selected cycle" />
        )}
      </div>
      <Heading2 className="mar-b-3">Internships</Heading2>
      {jobs.length > 0 ? (
        <ReactTable
          columns={internshipColumns}
          data={internships}
          showPagination={false}
          className="-unthemed"
          minRows={1}
        />) : (
        <Empty text="No internships pledge by this company for the selected cycle" />
      )}
      <AllocateDialog
        isOpen={!!selectedJobId}
        jobId={selectedJobId}
        handleClose={handleClose}
        handleSuccess={fetchData}
      />
      <ChangeWorkstreamDialog
        fetchData={fetchData}
        isOpen={!!selectedWorkstreamChangeJob}
        job={selectedWorkstreamChangeJob}
        handleClose={handleWorkstreamClose}
        workstreamOptions={workstreamOptions}
      />
    </CenteredContent>
  )
}