// Stolen from this (almost) excellent package https://github.com/danott/react-rails-form-helpers
// Need to take the code as the actual package is broken

import * as React from "react";

interface IProps extends Partial<HTMLFormElement> {
  url?: string;
  method?: string;
  csrfToken?: string;
  children: any;
}

export class FormTag extends React.Component<IProps> {
  public static defaultProps = {
    method: "post",
  };

  // The if statement is for if we are in test mode (no meta tag or forgery protection in test mode)
  public static csrfToken() {
    const metaTag = document.querySelector('head meta[name="csrf-token"]');
    if (!!metaTag) {
      return (metaTag as any).content;
    }
    return "";
  }

  constructor(props: IProps) {
    super(props);
  }

  public whitelistProps(props, ...omit) {
    const alwaysOmit = ["key", "ref", ...omit];
    const cloned = { ...props };
    alwaysOmit.forEach((key) => delete cloned[key]);
    return cloned;
  }

  public render() {
    let browserHTTPMethod = "post";
    let fakedHTTPMethod = null;

    if (this.props.method === "get") {
      browserHTTPMethod = "get";
    } else if (this.props.method !== "post") {
      fakedHTTPMethod = this.props.method;
    }

    const csrfToken = this.props.csrfToken || FormTag.csrfToken();

    return (
      <form
        {...this.whitelistProps(this.props, "url", "children", "csrfToken")}
        acceptCharset="UTF-8"
        action={this.props.url}
        method={browserHTTPMethod}
      >
        {fakedHTTPMethod && (
          <input type="hidden" name="_method" value={fakedHTTPMethod} />
        )}
        {csrfToken && (
          <input type="hidden" name="authenticity_token" value={csrfToken} />
        )}
        <input type="hidden" name="utf8" value="&#x2713;" />
        {this.props.children}
      </form>
    );
  }
}

// The if statement is for if we are in test mode (no meta tag or forgery protection in test mode)
export function csrfToken() {
  const metaTag = document.querySelector('head meta[name="csrf-token"]');
  if (!!metaTag) {
    return (metaTag as any).content;
  }
  return "";
}
