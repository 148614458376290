import * as React from "react";

import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuTrigger,
  Popover,
  UserAvatar,
} from "@pinpointhq/thumbtack";

interface IProfileNavProps {
  logoutUrl: string;
  items: { text: string; href: string }[];
  user: { firstName: string; lastName: string; email: string };
}

export default function ProfileNav({
  logoutUrl,
  items,
  user,
}: IProfileNavProps) {
  const menuItemsFromProps = items.map(({ href, text }) => (
    <MenuItem key={text} {...{ href, text }} />
  ));
  const menuDivider = <MenuDivider key="divider" />;
  const logoutMenuItem = (
    <MenuItem
      key="logout"
      href={logoutUrl}
      data-method="delete"
      text="Logout"
      shouldDismissPopover={true}
    />
  );
  const menuItems = [...menuItemsFromProps];
  if (menuItems.length > 0) menuItems.push(menuDivider);
  menuItems.push(logoutMenuItem);

  return (
    <>
      <Popover position="bottom">
        <MenuTrigger aria-label="Profile Navigation Trigger">
          <UserAvatar user={user} large={true} />
        </MenuTrigger>
        <Menu>{menuItems}</Menu>
      </Popover>
    </>
  );
}
