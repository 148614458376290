import * as React from 'react';
import {Heading2} from "@pinpointhq/thumbtack";
import {adminCompanyCompaniesCommentsPath} from "../../../../../../javascript/application/ts_routes";
import {Company} from "../../../../../../javascript/models";
import Comments from "../../../../shared/Comments";
import {CenteredContent} from "../../../../../shared/CenteredContent";

export function CompanyComments({ company, userId }: { company: Company, userId: string }) {
  return(
    <CenteredContent maxWidth={1200}>
      <Heading2 className="mar-b-3">Comments</Heading2>
      <Comments
        indexPath={adminCompanyCompaniesCommentsPath(company.id)}
        userId={userId}
      />
    </CenteredContent>
  )
}
