import * as React from 'react';
import {Alerter, Body, Button, Dialog, FormGroup, Intent, Select} from "@pinpointhq/thumbtack";
import * as $ from 'jquery';
import { visit } from 'turbolinks';
import {
  adminProgrammeCompanyPath, mergeAdminCompanyPath,
} from "../../../javascript/application/ts_routes";
import {getCsrfToken} from "../../shared/csrfToken";
import {useSpraypaint} from "../shared/hooks/useSpraypaint";
import {Company} from "../../../javascript/models";

export default function MergeCompanies({ company, handleClose, currentProgrammeId }) {
  const [selectedCompany, setSelectedCompany] = React.useState(null)

  const { data: companies, isLoading } = useSpraypaint(Company.per(10000))


  const companyOptions = companies.filter((optionCompany) => optionCompany.id !== company.id).map((company) => {
    return { value: company.id, label: `${company.name} (${company.id})` }
  }).filter((companyOption) => companyOption.value !== company.id)

  const handleItemSelect = (selectedItem) => {
    setSelectedCompany(selectedItem)
  }

  const handleMergeClick = () => {
    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      intent: Intent.DANGER,
      onConfirm: () => {
        confirmMerge()
      },
      text: 'Are you sure you want to merge this company? All submissions, jobs and contacts will be moved to the selected company and this company will be removed',
    });
  }

  const confirmMerge = () => {
    $.ajax({
      url: mergeAdminCompanyPath(company.id, { merge_id: selectedCompany.value }),
      data: { authenticity_token: getCsrfToken() },
      type: 'POST',
      dataType: 'json',
      success: () => {
        visit(adminProgrammeCompanyPath(currentProgrammeId, selectedCompany.value))
      },
    });
  }

  return(
    <Dialog title="Merge" isOpen={true} onClose={handleClose}>
      <Body className="mar-b-3">Merge this company into another. This company will be removed and all records will be moved over to the selected company</Body>
      <FormGroup label="Company">
        <Select
          buttonProps={{ large: true }}
          fill={true}
          onItemSelect={handleItemSelect}
          activeItem={selectedCompany}
          items={companyOptions}
          loading={isLoading}
        />
      </FormGroup>
      <Button onClick={handleMergeClick} text="Merge" large={true} fill={true} disabled={!selectedCompany} />
    </Dialog>
  )
}