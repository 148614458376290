import * as React from 'react';
import {Card, CardLevel, Classes, Colors, Drawer, PaddingLevel, Tab, Tabs} from "@pinpointhq/thumbtack";
import {DrawerHeader} from "../../../../shared/DrawerHeader";
import NewPaginationComponent from "../../../shared/NewPaginationComponent";
import ReactTable from "react-table";
import {useSpraypaintTableNamedParams} from "../../../shared/hooks/useSpraypaintTable";
import {JobSeeker} from "../../../../../javascript/models";
import humanize from "../../../../shared/utilities/humanize";

export function EligibilityTable({ cycleId, programmeId, selectedTab, setEligibility }) {

  const handleClose = () => setEligibility(null)

  const columns = [
    {
      Header: 'Name',
      accessor: 'fullName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Degree',
      accessor: 'degreeSubject',
      sortable: false,
    },
    {
      Header: 'University',
      accessor: 'university',
    },
    {
      Cell: (row: any) => {
        return (
          <div>{row.original.furthestStageReached ? humanize(row.original.furthestStageReached) : 'n/a'}</div>
        )
      },
      Header: 'Furthest stage',
      accessor: 'furthestStageReached',
      sortable: false
    }
  ];

  const { tableProps, isLoading, setQuery, query } = useSpraypaintTableNamedParams({
    initialScope: JobSeeker.where({ cycle_id: cycleId, programme_id: programmeId }).selectExtra(['furthest_stage_reached']),
    searchIdentifier: 'job-seeker-eligibility',
    initialQuery: { eligible: selectedTab === 'eligible' }
  })

  const paginationProps = React.useCallback(
    () => (tableProps),
    [tableProps],
  );

  const handleTabChange = (tabId) => {
    setQuery({ eligible: tabId === 'eligible' })
  }

  return(
    <Drawer isOpen={true} onClose={handleClose} maxWidth={1000}>
      <DrawerHeader title="Eligibility" handleClose={handleClose} />
      <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.NONE} style={{ borderTop: '1px solid #E8DFCA' }}>
        <Tabs id="job-seeker-eligibility" selectedTabId={selectedTab} muted={true} onChange={handleTabChange}>
          <Tab id="eligible">Eligible</Tab>
          <Tab id="ineligible">Ineligible</Tab>
          <div className={Classes.FLEX_EXPANDER} style={{ backgroundColor: Colors.IVORY_05 }} />
        </Tabs>
      </Card>
      <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.NONE}>
        <ReactTable
          {...tableProps}
          loading={isLoading}
          columns={columns}
          PaginationComponent={NewPaginationComponent}
          getPaginationProps={paginationProps}
          className="-unthemed"
          minRows={1}
        />
      </Card>
    </Drawer>
  )
}