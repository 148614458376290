import {
  AnchorButton,
  Button,
  Card,
  CardLevel,
  Col,
  FormGroup,
  Frow,
  IconNames16,
  ISelectOption,
  PaddingLevel,
  Select,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { useState } from 'react';
import { visit } from 'turbolinks';
import { exportAdminCompaniesCyclesPath, exportAdminCompaniesPath, newAdminCompanyPath } from '../../../../javascript/application/ts_routes';
import useVisibility from '../../../shared/hooks/useVisibility';
import Search from '../../../shared/Search';

interface IFilters {
  pledge_status?: string;
}

export default function Filters({ cycleId, columnOption, setColumnOption, cycleOptions, handleApplyFilters, searchTerm, setSearchTerm, setCycleId, programmeId }) {
  const { isOpen, toggleOpen } = useVisibility(false)
  const [filters, setFilters] = useState<IFilters>({ pledge_status: null })
  const [sort, setSort] = useState<string>('')
  const identifier = 'company-index'

  React.useEffect(() => {
    document.addEventListener(`${identifier}:search`, handleSearch);
    return () => {
      document.removeEventListener(`${identifier}:search`, handleSearch);
    };
  }, [searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.detail.searchTerm);
  }

  const handleApply = () => {
    handleApplyFilters(filters, sort)
  }

  const handleExport = () => {
    if (columnOption === 'submissionInfo') {
      visit(exportAdminCompaniesCyclesPath({ filters: { ...filters, cycle_id: cycleId }, sort: sort, programme_id: programmeId }))
    } else {
      visit(exportAdminCompaniesPath({ filters: { ...filters, cycle_id: cycleId }, sort: sort, programme_id: programmeId }))
    }
  }

  const pledgeStatusOptions = [
    { label: 'Pledge not met', value: 'pledge_not_met' },
    { label: 'Pledge met', value: 'pledge_met' },
    { label: 'Pledge exceeded', value: 'pledge_exceeded' },
  ]

  const sortableColumnOptions = [
    { label: 'Offers ascending', value: 'offers_asc' },
    { label: 'Offers descending', value: 'offers_desc' },
    { label: 'Hires ascending', value: 'hires_asc' },
    { label: 'Hires descending', value: 'hires_desc' },
    { label: 'Pledges ascending', value: 'pledges_asc' },
    { label: 'Pledges descending', value: 'pledges_desc' },
  ]

  const columnOptions = [
    { label: 'Cycle information', value: 'cycleInfo' },
    { label: 'Submission Information', value: 'submissionInfo' }
  ]

  const handleColumnOptionChange = (selectedItem) => setColumnOption(selectedItem.value)

  const selectedColumnOption = columnOptions.find((option) => option.value === columnOption)

  const handlePledgeStatusChange = (selectedItem) => {
    setFilters({ ...filters, pledge_status: selectedItem ? selectedItem.value : '' })
  }

  const handleSortChange = (selectedItem) => {
    setSort(selectedItem ? selectedItem.value : '')
  }

  const handleCycleChange = (selectedItem: ISelectOption) => {
    setCycleId(selectedItem.value)
  }

  const activeSort = sortableColumnOptions.find((item) => item.value === sort)
  const selectedPledgeStatus = pledgeStatusOptions.find((option) => option.value === filters.pledge_status)
  const activeCycle = cycleOptions.find((option) => option.value === cycleId)

  return(
    <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.EXPANDED}>
      <Frow gutters={16} verticalGutters={16}>
        <Col width="1-1">
          <Frow gutters={16} verticalGutters={16} alignItems="flex-end">
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Cycle" style={{ marginBottom: 0 }}>
                <Select
                  activeItem={activeCycle}
                  items={cycleOptions}
                  fill={true}
                  buttonProps={{ large: true }}
                  onItemSelect={handleCycleChange}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Columns" style={{ marginBottom: 0 }}>
                <Select
                  activeItem={selectedColumnOption}
                  items={columnOptions}
                  fill={true}
                  buttonProps={{ large: true }}
                  onItemSelect={handleColumnOptionChange}
                />
              </FormGroup>
            </Col>
            {/*<Col md={{ width: '1-3' }}>*/}
            {/*  <div className="text-right">*/}
            {/*    <AnchorButton icon={IconNames16.ADD} minimal={true} text="Add new organisation" large={true} href={newAdminCompanyPath()} />*/}
            {/*  </div>*/}
            {/*</Col>*/}
          </Frow>
        </Col>
        <div className="bp3-col--flex-grow-1">
          <Search target={identifier} />
        </div>
        <div>
          {/*<Button minimal={true} text="Filters & Sorts" large={true} onClick={toggleOpen} />*/}
        </div>
        <div>
          <Button minimal={true} text="Export" large={true} onClick={handleExport} />
        </div>
      </Frow>
      {isOpen && (
        <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR} className="mar-t-2">
          <Frow gutters={16} verticalGutters={16} alignContent="flex-end" alignItems="flex-end">
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Pledge Status" style={{ marginBottom: 0 }}>
                <Select
                  activeItem={selectedPledgeStatus}
                  items={pledgeStatusOptions}
                  fill={true}
                  buttonProps={{ large: true }}
                  onItemSelect={handlePledgeStatusChange}
                  clearable={true}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <FormGroup label="Sort by" style={{ marginBottom: 0 }}>
                <Select
                  activeItem={activeSort}
                  fill={true}
                  buttonProps={{ large: true }}
                  items={sortableColumnOptions}
                  onItemSelect={handleSortChange}
                  clearable={true}
                />
              </FormGroup>
            </Col>
            <Col md={{ width: '1-3' }}>
              <Button large={true} fill={true} text="Apply" onClick={handleApply} />
            </Col>
          </Frow>
        </Card>
      )}
    </Card>
  )
}

