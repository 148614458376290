import * as React from 'react';
import {
  Body,
  Card,
  CardLevel,
  Classes,
  Col, Colors,
  Frow,
  Icon8,
  IconNames8,
  PaddingLevel,
  Subheading1
} from "@pinpointhq/thumbtack";
import {useSpraypaintNamedParams} from "../../shared/hooks/useSpraypaint";
import {Workstream} from "../../../../javascript/models";
import LoadingSpinner from "../../../shared/LoadingSpinner";

export default function SectorBreakdown({ cycleId, programmeId }) {
  const workstreamScope = Workstream.per(0).extraParams({ cycle_id: cycleId, programme_id: programmeId }).stats({ averages: ['applications_per_workstream', 'applications_per_workstream_previous'] })
  const { meta, isInitialLoading } = useSpraypaintNamedParams({ initialScope: workstreamScope })

  return(
    <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
      <Subheading1>Sector Overview</Subheading1>
      <Body muted={true} className="mar-t-1 mar-b-3">
        An overview of average number of applicants per internship for each sector
      </Body>
      <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR} style={{ maxHeight: '400px', overflowY: 'scroll' }}>
        <ul className={Classes.LIST_TABULAR}>
          <li>
            <Frow style={{ width: '100%' }}>
              <Col width="1-5">
                <Body semiBold={true}>Sector</Body>
              </Col>
              <Col width="1-5">
                <Body semiBold={true}>Current average</Body>
              </Col>
              <Col width="1-5">
                <Body semiBold={true}>Current internship count</Body>
              </Col>
              <Col width="1-5">
                <Body semiBold={true}>Previous average</Body>
              </Col>
              <Col width="1-5">
                <Body semiBold={true}>Previous internship count</Body>
              </Col>
            </Frow>
          </li>
          {isInitialLoading ? <LoadingSpinner /> : meta.stats.averages.applications_per_workstream.map((item) => {
            const previous = meta.stats.averages.applications_per_workstream_previous.find((previousItem) => previousItem.label === item.label)
            let color = Colors.RED_50;
            if (item.value < 30) {
              color = Colors.YELLOW_60
            }
            if (item.value < 20) {
              color = Colors.GREEN_50
            }
            if (item.value < 6) {
              color = Colors.RED_50;
            }

            let previousColor = Colors.RED_50
            if (previous.value < 30) {
              previousColor = Colors.YELLOW_60
            }
            if (previous.value < 20) {
              previousColor = Colors.GREEN_50
            }
            if (previous.value < 6) {
              previousColor = Colors.RED_50;
            }

            return(
              <li key={item.label} style={{ padding: '12px 0' }}>
                <Frow style={{ width: '100%' }}>
                  <Col width="1-5">
                    <Body>{item.label}</Body>
                  </Col>
                  <Col width="1-5">
                    <Frow gutters={8} flexWrap="nowrap">
                      <div>
                        <Icon8 icon={IconNames8.BULLET_LARGE} style={{ fill: color }} />
                      </div>
                      <div>
                        <Body>{item.value}</Body>
                      </div>
                    </Frow>
                  </Col>
                  <Col width="1-5">
                    <Frow gutters={8} flexWrap="nowrap">
                      <div>
                        <Body>{item.internship_count}</Body>
                      </div>
                    </Frow>
                  </Col>
                  <Col width="1-5">
                    <Frow gutters={8} flexWrap="nowrap">
                      <div>
                        <Icon8 icon={IconNames8.BULLET_LARGE} style={{ fill: previousColor }} />
                      </div>
                      <div>
                        <Body>{previous.value}</Body>
                      </div>
                    </Frow>
                  </Col>
                  <Col width="1-5">
                    <Frow gutters={8} flexWrap="nowrap">
                      <div>
                        <Body>{previous.internship_count}</Body>
                      </div>
                    </Frow>
                  </Col>
                </Frow>
              </li>
            )
          })}
        </ul>
      </Card>
    </Card>
  )
}