import { Checkbox } from '@pinpointhq/thumbtack';
import * as React from 'react';

interface IHeaderCheckboxProps<T> {
  data: T[];
  selection: T[];
  setSelection(selection: T[]): void;
}

export function HeaderCheckbox<T = unknown>({ data, selection, setSelection }: IHeaderCheckboxProps<T>) {
  const isChecked = data === selection;
  function handleChange() {
    if (isChecked) {
      setSelection([]);
    } else {
      setSelection(data);
    }
  }
  return <Checkbox onChange={handleChange} checked={isChecked} />;
}
