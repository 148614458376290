import * as React from 'react';
import * as $ from 'jquery';
import * as pluralize from 'pluralize';

import { Card, CardLevel, Heading2, IndicatorTag, Intent, Link, Super2 } from '@pinpointhq/thumbtack';
import ReactTable, { Column } from 'react-table';
import PaginationComponent from './PaginationComponent';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import tieredUniversity from '../../../shared/utilities/tieredUniversity';

import { adminProgrammeCandidatePath } from '../../../../javascript/application/ts_routes';

export default function Index({ path, programmeId }: { path: string, programmeId: string }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState();

  React.useEffect(() => {
    getRecords();
  }, []);

  function getRecords() {
    $.ajax({
      url: path,
      type: 'GET',
      dataType: 'json',
      cache: false,
      beforeSend: () => setIsLoading(true),
      complete: () => setIsLoading(false),
      success: (data) => setData(data),
    });
  }

  if (isLoading) return <LoadingSpinner />;

  const columns: Column[] = [
    {
      Cell: (row: any) => {
        return (
          <Link target="_blank" href={adminProgrammeCandidatePath(programmeId, row.original.attributes.pinpoint_job_seeker_id)}>
            {row.original.attributes.full_name}
          </Link>
        );
      },
      Header: 'Name',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.attributes.email}</div>,
      Header: 'Email',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return (
          <>
            {row.original.attributes.university}
            <IndicatorTag intent={Intent.SUCCESS} className="mar-l-2" outline={true} text={tieredUniversity(row.original.attributes.university)} />
          </>
        );
      },
      Header: 'University',
      sortable: false,
    },
  ];

  return (
    <>
      <Card level={CardLevel.FILLED}>
        <div className="frow frow--items-center">
          <Super2 className={isLoading && 'bp3-skeleton'}>{data ? data.meta.total : '100'}</Super2>
          <Heading2 style={{ margin: '0 0 0 16px' }}>
            {data
              ? `${pluralize('Candidate', data.meta.total, false)} awaiting allocation`
              : pluralize('Candidate awaiting allocation')}
          </Heading2>
        </div>
      </Card>
      <div className="pad-a-5">
        <ReactTable
          columns={columns}
          data={data && data.data}
          PaginationComponent={PaginationComponent}
          className="-unthemed"
        />
      </div>
    </>
  );
}
