import {
  Button,
  Checkbox,
  Col,
  Dialog,
  FormGroup,
  Frow,
  Input,
  NumericInput,
  RadioSelect,
  Select,
  TextArea,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { createToast } from '../../../../../../FlashToaster';

export function NewJobDialog({ fetchData, handleClose, isOpen, job, regionOptions, workstreamOptions }) {
  if (!job) return null;

  const [title, setTitle] = React.useState(job.title);
  const [workstream, setWorkstream] = React.useState(job.workstream);
  const [region, setRegion] = React.useState(job.region);
  const [headcount, setHeadcount] = React.useState(job.headcount);
  const [sponsorship, setSponsorship] = React.useState(job.sponsorship);
  const [longitude, setLongitude] = React.useState(job.longitude);
  const [latitude, setLatitude] = React.useState(job.latitude);
  const [postcode, setPostcode] = React.useState(job.postcode);
  const [pay, setPay] = React.useState(job.pay);
  const [remote, setRemote] = React.useState(job.remote);
  const [description, setDescription] = React.useState(job.description);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleTitleChange = (event) => setTitle(event.target.value);
  const handleWorkstreamChange = (selectedItem) => setWorkstream(selectedItem.value);
  const handleRegionChange = (selectedItem) => setRegion(selectedItem.value);
  const handleHeadcountChange = (value) => setHeadcount(value);
  const handleLongitudeChange = (event) => setLongitude(event.target.value);
  const handleLatitudeChange = (event) => setLatitude(event.target.value);
  const handlePostcodeChange = (event) => setPostcode(event.target.value);
  const handlePayChange = (event) => setPay(event.target.value);
  const handleDescriptionChange = (event) => setDescription(event.target.value);

  const selectedWorksteamOption = workstreamOptions.find((option) => option.value === workstream);
  const selectedRegionOption = regionOptions.find((option) => option.value === region);

  const radios = [
    { value: false, label: 'No' },
    { value: true, label: 'Yes' },
  ].map((option) => {
    const handleChange = (selectedItem) => {
      if (selectedItem.currentTarget.checked) setSponsorship(option.value);
    };

    return (
      <div className="col-flex-grow-1" key={option.label}>
        <RadioSelect label={option.label} onChange={handleChange} checked={option.value === sponsorship} />
      </div>
    );
  });

  const handleCreate = () => {
    setIsSubmitting(true);
    createToast({ type: 'success', text: 'Creating job' });
    job.title = title;
    job.workstream = workstream;
    job.region = region;
    job.headcount = headcount;
    job.sponsorship = sponsorship;
    job.long = longitude;
    job.lat = latitude;
    job.postcode = postcode;
    job.pay = pay;
    job.remote = remote;
    job.description = description;
    job.save().then((success) => {
      if (success) {
        if (success) {
          setIsSubmitting(false);
          createToast({ type: 'success', text: 'Successfully created job' });
          handleClose();
          fetchData();
        } else {
          setIsSubmitting(false);
          createToast({ type: 'success', text: 'Unable to create job' });
        }
      }
    });
  };

  return (
    <Dialog title="Create job" isOpen={isOpen} onClose={handleClose}>
      <FormGroup label="Internship Title">
        <Input value={title} onChange={handleTitleChange} />
      </FormGroup>
      <FormGroup label="Description">
        <TextArea value={description} onChange={handleDescriptionChange} />
      </FormGroup>
      <FormGroup label="Workstream">
        <Select
          items={workstreamOptions}
          onItemSelect={handleWorkstreamChange}
          fill={true}
          buttonProps={{ large: true }}
          activeItem={selectedWorksteamOption}
        />
      </FormGroup>
      <FormGroup label="Region">
        <Select
          items={regionOptions}
          onItemSelect={handleRegionChange}
          fill={true}
          buttonProps={{ large: true }}
          activeItem={selectedRegionOption}
        />
      </FormGroup>
      <FormGroup label="Headcount">
        <NumericInput value={headcount} onValueChange={handleHeadcountChange} />
      </FormGroup>
      <FormGroup label="Sponsorship">
        <Frow flexDirection="row" flexWrap="nowrap" justifyContent="center">
          {radios}
        </Frow>
      </FormGroup>
      <Frow gutters={8}>
        <Col sm={{ width: '1-2' }}>
          <FormGroup label="Latitude">
            <Input value={latitude} onChange={handleLatitudeChange} />
          </FormGroup>
        </Col>
        <Col sm={{ width: '1-2' }}>
          <FormGroup label="Longitude">
            <Input value={longitude} onChange={handleLongitudeChange} />
          </FormGroup>
        </Col>
      </Frow>
      <FormGroup label="Postcode">
        <Input value={postcode} onChange={handlePostcodeChange} />
      </FormGroup>
      <FormGroup label="Pay">
        <Input value={pay} onChange={handlePayChange} />
      </FormGroup>
      <FormGroup label="Remote">
        <Checkbox
          checked={remote}
          onChange={(event) => setRemote(event.currentTarget.checked)}
          label="Will offer remote internships"
        />
      </FormGroup>
      <Button
        fill={true}
        text="Create Job"
        large={true}
        onClick={handleCreate}
        disabled={!title || !workstream || !region || !headcount || sponsorship === undefined}
        loading={isSubmitting}
      />
    </Dialog>
  );
}
