import * as React from 'react';
import {Body, Card, CardLevel, Classes, Drawer, PaddingLevel, Subheading1} from "@pinpointhq/thumbtack";
import {useSpraypaintTableNamedParams} from "../../../shared/hooks/useSpraypaintTable";
import {JobSeeker, University} from "../../../../../javascript/models";
import ReactTable from "react-table";
import SearchField from "../../../../shared/SearchField";
import humanize from "../../../../shared/utilities/humanize";
import {DrawerHeader} from "../../../../shared/DrawerHeader";
import NewPaginationComponent from "../../../shared/NewPaginationComponent";

export function Universities({ cycleId, programmeId }) {
  const [selectedUniversity, setSelectedUniversity] = React.useState();

  const { tableProps, isLoading, setQuery } = useSpraypaintTableNamedParams({
    initialScope: University.extraParams({ cycle_id: cycleId, programme_id: programmeId }).selectExtra(['number_of_job_seekers']),
    searchIdentifier: 'university-table'
  })

  const columns = [
    {
      Cell: (row: any) => {
        return (
          <div>
            <span className={Classes.LINK} onClick={() => setSelectedUniversity(row.original.name)}>
              {row.original.name}
            </span>
          </div>
        )
      },
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Tier',
      accessor: 'tier',
    },
    {
      Header: 'Number of candidates',
      accessor: 'numberOfJobSeekers',
    },
  ]

  const handleSearchChange = (searchTerm) => {
    setQuery({ search: searchTerm })
  }

  const handleClose = () => {
    setSelectedUniversity(null)
  }

  return(
    <>
      <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
        <div className="mar-b-2">
          <Subheading1>Universities</Subheading1>
          <Body muted={true} className="mar-t-1">
            Breakdown of the candidates by university
          </Body>
        </div>
        <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.NONE}>
          <div className="mar-b-2">
            <SearchField handleChange={handleSearchChange} />
          </div>
          <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
            <ReactTable
              {...tableProps}
              loading={isLoading}
              columns={columns}
              className="-unthemed"
              minRows={1}
              showPagination={false}
            />
          </div>
        </Card>
      </Card>
      {selectedUniversity && <SelectedUniversityTable {...{ cycleId, handleClose, programmeId, selectedUniversity }} />}
    </>
  )
}

function SelectedUniversityTable({ cycleId, handleClose, programmeId, selectedUniversity }) {

  const { tableProps, isLoading, setQuery, query } = useSpraypaintTableNamedParams({
    initialScope: JobSeeker.where({ cycle_id: cycleId, programme_id: programmeId, university: selectedUniversity }).selectExtra(['application_count', 'furthest_stage_reached']),
    searchIdentifier: 'university-table'
  })

  const columns = [
    {
      Header: 'Name',
      accessor: 'fullName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Degree',
      accessor: 'degreeSubject',
      sortable: false,
    },
    {
      Header: 'Applications',
      accessor: 'applicationCount',
      width: 90
    },
    {
      Cell: (row: any) => {
        return (
          <div>{row.original.furthestStageReached ? humanize(row.original.furthestStageReached) : 'n/a'}</div>
        )
      },
      Header: 'Furthest stage',
      accessor: 'furthestStageReached',
      sortable: false
    }
  ];

  const paginationProps = React.useCallback(
    () => (tableProps),
    [tableProps],
  );

  return(
    <Drawer isOpen={true} onClose={handleClose} maxWidth={1000}>
      <DrawerHeader title="Universities" handleClose={handleClose} />
      <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.NONE}>
        <ReactTable
          {...tableProps}
          loading={isLoading}
          columns={columns}
          PaginationComponent={NewPaginationComponent}
          getPaginationProps={paginationProps}
          className="-unthemed"
          minRows={1}
        />
      </Card>
    </Drawer>
  )
}