import { Button, FormGroup, Input } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { createToast } from '../../../../../FlashToaster';

export function Form({ company, editingContact, fetchData, setEditingContact }) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState(editingContact.name);
  const [phone, setPhone] = React.useState(editingContact.phone);
  const [email, setEmail] = React.useState(editingContact.email);

  const handleNameChange = (event) => setName(event.currentTarget.value);
  const handlePhoneChange = (event) => setPhone(event.currentTarget.value);
  const handleEmailChange = (event) => setEmail(event.currentTarget.value);

  const handleSave = () => {
    setIsLoading(true);
    editingContact.name = name;
    editingContact.phone = phone;
    editingContact.email = email;
    editingContact.companyId = company.id;
    editingContact.save().then((success) => {
      if (success) {
        createToast({ type: 'success', text: 'Successfully updated contact' });
        fetchData();
        setEditingContact(null);
      } else {
        createToast({ type: 'error', text: 'There was an error updating this contact' });
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <FormGroup label="Name">
        <Input type="text" value={name} onChange={handleNameChange} />
      </FormGroup>
      <FormGroup label="Phone">
        <Input type="text" value={phone} onChange={handlePhoneChange} />
      </FormGroup>
      <FormGroup label="Email">
        <Input type="email" value={email} onChange={handleEmailChange} />
      </FormGroup>
      <Button text="Save" large={true} onClick={handleSave} loading={isLoading} disabled={!name || !email || !phone} />
    </>
  );
}
