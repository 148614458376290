import * as React from 'react';
import * as $ from 'jquery';

import { Card, CardLevel, Heading2, Super2 } from '@pinpointhq/thumbtack';
import ReactTable from 'react-table';
import PaginationComponent from './../awaiting/PaginationComponent';

import { columns } from '../table/columns';
import Filters from '../table/Filters';

export default function Index({ path, programmeId }) {
  const [selection, setSelection] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState();
  const [searchTerm, setSearchTerm] = React.useState<string>()

  React.useEffect(() => {
    getRecords({});
  }, []);

  const handleApplyFilters = (filters) => {
    getRecords(filters)
  }

  function getRecords(filters) {
    $.ajax({
      url: path,
      data: { filters: filters, search: searchTerm },
      type: 'GET',
      dataType: 'json',
      cache: false,
      beforeSend: () => setIsLoading(true),
      complete: () => setIsLoading(false),
      success: (data) => {
        setData(data);
      },
    });
  }

  return (
    <>
      <Card level={CardLevel.FILLED}>
        <div className="frow frow--items-center">
          <Super2 className={isLoading && 'bp3-skeleton'}>{data ? data.meta.total : '100'}</Super2>
          <Heading2 style={{ margin: '0 0 0 16px' }} className={data ? '' : 'bp3-skeleton'}>
            {data ? 'Candidates' : 'Loading'}
          </Heading2>
        </div>
      </Card>
      <Filters {...{ handleApplyFilters, searchTerm, setSearchTerm, selection, setSelection }} meta={data ? data.meta : null} initialFilter={{ application_status: 'hired' }} />
      <div className="pad-a-5">
        <ReactTable
          columns={columns({ data, selection, setSelection, programmeId })}
          data={data && data.data}
          PaginationComponent={PaginationComponent}
          className="-unthemed"
          minRows={1}
          loading={isLoading}
        />
      </div>
    </>
  );
}
