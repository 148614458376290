import * as React from "react";
import { Callout, ICalloutProps } from "@pinpointhq/thumbtack";

/**
 * This is not your average Callout, this is Simple Ricks Simple Callout
 */
export interface ISimpleCallout extends Omit<ICalloutProps, "children"> {
  /**
   * This component does not support custom children. Use the `text` prop.
   */
  children?: never;
  /**
   * Text to display in the body of the callout. It allows you to pass an array e.g. An array of errors
   * as well as a normal string. Each item in the array forms a new line within the Callout
   */
  text: string | string[];
}

export function SimpleCallout({ text, ...calloutProps }: ISimpleCallout) {
  let content: string | React.ReactNode = text;
  if (Array.isArray(text)) {
    content = text.map((message, index) => <div key={index}>{message}.</div>);
  }
  return <Callout {...calloutProps}>{content}</Callout>;
}

export default SimpleCallout;
