import * as React from 'react';
import * as $ from 'jquery';

import { format, parse } from 'date-fns';
import { isWebUri } from 'valid-url';
import { startCase } from 'lodash';

import {
  adminApiJobPath,
  adminApiJobSeekerPath,
  adminJobPath,
} from '../../../javascript/application/ts_routes';
import { CenteredContent } from '../../shared/CenteredContent';
import LoadingSpinner from '../../shared/LoadingSpinner';
import {
  Alerter,
  Body,
  Callout,
  Card,
  CardLevel,
  Classes,
  ColorAliases,
  Frow,
  Icon16,
  IconNames16,
  Heading2,
  Intent,
  Link,
  SimpleAvatar,
  Spinner,
  Tab,
  Tabs,
  PaddingLevel,
  WorkflowTag,
  Divider, Button, Dialog, ISelectOption,
  Select, FormGroup,
} from '@pinpointhq/thumbtack';
import { SanitizedRunningText } from '../../shared/SanitizedRunningText';
import { SubheadedSection } from '../../shared/SubheadedSection';
import useVisibility from '../../shared/hooks/useVisibility';
import { csrfToken } from '../../shared/react_rails_form_helpers';
import { createToast } from '../../FlashToaster';

interface IApplication {
  id: string;
  pinpoint_job_id: string;
  status: string;
  updated_at: string;
}

export default function Show({ applications, candidateId, workstreams }: { applications: IApplication; candidateId: string; workstreams: ISelectOption[] }) {
  const [candidate, setCandidate] = React.useState<any>();
  const [errors, setErrors] = React.useState<any>();
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<string>('details');
  const { isOpen, handleOpen, handleClose } = useVisibility(false)
  const [selectedWorkstream, setSelectedWorkstream] = React.useState()

  React.useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    await fetch(adminApiJobSeekerPath(candidateId), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.errors) {
          setErrors(json.errors);
        } else {
          setErrors(null);
          setCandidate(json);
        }
      })
      .then(() => {
        setIsLoaded(true);
      });
  }

  if (!isLoaded) return <LoadingSpinner />;

  if (errors) {
    return <ErrorRenderer {...{ errors }} errorMessage="There was a problem when trying to retrieve this candidate." />;
  }

  const handleTabChange = (selectedTabId: string) => setSelectedTab(selectedTabId);

  const { first_name: firstName, last_name: lastName } = candidate.data.attributes;

  const handleSelect = (selectedItem) => setSelectedWorkstream(selectedItem)

  // const handleAssign = () => {
  //   Alerter.create({
  //     text: `Are you sure you want to create an allocation in the ${selectedWorkstream.label} sector for this applicant?`,
  //     cancelButtonText: 'cancel',
  //     onConfirm: () => $.ajax({
  //       data: { workstream: selectedWorkstream.value, authenticity_token: csrfToken() },
  //       dataType: 'json',
  //       success: () => {
  //         createToast({
  //           text: `Successfully assigned candidate`,
  //           type: 'success',
  //         });
  //         window.location.reload()
  //       },
  //       type: 'POST',
  //       url: assignAdminCandidatePath(candidateId),
  //     })
  //   });
  // }

  return (
    <>
      <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.EXPANDED}>
        <Frow gutters={16} justifyContent="space-between">
          <div>
            <Frow gutters={16}>
              <div>
                <SimpleAvatar large={true} initials={`${firstName[0]} ${lastName[0]}`} />
              </div>
              <div>
                <Heading2>{candidate.data.attributes.full_name}</Heading2>
              </div>
            </Frow>
          </div>
          <div>
            <Button text="Assign" onClick={handleOpen} large={true} fill={true} />
          </div>
        </Frow>
      </Card>
      <Tabs
        className={[Classes.FOCUS_DISABLED, 'responsive-secondary-tabs'].join(' ')}
        id="candidate-tabs"
        vertical={true}
        muted={true}
        renderActiveTabPanelOnly={true}
        selectedTabId={selectedTab}
        onChange={handleTabChange}
      >
        <Tab panel={<CandidateDetails {...{ candidate }} />} id="details">
          Details
        </Tab>
        <Tab panel={<ApplicationHistoryTab {...{ applications }} />} id="history">
          Assigned Jobs
        </Tab>
      </Tabs>
      <Dialog title="Assign" isOpen={isOpen} onClose={handleClose}>
        <>
          <FormGroup label="Worksteam">
            <Select items={workstreams} onItemSelect={handleSelect} fill={true} activeItem={selectedWorkstream} />
          </FormGroup>
          {/*<Button text="Assign" disabled={!selectedWorkstream} onClick={handleAssign} large={true} fill={true} />*/}
        </>
      </Dialog>
    </>
  );
}

function CandidateDetails({ candidate }) {
  return (
    <>
      <CenteredContent maxWidth={880}>
        <Heading2 className="mar-b-5">Details</Heading2>
        <SubheadedSection title="Submission Details" showDivider={true}>
          <ul className={Classes.LIST_TABULAR}>
            <li>
              <Body semiBold={true}>First Name</Body>
              <div className="text-wrap" aria-label="first_name">
                {candidate.data.attributes.first_name}
              </div>
            </li>
            <li>
              <Body semiBold={true}>Last Name</Body>
              <div className="text-wrap" aria-label="last_name">
                {candidate.data.attributes.last_name}
              </div>
            </li>
            <li>
              <Body semiBold={true}>Email</Body>
              <div className="text-wrap" aria-label="email">
                {candidate.data.attributes.email}
              </div>
            </li>
            <li>
              <Body semiBold={true}>Phone</Body>
              <div className="text-wrap" aria-label="phone">
                {candidate.data.attributes.phone}
              </div>
            </li>
          </ul>
        </SubheadedSection>
        {candidate.included && <QuestionsAndAnswers {...{ candidate }} />}
      </CenteredContent>
    </>
  );
}

function ApplicationHistoryTab({ applications }) {
  return (
    <>
      <CenteredContent maxWidth={880}>
        <Heading2 className="mar-b-5">Assigned Jobs</Heading2>
        <Divider className="mar-v-5" />
        <table className="table table--full table--condensed">
          <thead>
            <tr>
              <th>Job</th>
              <th>Status</th>
              <th>Updated at</th>
            </tr>
          </thead>
          <tbody>
            {applications.length > 0 ? (
              applications.map((application) => {
                return <ApplicationRow key={application.id} application={application} />;
              })
            ) : (
              <tr>
                <td colSpan={3}>
                  <Body muted={true}>This candidate has not been assigned to any jobs yet</Body>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CenteredContent>
    </>
  );
}

function ApplicationRow({ application }: { application: IApplication }) {
  const [errors, setErrors] = React.useState<any>();
  const [job, setJob] = React.useState();
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
  const { pinpoint_job_id: pinpointJobId, status, updated_at: updatedAt } = application;

  React.useEffect(() => {
    fetchJobData();
  }, []);

  async function fetchJobData() {
    await fetch(adminApiJobPath(pinpointJobId), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.errors) {
          setErrors(json.errors);
        } else {
          setErrors(null);
          setJob(json);
        }
      })
      .then(() => {
        setIsLoaded(true);
      });
  }

  if (!isLoaded)
    return (
      <tr>
        <td colSpan={3}>
          <Spinner small={true} />
        </td>
      </tr>
    );

  const jobHref = adminJobPath(job.data.id);
  const { title } = job.data.attributes;

  const renderStatus = () => {
    switch (status) {
      case 'rejected':
        return <WorkflowTag text="Rejected" intent={Intent.DANGER} />;
      case 'hired':
        return <WorkflowTag text="Hired" intent={Intent.SUCCESS} />;
      case 'offer':
        return <WorkflowTag text="Offer" intent={Intent.SUCCESS} />;
      case 'interview':
        return <WorkflowTag text="Interview" intent={Intent.PRIMARY} />;
      case 'review':
        return <WorkflowTag text="Review" intent={Intent.WARNING} />;
      default:
        return <WorkflowTag text={startCase(status)} intent={Intent.NONE} />;
    }
  };

  return (
    <tr>
      <td>
        <Link href={jobHref}>
          {title}
        </Link>
      </td>
      <td>{renderStatus()}</td>
      <td>{format(updatedAt, 'MMMM Do YYYY')}</td>
    </tr>
  );
}

interface IQuestionsAndAnswers {
  candidate: any;
}

const QuestionsAndAnswers = ({ candidate }: IQuestionsAndAnswers) => {
  const answers = candidate.included;

  const filteredAnswers = answers.filter(
    (answer) => answer.attributes.question_type !== 'document' && answer.type !== 'answer_options',
  );
  return (
    <SubheadedSection title="Question &amp; Answers" aria-label="Questions and answers">
      {filteredAnswers.map((answer) => {
        return (
          <div key={answer.id} style={{ marginTop: '24px' }}>
            <Body semiBold={true}>{answer.attributes.title}</Body>
            <FormattedAnswer {...{ answer }} />
          </div>
        );
      })}
    </SubheadedSection>
  );
};

const FormattedAnswer = ({ answer }: { answer: any }) => {
  const noAnswerGiven = <Body muted={true}>No answer given.</Body>;

  switch (answer.attributes.question_type) {
    case 'short_text':
      if (!answer.attributes.text_answer) return noAnswerGiven;

      return <SanitizedRunningText text={answer.attributes.text_answer} muted={true} />;
    case 'long_text':
      if (!answer.attributes.text_answer) return noAnswerGiven;

      return <SanitizedRunningText text={answer.attributes.text_answer} muted={true} />;
    case 'date':
      if (!answer.attributes.date_answer) return noAnswerGiven;

      return <Body muted={true}>{format(parse(answer.dateAnswer), 'MMMM Do YYYY')}</Body>;
    case 'url':
      if (!answer.attributes.url_answer) return noAnswerGiven;

      if (isWebUri(answer.attributes.url_answer)) {
        return (
          <Link href={answer.attributes.url_answer} target="_blank" style={{ wordBreak: 'break-all' }}>
            {answer.urlAnswer}
          </Link>
        );
      }
      return <Body muted={true}>answer.attributes.url_answer</Body>;
    case 'boolean':
      if (answer.attributes.boolean_answer === true) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon16 icon={IconNames16.SUCCESS} style={{ marginRight: '8px' }} fill={ColorAliases.SUCCESS} />
            <Body muted={true}>Yes</Body>
          </div>
        );
      }
      if (answer.attributes.boolean_answer === false) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon16 icon={IconNames16.REJECTED} style={{ marginRight: '8px' }} fill={ColorAliases.DANGER} />
            <Body muted={true}>No</Body>
          </div>
        );
      }
      return noAnswerGiven;
    case 'multiple_choice':
      if (answer.attributes.multiple_choice_answer.length === 0) return <Body muted={true}>No options selected.</Body>;

      const choices = answer.attributes.multiple_choice_answer.map((choice, index) => {
        return (
          <li key={index}>
            <Body muted={true}>{choice}</Body>
          </li>
        );
      });
      return (
        <div className={Classes.RUNNING_TEXT}>
          <ul>{choices}</ul>
        </div>
      );
  }
};

function ErrorRenderer({ errors, errorMessage }) {
  return (
    <>
      <Card level={CardLevel.FILLED}>
        <Callout intent={Intent.DANGER} title={errorMessage}>
          {errors.map((error, index) => {
            return <p key={index}>{error.title}</p>;
          })}
        </Callout>
      </Card>
    </>
  );
}
