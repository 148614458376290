import { Link } from '@pinpointhq/thumbtack';
import { adminJobPath } from '../../../../javascript/application/ts_routes';
import * as React from 'react';

export function columns() {
  return [
    {
      Cell: (row: any) => {
        return (
          <Link target="_blank" href={adminJobPath(row.original.attributes.pinpoint_job_id)}>
            {row.original.attributes.company}
          </Link>
        );
      },
      Header: 'Company',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.attributes.workstream}</div>;
      },
      Header: 'Workstream',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.attributes.applications_count}</div>
      },
      Header: 'No. of applications',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.attributes.applied_applications_count}</div>
      },
      Header: 'Applied applications',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.attributes.rejected_applications_count}</div>
      },
      Header: 'Rejected applications',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.attributes.review_applications_count}</div>
      },
      Header: 'Review applications',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.attributes.interview_applications_count}</div>
      },
      Header: 'Interview applications',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.attributes.offer_applications_count}</div>
      },
      Header: 'Offer applications',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return <div>{row.original.attributes.hired_applications_count}</div>
      },
      Header: 'Hired applications',
      sortable: false,
    },
  ];
}
