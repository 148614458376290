import * as React from 'react';
import * as $ from 'jquery';
import {
  adminProgrammeCompanyTimelinesPath,
} from '../../../javascript/application/ts_routes';
import {Card, CardLevel, Heading2, Super2} from '@pinpointhq/thumbtack';
import Filters from './table/Filters';
import ReactTable from 'react-table';
import PaginationComponent from '../candidates/awaiting/PaginationComponent';
import { columns } from './table/columns';

export default function Index({ currentCycleId, cycleOptions, programmeId }) {
  const [cycleId, setCycleId] = React.useState<string>(currentCycleId)
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState();
  const [searchTerm, setSearchTerm] = React.useState<string>()

  React.useEffect(() => {
    getRecords({});
  }, [cycleId, searchTerm]);

  function getRecords(filters) {
    $.ajax({
      url: adminProgrammeCompanyTimelinesPath(programmeId),
      data: { filters: { ...filters, cycle_id: cycleId }, search: searchTerm},
      type: 'GET',
      dataType: 'json',
      cache: false,
      beforeSend: () => setIsLoading(true),
      complete: () => setIsLoading(false),
      success: (data) => setData(data),
    });
  }

  const handleApplyFilters = (filters) => {
    getRecords(filters)
  }

  const rowStyle = (state, rowInfo, instance) => {
    if (!rowInfo) return {}

    let rowColor: string = 'black'
    switch(rowInfo.original.attributes.timeline_status) {
      case 'red':
        rowColor = '#F1564E';
        break;
      case 'orange':
        rowColor = '#FFA500';
        break;
      case 'yellow':
        rowColor = '#FCB643';
        break;
      case 'green':
        rowColor = '#278753';
        break;
    }
    return {
      style: {
        color: rowColor,
      },
    };
  };

  return (
    <>
      <Card level={CardLevel.FILLED}>
        <div className="frow frow--items-center">
          <Super2 className={isLoading && 'bp3-skeleton'}>{data ? data.meta.total : '100'}</Super2>
          <Heading2 style={{ margin: '0 0 0 16px' }} className={data ? '' : 'bp3-skeleton'}>
            Company Timelines
          </Heading2>
        </div>
      </Card>
      <Filters {...{ cycleId, cycleOptions, handleApplyFilters, searchTerm, setSearchTerm, setCycleId, programmeId }} />
      <div className="pad-a-5">
        <ReactTable
          columns={columns}
          data={data && data.data}
          getTrProps={rowStyle}
          PaginationComponent={PaginationComponent}
          className="-unthemed"
          minRows={1}
        />
      </div>
    </>
  );
}
