import * as React from 'react';
import { Link } from '@pinpointhq/thumbtack';
import { adminProgrammeCompanyPath } from '../../../../javascript/application/ts_routes'
import humanize from "../../../shared/utilities/humanize";
import {format} from "date-fns";

export const columns = (programmeId) => {
  return [
    {
      Cell: (row: any) => {
        return (
          <Link href={adminProgrammeCompanyPath(programmeId, row.original.company.id)}>{row.original.company.name}</Link>
        )
      },
      Header: 'Name',
      sortable: false,
    },
    {
      Cell: (row: any) => <div>{row.original.companyCycleInformation.offersExtended}</div>,
      Header: 'Offers',
      sortable: false,
      width: 110,
    },
    {
      Cell: (row: any) => <div>{row.original.companyCycleInformation.numberOfHires}</div>,
      Header: 'Hires',
      sortable: false,
      width: 100,
    },
    {
      Cell: (row: any) => {
        const {initialPledge} = row.original.companyCycleInformation;
        return <div>{initialPledge ? initialPledge : '-'}</div>;
      },
      Header: 'Original Pledge',
      sortable: false,
      width: 180,
    },
    {
      Cell: (row: any) => {
        const {currentPledge} = row.original.companyCycleInformation;
        return <div>{currentPledge ? currentPledge : '-'}</div>;
      },
      Header: 'Current Pledge',
      sortable: false,
      width: 180,
    },
    {
      Cell: (row: any) => <div>{row.original.companyCycleInformation.percentageOfPledge}</div>,
      Header: '% of pledge offers + hired',
      sortable: false,
      width: 220,
    },
    {
      Cell: (row: any) => <div>{humanize(row.original.pledgeStatus)}</div>,
      Header: 'Status',
      sortable: false,
      width: 180,
    },
  ]
}

export const submissionColumns = (programmeId) => {
  return [
    {
      Cell: (row: any) => {
        return (
          <Link href={adminProgrammeCompanyPath(programmeId, row.original.companyId)}>{row.original.company.name}</Link>
        )
      },
      Header: 'Name',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return(
          <div>
            {row.original.companySubmission.completedAt ? format(row.original.companySubmission.completedAt, 'Do MMM YY') : row.original.companySubmission.completedAt}
          </div>
        )
      },
      Header: 'Completed at',
      sortable: false,
      width: 200
    },
    {
      Cell: (row: any) => <div>{row.original.companySubmission.contactName}</div>,
      Header: 'Name',
      sortable: false,
      width: 200
    },
    {
      Cell: (row: any) => <div>{row.original.companySubmission.contactEmail}</div>,
      Header: 'Email',
      sortable: false,
      width: 200
    },
    {
      Cell: (row: any) => <div>{row.original.companySubmission.contactMobileNumber}</div>,
      Header: 'Phone',
      sortable: false,
      width: 200
    },
    // {
    //   Cell: (row: any) => {
    //     return <div>{row.original.companySubmission.internships_pledged}</div>;
    //   },
    //   Header: 'Pledge',
    //   sortable: false,
    //   width: 100
    // },
    // {
    //   Cell: (row: any) => {
    //     return <div>{row.original.companySubmission.previous_pledge}</div>;
    //   },
    //   Header: 'Previous pledge',
    //   sortable: false,
    //   width: 180
    // },
  ]
}
