import {
  Alerter,
  Card,
  CardLevel,
  Drawer,
  IconButton40,
  IconNames16,
  Intent,
  Menu,
  MenuItem,
  PaddingLevel,
  Popover,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import ReactTable from 'react-table';
import { Application } from '../../../../../../../javascript/models';
import { createToast } from '../../../../../../FlashToaster';
import { DrawerHeader } from '../../../../../../shared/DrawerHeader';
import humanize from '../../../../../../shared/utilities/humanize';
import { useSpraypaintTableNamedParams } from '../../../../../shared/hooks/useSpraypaintTable';
import NewPaginationComponent from '../../../../../shared/NewPaginationComponent';

export function ApplicationDrawer({ handleClose, job }) {
  const { tableProps, fetchData } = useSpraypaintTableNamedParams({
    initialScope: Application.where({ job_id: job.id }).includes(['jobSeeker']),
    searchIdentifier: 'applications',
  });

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  const columns = [
    {
      Header: 'Name',
      accessor: 'jobSeeker.fullName',
    },
    {
      Header: 'Email',
      accessor: 'jobSeeker.email',
    },
    {
      Cell: (row: { original: Application }) => {
        return humanize(row.original.status);
      },
      Header: 'Status',
      accessor: 'status',
    },
    {
      Cell: (row: { original: Application }) => {
        const handleDelete = () => {
          Alerter.create({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes',
            onConfirm: () => {
              row.original.destroy().then((success) => {
                if (success) {
                  createToast({ type: 'success', text: 'Application successfully deleted' });
                  fetchData();
                }
              });
            },
            text:
              'Are you sure you want to delete this application? This action cannot be undone, and will be reflected in the companies Pinpoint instance',
          });
        };

        return (
          <Popover>
            <IconButton40 icon={IconNames16.OVERFLOW} />
            <Menu>
              <MenuItem intent={Intent.DANGER} text="Delete" onClick={handleDelete} />
            </Menu>
          </Popover>
        );
      },
      Header: '',
      accessor: 'id',
      sortable: false,
      width: 80,
    },
  ];

  return (
    <Drawer isOpen={true} onClose={handleClose} maxWidth={1000}>
      <DrawerHeader title="Applications" handleClose={handleClose} />
      <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.REGULAR} style={{ overflowY: 'scroll' }}>
        <ReactTable
          {...tableProps}
          columns={columns}
          PaginationComponent={NewPaginationComponent}
          getPaginationProps={paginationProps}
          className="-unthemed"
          minRows={1}
        />
      </Card>
    </Drawer>
  );
}
