import { Button, Card, CardLevel, Drawer, FormGroup, Input, PaddingLevel } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { Candidate } from '../../../../javascript/models';
import { createToast } from '../../../FlashToaster';
import { DrawerHeader } from '../../../shared/DrawerHeader';

interface IEditDrawer {
  candidate: Candidate;
  fetchData: () => void;
  handleClose: () => void;
}

export function EditDrawer({ candidate, fetchData, handleClose }: IEditDrawer) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [firstName, setFirstName] = React.useState(candidate.firstName);
  const [lastName, setLastName] = React.useState(candidate.lastName);
  const [email, setEmail] = React.useState(candidate.email);
  const [phoneNumber, setPhoneNumber] = React.useState(candidate.phoneNumber);

  const handleFirstNameChange = (event) => setFirstName(event.currentTarget.value);
  const handleLastNameChange = (event) => setLastName(event.currentTarget.value);
  const handleEmailChange = (event) => setEmail(event.currentTarget.value);
  const handlePhoneNumberChange = (event) => setPhoneNumber(event.currentTarget.value);

  const handleSave = () => {
    setIsSubmitting(true);
    candidate.firstName = firstName;
    candidate.lastName = lastName;
    candidate.email = email;
    candidate.phoneNumber = phoneNumber;
    candidate.save().then((success) => {
      if (success) {
        createToast({ type: 'success', text: 'Successfully updated candidate' });
        fetchData();
        handleClose();
      } else {
        createToast({ type: 'error', text: 'Failed to save candidate' });
        setIsSubmitting(false);
      }
    });
  };

  return (
    <Drawer maxWidth={600} isOpen={true}>
      <DrawerHeader title="Edit candidate" handleClose={handleClose} />
      <Card
        paddingLevel={PaddingLevel.REGULAR}
        level={CardLevel.NONE}
        cardInnerProps={{ className: 'pad-h-5 pad-v-3' }}
      >
        <FormGroup label="First name">
          <Input type="text" value={firstName} onChange={handleFirstNameChange} />
        </FormGroup>
        <FormGroup label="Last name">
          <Input type="text" value={lastName} onChange={handleLastNameChange} />
        </FormGroup>
        <FormGroup label="Email">
          <Input type="text" value={email} onChange={handleEmailChange} />
        </FormGroup>
        <FormGroup label="Phone number">
          <Input type="text" value={phoneNumber} onChange={handlePhoneNumberChange} />
        </FormGroup>
        <Button text="Save" loading={isSubmitting} onClick={handleSave} large={true} />
      </Card>
    </Drawer>
  );
}
