import * as React from 'react';
import {
  Body,
  Caption,
  Card,
  CardLevel,
  Classes,
  Col,
  Colors,
  Frow,
  Icon8,
  IconNames8,
  Lead,
  PaddingLevel,
  Subheading1,
  Super2
} from "@pinpointhq/thumbtack";
import {useSpraypaintNamedParams} from "../../shared/hooks/useSpraypaint";
import {JobSeeker} from "../../../../javascript/models";
import {CardListWithChart} from "../../dashboard/insights/CandidateInsightDoughnutChart";
import CandidateQuality from "../../dashboard/insights/CandidateQuality";
import {EligibilityTable} from "./applicationData/EligibilityTable";
import {Universities} from "./applicationData/Universities";

export function ApplicationData({ cycle, programme }) {

  const { meta: jobSeekerMeta, isInitialLoading } = useSpraypaintNamedParams(
    {
      initialScope: JobSeeker.per(0).where(
        { cycle_id: cycle.id, programmeId: programme.id }
      ).extraParams({ cycle_id: cycle.id, programme_id: programme.id }).stats(
        {
          count: ['eligible', 'ineligible'],
          pie_chart: ['job_seeker_by_channel', 'job_seeker_by_gender', 'job_seeker_by_education_level', 'job_seeker_by_ethnicity', 'job_seeker_by_disability'],
          headline: ['daily_applications_over_last_week', 'average_applications_by_internship', 'internships_accepting_visas', 'candidates_requiring_visas']
        }
      )
    }
  )

  const [eligibility, setEligibility] = React.useState<string>()

  return(
    <>
      <Frow flexDirection="column" verticalGutters={40}>
        <Col>
          <Frow gutters={40}>
            <Col sm={{ width: '1-2' }}>
              <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
                <div className="mar-b-2">
                  <Subheading1>Eligibility</Subheading1>
                  <Body muted={true} className="mar-t-1">
                    Eligible candidates
                  </Body>
                </div>
                <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
                  {isInitialLoading ? <div className={Classes.SKELETON} /> : (
                    <ul className={Classes.LIST_TABULAR}>
                      <li style={{ padding: '12px 0' }}>
                        <Frow style={{ width: '100%' }}>
                          <div className="col-flex-grow-1">
                            <Frow alignItems="center" gutters={8} flexWrap="nowrap">
                              <div>
                                <Icon8 icon={IconNames8.BULLET_LARGE} style={{ fill: Colors.SAGE_50 }} />
                              </div>
                              <div>
                                <Body muted={true}>
                                  <span className={Classes.LINK} onClick={() => setEligibility('eligible')}>
                                    Eligible
                                  </span>
                                </Body>
                              </div>
                            </Frow>
                          </div>
                          <div>
                            <Body semiBold={true} className="text-right">{jobSeekerMeta.stats.count.eligible}</Body>
                          </div>
                        </Frow>
                      </li>
                      <li style={{ padding: '12px 0' }}>
                        <Frow style={{ width: '100%' }}>
                          <div className="col-flex-grow-1">
                            <Frow alignItems="center" gutters={8} flexWrap="nowrap">
                              <div>
                                <Icon8 icon={IconNames8.BULLET_LARGE} style={{ fill: Colors.YELLOW_50 }} />
                              </div>
                              <div>
                                <Body muted={true}>
                                  <span className={Classes.LINK} onClick={() => setEligibility('ineligible')}>
                                    Ineligibles
                                  </span>
                                </Body>
                              </div>
                            </Frow>
                          </div>
                          <div>
                            <Body semiBold={true} className="text-right">{jobSeekerMeta.stats.count.ineligible}</Body>
                          </div>
                        </Frow>
                      </li>
                    </ul>
                  )}
                </Card>
              </Card>
            </Col>
            <Col md={{ width: '1-2' }}>
              <Frow verticalGutters={24} gutters={24}>
                <Col md={{ width: '1-2' }}>
                  <Card
                    level={CardLevel.FILLED}
                    paddingLevel={PaddingLevel.REGULAR}
                    style={{ backgroundColor: Colors.IVORY_20, borderLeft: `2px solid ${Colors.IVORY_30}` }}
                  >
                    <Super2 className={isInitialLoading ? Classes.SKELETON : ''}>{isInitialLoading ? '0' : jobSeekerMeta.stats.headline.daily_applications_over_last_week}</Super2>
                    <Lead>Average number of daily applications</Lead>
                    <Caption muted={true}>over the last week</Caption>
                  </Card>
                </Col>
                <Col md={{ width: '1-2' }}>
                  <Card
                    level={CardLevel.FILLED}
                    paddingLevel={PaddingLevel.REGULAR}
                    style={{ backgroundColor: Colors.IVORY_20, borderLeft: `2px solid ${Colors.IVORY_30}` }}
                  >
                    <Super2 className={isInitialLoading ? Classes.SKELETON : ''}>{isInitialLoading ? '0' : jobSeekerMeta.stats.headline.average_applications_by_internship}</Super2>
                    <Lead>Average no. applications</Lead>
                    <Caption>per internship</Caption>
                  </Card>
                </Col>
                <Col md={{ width: '1-2' }}>
                  <Card
                    level={CardLevel.FILLED}
                    paddingLevel={PaddingLevel.REGULAR}
                    style={{ backgroundColor: Colors.IVORY_20, borderLeft: `2px solid ${Colors.IVORY_30}`, height: '100%' }}
                    cardInnerProps={{ style: { height: '100%' } }}
                  >
                    <Super2 className={isInitialLoading ? Classes.SKELETON : ''}>{isInitialLoading ? '0' : jobSeekerMeta.stats.headline.candidates_requiring_visas}</Super2>
                    <Lead>Candidates</Lead>
                    <Caption>that require visas</Caption>
                  </Card>
                </Col>
                <Col md={{ width: '1-2' }}>
                  <Card
                    level={CardLevel.FILLED}
                    paddingLevel={PaddingLevel.REGULAR}
                    style={{ backgroundColor: Colors.IVORY_20, borderLeft: `2px solid ${Colors.IVORY_30}` }}
                  >
                    <Super2 className={isInitialLoading ? Classes.SKELETON : ''}>{isInitialLoading ? '0' : jobSeekerMeta.stats.headline.internships_accepting_visas}</Super2>
                    <Lead>Internships</Lead>
                    <Caption muted={true}>that will accept candidates who require visas</Caption>
                  </Card>
                </Col>
              </Frow>
            </Col>
          </Frow>
        </Col>
        <Col>
          <Universities cycleId={cycle.id} programmeId={programme.id} />
        </Col>
        <Col>
          <Frow gutters={40} verticalGutters={40}>
            <Col md={{ width: '1-2' }}>
              <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
                <div className="mar-b-2">
                  <Subheading1>Applicants by channel</Subheading1>
                  <Body muted={true} className="mar-t-1">
                    Applications broken down by channel
                  </Body>
                </div>
                <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
                  <CardListWithChart
                    array={isInitialLoading ? [] : jobSeekerMeta.stats.pie_chart.job_seeker_by_channel}
                    isLoading={isInitialLoading}
                  />
                </Card>
              </Card>
            </Col>
            <Col md={{ width: '1-2' }}>
              <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
                <div className="mar-b-2">
                  <Subheading1>Applicants by {programme.name === 'Black Interns' ? 'Ethnicity' : 'Disability'}</Subheading1>
                  <Body muted={true} className="mar-t-1">
                    Applications broken down by {programme.name === 'Black Interns' ? 'ethnicity' : 'disability'}
                  </Body>
                </div>
                <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
                  <CardListWithChart
                    array={isInitialLoading ? [] : programme.name === 'Black Interns' ? jobSeekerMeta.stats.pie_chart.job_seeker_by_ethnicity : jobSeekerMeta.stats.pie_chart.job_seeker_by_disability}
                    isLoading={isInitialLoading}
                  />
                </Card>
              </Card>
            </Col>
          </Frow>
        </Col>
        <Col>
          <CandidateQuality programmeId={programme.id} cycleId={cycle.id} />
        </Col>
        <Col>
          <Frow gutters={40} verticalGutters={40}>
            <Col md={{ width: '1-2' }}>
              <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
                <div className="mar-b-2">
                  <Subheading1>Applicants by gender</Subheading1>
                  <Body muted={true} className="mar-t-1">
                    Applications broken down by gender
                  </Body>
                </div>
                <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
                  <CardListWithChart
                    array={isInitialLoading ? [] : jobSeekerMeta.stats.pie_chart.job_seeker_by_gender}
                    isLoading={isInitialLoading}
                  />
                </Card>
              </Card>
            </Col>
            <Col md={{ width: '1-2' }}>
              <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
                <div className="mar-b-2">
                  <Subheading1>Applicants by education level</Subheading1>
                  <Body muted={true} className="mar-t-1">
                    Applications broken down by education level
                  </Body>
                </div>
                <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
                  <CardListWithChart
                    array={isInitialLoading ? [] : jobSeekerMeta.stats.pie_chart.job_seeker_by_education_level}
                    isLoading={isInitialLoading}
                  />
                </Card>
              </Card>
            </Col>
          </Frow>
        </Col>
      </Frow>
      {eligibility && <EligibilityTable cycleId={cycle.id} programmeId={programme.id} selectedTab={eligibility} {...{ setEligibility }} />}
    </>
  )
}