import { Card, CardLevel, Drawer, Frow, PaddingLevel } from '@pinpointhq/thumbtack';
import * as React from 'react';
import ReactTable from 'react-table';
import { DrawerHeader } from '../../shared/DrawerHeader';
import LoadingSpinner from '../../shared/LoadingSpinner';
import Search from '../../shared/Search';
import PaginationComponent from '../candidates/awaiting/PaginationComponent';

export function InsightDrawer({ isOpen, fetchUrl, handleClose }) {
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState();

  React.useEffect(() => {
    fetchData();
  }, []);

  async function fetchData(args = { cycleId: null, allocationType: null }) {
    const { allocationType, cycleId } = args;
    let url = `${fetchUrl}`;
    // const url = cycleId ? `${fetchUrl}?cycle_id=${cycleId}` : fetchUrl
    if (cycleId) {
      url = url.includes('?') ? `&${url}cycle_id=${cycleId}&` : `?${url}cycle_id=${cycleId}&`;
    }
    if (allocationType) {
      url = `${url}allocation_type=${allocationType}`;
    }
    await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.errors) {
        } else {
          setData(json);
        }
      })
      .then(() => {
        setLoading(false);
      });
  }

  if (loading) {
    return (
      <Drawer isOpen={isOpen} onClose={handleClose} maxWidth={1000}>
        <DrawerHeader title="Loading..." handleClose={handleClose} />
        <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.NONE}>
          <LoadingSpinner />
        </Card>
      </Drawer>
    );
  }

  const columns = data.tableHeaders.map((tableHeader) => {
    return {
      Cell: (row: any) => {
        return <div>{row.original[tableHeader]}</div>;
      },
      Header: tableHeader,
      sortable: false,
    };
  });

  const handleSearch = (search) => setSearchTerm(search);

  const filteredData = data.tableData.filter((item) => {
    if (searchTerm) {
      return item[data.searchAttribute].toLowerCase().includes(searchTerm.toLowerCase());
    } else {
      return true;
    }
  });

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} maxWidth={1000}>
      <DrawerHeader title={data.title} handleClose={handleClose} />
      <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.NONE} style={{ overflowY: 'scroll' }}>
        <Frow className="mar-b-2">
          <div className="col-flex-grow-1">
            <Search target="identifier" handleSearch={handleSearch} />
          </div>
        </Frow>
        <ReactTable
          columns={columns}
          data={filteredData && filteredData}
          PaginationComponent={PaginationComponent}
          className="-unthemed"
          minRows={1}
        />
      </Card>
    </Drawer>
  );
}
