export interface IPromiseAllWithProgressDetails {
  complete: number;
  percentage: number;
  total: number;
}

export interface IPromiseAllWithProgress {
  promises: Promise<any>[];
  callback(progressDetails: IPromiseAllWithProgressDetails): void;
}

export function promiseAllWithProgress({ promises, callback }: IPromiseAllWithProgress) {
  let numComplete = 0;
  callback({ complete: 0, percentage: 0, total: promises.length });
  promises.forEach((promise) => {
    promise.then(() => {
      numComplete += 1;
      const progressDetails: IPromiseAllWithProgressDetails = {
        complete: numComplete,
        percentage: Math.round((numComplete * 100) / promises.length),
        total: promises.length,
      };
      callback(progressDetails);
    });
  });
  return Promise.all(promises);
}
