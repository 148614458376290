import {
  Alerter,
  Body,
  Button,
  Card,
  CardLevel,
  Dialog,
  Drawer,
  Frow,
  Heading2,
  IconButton40,
  IconNames16,
  Intent,
  Link,
  PaddingLevel,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { visit } from 'turbolinks';
import { exportAdminAllocationRunPath } from '../../../javascript/application/ts_routes';
import { AllocationApplication, AllocationRun } from '../../../javascript/models';
import { createToast } from '../../FlashToaster';
import { DrawerFooter } from '../../shared/DrawerFooter';
import { DrawerHeader } from '../../shared/DrawerHeader';
import LoadingSpinner from '../../shared/LoadingSpinner';
import useSpraypaintFind from '../shared/hooks/useSpraypaintFind';

export function ShowDrawer({ allocationRun, fetchData, handleClose }) {
  const { data: run, isLoading } = useSpraypaintFind(
    AllocationRun.includes({ allocationApplications: { job_seeker: ['addresses'] } }),
    allocationRun.id,
  );

  const [allocationApplications, setAllocationApplications] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (run) {
      setAllocationApplications(run.allocationApplications);
    }
  }, [run, setAllocationApplications]);

  const [dialogContent, setDialogContent] = React.useState<string | null>(null);
  const [confirmRemove, setConfirmRemove] = React.useState<boolean>(true);

  const handleAssignRun = () => {
    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      onConfirm: () => {
        setIsSubmitting(true);
        run.assign = true;
        run.save().then((success) => {
          if (success) {
            createToast({ text: 'Allocation assigned', type: 'success' });
            fetchData();
            handleClose();
          } else {
            setIsSubmitting(false);
            createToast({ text: 'Unable to assign allocation', type: 'error' });
          }
        });
      },
      text:
        'Are you sure you want to assign this allocation? This will allocate the applications and post them to the companies Pinpoint instance',
    });
  };

  return (
    <Drawer maxWidth={2000} isOpen={true} onClose={handleClose}>
      <DrawerHeader title="Allocation Run" handleClose={handleClose} />
      <Card paddingLevel={PaddingLevel.EXPANDED} level={CardLevel.NONE} style={{ overflowY: 'scroll' }}>
        <div className="mar-b-2">
          <Heading2>Output files</Heading2>
          {Object.keys(allocationRun.output).map((key) => {
            const handleLinkClick = () => {
              visit(exportAdminAllocationRunPath(allocationRun.id, { job_id: key }));
            };
            return (
              <div key={key}>
                <Link onClick={handleLinkClick}>{allocationRun.output[key].title}</Link>
              </div>
            );
          })}
        </div>
        <div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Heading2>Allocations</Heading2>
              <div style={{ overflowY: 'scroll' }}>
                <table className="table table--full table--condensed">
                  <thead>
                    <tr>
                      <th></th>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Preference 1</th>
                      <th>Cover 1</th>
                      <th>Preference 2</th>
                      <th>Cover 2</th>
                      <th>Preference 3</th>
                      <th>Cover 3</th>
                      <th>CV</th>
                      <th>Visa required</th>
                      <th>Year of graduation</th>
                      <th>Level of education</th>
                      <th>University</th>
                      <th>Other University Name</th>
                      <th>Degree subject closest match</th>
                      <th>Degree subject exact</th>
                      <th>Gender</th>
                      <th>Ethnicity</th>
                      <th>Skills</th>
                      <th>Address 1</th>
                      <th>Address 2</th>
                      <th>Address 3</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allocationApplications.map((allocationApplication: AllocationApplication) => {
                      const { jobSeeker } = allocationApplication;
                      const handleClick = (content: string) => {
                        setDialogContent(content);
                      };

                      const handleDelete = () => {
                        if (confirmRemove) {
                          Alerter.create({
                            cancelButtonText: 'Cancel',
                            confirmButtonText: 'Yes',
                            intent: Intent.DANGER,
                            onConfirm: () => {
                              allocationApplication.destroy().then(() => {
                                setAllocationApplications(
                                  allocationApplications.filter((app) => app.id !== allocationApplication.id),
                                );
                                createToast({ text: 'Allocation removed', type: 'success' });
                              });
                            },
                            text: 'Are you sure you want to remove this allocation?',
                          });
                        }
                      };

                      return (
                        <tr key={allocationApplication.id}>
                          <td>
                            {allocationRun.status === 'completed' && (
                              <IconButton40 icon={IconNames16.DELETE} intent={Intent.DANGER} onClick={handleDelete} />
                            )}
                          </td>
                          <td>{jobSeeker.id}</td>
                          <td>{jobSeeker.fullName}</td>
                          <td>{jobSeeker.email}</td>
                          <td>{jobSeeker.internshipSectorPreferenceOne}</td>
                          <td style={{ minWidth: '150px' }}>
                            <Link onClick={() => handleClick(jobSeeker.internshipSectorPreferenceOneRationale)}>
                              Click to see more
                            </Link>
                          </td>
                          <td>{jobSeeker.internshipSectorPreferenceTwo}</td>
                          <td style={{ minWidth: '150px' }}>
                            <Link onClick={() => handleClick(jobSeeker.internshipSectorPreferenceTwoRationale)}>
                              Click to see more
                            </Link>
                          </td>
                          <td>{jobSeeker.internshipSectorPreferenceThree}</td>
                          <td style={{ minWidth: '150px' }}>
                            <Link onClick={() => handleClick(jobSeeker.internshipSectorPreferenceThreeRationale)}>
                              Click to see more
                            </Link>
                          </td>
                          <td>
                            <Link href={jobSeeker.cvUrl} target="_blank">
                              Click here
                            </Link>
                          </td>
                          <td>{jobSeeker.visaRequired}</td>
                          <td>{jobSeeker.yearOfGraduation}</td>
                          <td>{jobSeeker.levelOfEducation}</td>
                          <td>{jobSeeker.university}</td>
                          <td>{jobSeeker.otherUniversityName}</td>
                          <td>{jobSeeker.degreeSubject}</td>
                          <td>{jobSeeker.exactDegreeTitle}</td>
                          <td>{jobSeeker.gender}</td>
                          <td style={{ minWidth: '280px' }}>{jobSeeker.ethnicity}</td>
                          <td>{jobSeeker.proficientSkills}</td>
                          <td>{jobSeeker.addresses[0] && jobSeeker.addresses[0].region}</td>
                          <td>{jobSeeker.addresses[1] && jobSeeker.addresses[1].region}</td>
                          <td>{jobSeeker.addresses[2] && jobSeeker.addresses[2].region}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </Card>
      {dialogContent && (
        <Dialog isOpen={true} onClose={() => setDialogContent(null)} title="">
          <Body dangerouslySetInnerHTML={{ __html: dialogContent }} />
        </Dialog>
      )}
      <DrawerFooter>
        <Frow justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
          <div>
            <Button text="Cancel" minimal={true} />
          </div>
          {allocationRun.status === 'completed' && (
            <div>
              <Button text="Assign" onClick={handleAssignRun} />
            </div>
          )}
        </Frow>
      </DrawerFooter>
    </Drawer>
  );
}
