import * as React from 'react';
import {
  Body,
  Card,
  CardLevel,
  Classes,
  Col,
  Colors,
  Drawer,
  Frow,
  Icon8,
  IconNames8, Link,
  PaddingLevel,
  Subheading1
} from "@pinpointhq/thumbtack";
import {useSpraypaintNamedParams} from "../../shared/hooks/useSpraypaint";
import {Company, Workstream} from "../../../../javascript/models";
import LoadingSpinner from "../../../shared/LoadingSpinner";
import {AllRolloverCompanies} from "./rollover/AllRolloverCompanies";
import {SuperleagueCompanies} from "./rollover/SuperleagueCompanies";

interface IRollover {
  cycle: { id: string; name: string; };
  programme: { id: string };
}

export default function Rollover({ cycle, programme }: IRollover) {
  const [selectedTab, setSelectedTab] = React.useState(null)
  const [selectedDrawer, setSelectedDrawer] = React.useState(null)
  const initialScope = Company.per(0).stats({ count: ['rollover', 'superleague'] }).extraParams({ cycle_id: cycle.id, programme_id: programme.id })
  const {meta, isInitialLoading} = useSpraypaintNamedParams({ initialScope: initialScope })

  const initialWorkstreamScope = Workstream.per(0).stats({ count: ['pledges'] }).extraParams({ cycle_id: cycle.id, programme_id: programme.id })
  const {meta: workstreamMeta, isInitialLoading: isWorkstreamLoading} = useSpraypaintNamedParams({ initialScope: initialWorkstreamScope })

  const handleDrawerClose = () => {
    setSelectedDrawer(null)
    setSelectedTab(null)
  };

  return(
    <>
      <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.REGULAR}>
        <Subheading1>Rollover organisations</Subheading1>
        <Body className="mar-t-1 mar-b-3">
          Organisations who took part in the last cycle and chose to participate in this.
        </Body>
        <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR} className="mar-b-3">
          <Subheading1 className="mar-b-2">Summary</Subheading1>
          <ul className={Classes.LIST_TABULAR}>
            {isInitialLoading ? <LoadingSpinner /> : (
              <>
                <li style={{ padding: '12px 0' }}>
                  <Frow style={{ width: '100%' }}>
                    <div className="col-flex-grow-1">
                      <Link className={Classes.LINK} onClick={() => {
                        setSelectedTab('all-companies')
                        setSelectedDrawer('all-rollover-companies')
                      }}>
                        Rollover organisations from previous period
                      </Link>
                    </div>
                    <div>
                      <Body semiBold={true} className="text-right">{meta.stats.count.rollover.total}</Body>
                    </div>
                  </Frow>
                </li>
                <li style={{ padding: '12px 0' }}>
                  <Frow style={{ width: '100%' }}>
                    <div className="col-flex-grow-1">
                      <Link className={Classes.LINK} onClick={() => {
                        setSelectedTab('increased-pledge')
                        setSelectedDrawer('all-rollover-companies')
                      }}>
                        Organisations with increased pledge
                      </Link>
                    </div>
                    <div>
                      <Body semiBold={true} className="text-right">{meta.stats.count.rollover.increased_pledge}</Body>
                    </div>
                  </Frow>
                </li>
                <li style={{ padding: '12px 0' }}>
                  <Frow style={{ width: '100%' }}>
                    <div className="col-flex-grow-1">
                      <Link className={Classes.LINK} onClick={() => {
                        setSelectedTab('decreased-pledge')
                        setSelectedDrawer('all-rollover-companies')
                      }}>
                        Organisations with decreased pledge
                      </Link>
                    </div>
                    <div>
                      <Body semiBold={true} className="text-right">{meta.stats.count.rollover.decreased_pledge}</Body>
                    </div>
                  </Frow>
                </li>
                <li style={{ padding: '12px 0' }}>
                  <Frow style={{ width: '100%' }}>
                    <div className="col-flex-grow-1">
                      <Link className={Classes.LINK} onClick={() => {
                        setSelectedTab('same-pledge')
                        setSelectedDrawer('all-rollover-companies')
                      }}>
                        Organisations with same pledge
                      </Link>
                    </div>
                    <div>
                      <Body semiBold={true} className="text-right">{meta.stats.count.rollover.same_pledge}</Body>
                    </div>
                  </Frow>
                </li>
              </>
            )}
          </ul>
        </Card>
        <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR} className="mar-b-3" style={{ maxHeight: '400px', overflowY: 'scroll' }}>
          <Subheading1 className="mar-b-2">Breakdown by sector</Subheading1>
          <ul className={Classes.LIST_TABULAR}>
            <li style={{ padding: '12px 0' }}>
              <Frow style={{ width: '100%' }} gutters={8}>
                <Col width="1-5">
                  <Body semiBold={true}>Sector</Body>
                </Col>
                <Col width="1-5">
                  <Body semiBold={true}>Previous pledges</Body>
                </Col>
                <Col width="1-5">
                  <Body semiBold={true}>{cycle.name} projected</Body>
                </Col>
                <Col width="1-5">
                  <Body semiBold={true}>{cycle.name} confirmed</Body>
                </Col>
                <Col width="1-5">
                  <Body semiBold={true}>Opt-out rate (%)</Body>
                </Col>
              </Frow>
            </li>
            {isWorkstreamLoading ? <LoadingSpinner /> : workstreamMeta.stats.count.pledges.map((pledge) => {
              return(
                <li style={{ padding: '12px 0' }} key={pledge.name}>
                  <Frow style={{ width: '100%' }} gutters={8}>
                    <Col width="1-5">
                      <Body muted={true}>{pledge.name}</Body>
                    </Col>
                    <Col width="1-5">
                      <Body muted={true}>{pledge.previous_pledges}</Body>
                    </Col>
                    <Col width="1-5">
                      <Body muted={true}>{pledge.previous_pledges}</Body>
                    </Col>
                    <Col width="1-5">
                      <Body muted={true}>{pledge.confirmed_pledges}</Body>
                    </Col>
                    <Col width="1-5">
                      <Body muted={true}>{((pledge.confirmed_pledges || 0) / (pledge.previous_pledges || 1) * 100)}%</Body>
                    </Col>
                  </Frow>
                </li>
              )
            })}
          </ul>
        </Card>
        <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
          <Subheading1>Super-league Organisations</Subheading1>
          <Body className="mar-t-1 mar-b-3">
            Organisations who pledged 5+ internships in the last cycle
          </Body>
          <ul className={Classes.LIST_TABULAR}>
            <li style={{ padding: '12px 0' }}>
              <Frow style={{ width: '100%' }} gutters={8}>
                <Col width="3-5">
                  <Frow flexWrap="nowrap" gutters={8}>
                    <div>
                      <Icon8 icon={IconNames8.BULLET_LARGE} style={{ fill: Colors.RED_50 }} />
                    </div>
                    <div>
                      <Link className={Classes.LINK} onClick={() => {
                        setSelectedTab('not-taking-part')
                        setSelectedDrawer('super-league')
                      }}>
                        Not taking part
                      </Link>
                    </div>
                  </Frow>
                </Col>
                <Col width="1-5">
                  <Body muted={true} className={isInitialLoading ? Classes.SKELETON : ''}>
                    {isInitialLoading ? '0' : meta.stats.count.superleague.not_taking_part.company_count} organisations
                  </Body>
                </Col>
                <Col width="1-5">
                  <Body muted={true} className={isInitialLoading ? Classes.SKELETON : ''}>
                    {isInitialLoading ? '0' : meta.stats.count.superleague.not_taking_part.internship_count} internships
                  </Body>
                </Col>
              </Frow>
            </li>
            <li style={{ padding: '12px 0' }}>
              <Frow style={{ width: '100%' }} gutters={8}>
                <Col width="3-5">
                  <Frow flexWrap="nowrap" gutters={8}>
                    <div>
                      <Icon8 icon={IconNames8.BULLET_LARGE} style={{ fill: Colors.GREEN_50 }} />
                    </div>
                    <div>
                      <Link className={Classes.LINK} onClick={() => {
                        setSelectedTab('signed-up')
                        setSelectedDrawer('super-league')
                      }}>
                        Already signed up
                      </Link>
                    </div>
                  </Frow>
                </Col>
                <Col width="1-5">
                  <Body muted={true} className={isInitialLoading ? Classes.SKELETON : ''}>
                    {isInitialLoading ? '0' : meta.stats.count.superleague.already_signed_up.company_count} organisations
                  </Body>
                </Col>
                <Col width="1-5">
                  <Body muted={true} className={isInitialLoading ? Classes.SKELETON : ''}>
                    {isInitialLoading ? '0' : meta.stats.count.superleague.already_signed_up.internship_count} internships
                  </Body>
                </Col>
              </Frow>
            </li>
            <li style={{ padding: '12px 0' }}>
              <Frow style={{ width: '100%' }} gutters={8}>
                <Col width="3-5">
                  <Frow flexWrap="nowrap" gutters={8}>
                    <div>
                      <Icon8 icon={IconNames8.BULLET_LARGE} style={{ fill: Colors.MARINE_50 }} />
                    </div>
                    <div>
                      <Link className={Classes.LINK} onClick={() => {
                        setSelectedTab('pending')
                        setSelectedDrawer('super-league')
                      }}>
                        Yet to roll over
                      </Link>
                    </div>
                  </Frow>
                </Col>
                <Col width="1-5">
                  <Body muted={true} className={isInitialLoading ? Classes.SKELETON : ''}>
                    {isInitialLoading ? '0' : meta.stats.count.superleague.yet_to_roll_over.company_count} organisations
                  </Body>
                </Col>
                <Col width="1-5">
                  <Body muted={true} className={isInitialLoading ? Classes.SKELETON : ''}>
                    {isInitialLoading ? '0' : meta.stats.count.superleague.yet_to_roll_over.internship_count} internships
                  </Body>
                </Col>
              </Frow>
            </li>
          </ul>
        </Card>
      </Card>
      {!!selectedDrawer && (
        <Drawer isOpen={true} onClose={handleDrawerClose} maxWidth={1100}>
          <DrawerBody {...{ cycle, programme, selectedDrawer, selectedTab }} handleClose={handleDrawerClose} />
        </Drawer>
      )}
    </>
  )
}

function DrawerBody({ cycle, handleClose, programme, selectedDrawer, selectedTab }) {
  if (selectedDrawer === 'all-rollover-companies') return <AllRolloverCompanies {...{ cycle, programme, handleClose, selectedTab }} />
  if (selectedDrawer === 'super-league') return <SuperleagueCompanies {...{ cycle, programme, handleClose, selectedTab }} />

  return null;
}
