import {DrawerHeader} from "../../../../shared/DrawerHeader";
import {Card, CardLevel, Classes, Divider, Link, PaddingLevel, Tab, Tabs} from "@pinpointhq/thumbtack";
import ReactTable from "react-table";
import * as React from "react";
import {Company} from "../../../../../javascript/models";
import {useSpraypaintTableNamedParams} from "../../../shared/hooks/useSpraypaintTable";
import NewPaginationComponent from "../../../shared/NewPaginationComponent";
import Search from "../../../../shared/Search";
import {adminProgrammeCompanyPath} from "../../../../../javascript/application/ts_routes";

export function AllRolloverCompanies({ cycle, handleClose, programme, selectedTab }) {
  const [selectedTabId, setSelectedTabId] = React.useState(selectedTab || 'all-companies')

  const handleTabChange = (tabId) => setSelectedTabId(tabId)

  return(
    <>
      <DrawerHeader title="Rollover organisations" handleClose={handleClose} />
      <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.NONE}>
        <Divider muted={true} />
        <Tabs
          className={[Classes.FOCUS_DISABLED, 'responsive-secondary-tabs'].join(' ')}
          id="all-rollover-companies"
          selectedTabId={selectedTabId} onChange={handleTabChange}
          muted={true}
          renderActiveTabPanelOnly={true}
        >
          <Tab panel={<AllCompanies {...{ cycle, programme }} />} id="all-companies">
            Rollover organisations from previous
          </Tab>
          <Tab panel={<IncreasedPledge {...{ cycle, programme }} />} id="increased-pledge">
            Increased pledge
          </Tab>
          <Tab panel={<DecreasedPledge {...{ cycle, programme }} />} id="decreased-pledge">
            Decreased pledge
          </Tab>
          <Tab panel={<SamePledge {...{ cycle, programme }} />} id="same-pledge">
            Same pledge
          </Tab>
        </Tabs>
      </Card>
    </>
  )
}

function AllCompanies({ cycle, programme }) {
  const scope = Company.where({ rollover_total: true })
    .extraParams({ cycle_id: cycle.id, programme_id: programme.id })
    .selectExtra(['current_pledge', 'previous_pledge'])


  return(
    <Table {...{ programme, scope }} />
  )
}

function IncreasedPledge({ cycle, programme }) {
  const scope = Company.where({ increased_pledge: true })
    .extraParams({ cycle_id: cycle.id, programme_id: programme.id })
    .selectExtra(['current_pledge', 'previous_pledge'])


  return(
    <Table {...{ programme, scope }} />
  )
}

function DecreasedPledge({ cycle, programme }) {
  const scope = Company.where({ decreased_pledge: true })
    .extraParams({ cycle_id: cycle.id, programme_id: programme.id })
    .selectExtra(['current_pledge', 'previous_pledge'])


  return(
    <Table {...{ programme, scope }} />
  )
}

function SamePledge({ cycle, programme }) {
  const scope = Company.where({ same_pledge: true })
    .extraParams({ cycle_id: cycle.id, programme_id: programme.id })
    .selectExtra(['current_pledge', 'previous_pledge'])


  return(
    <Table {...{ programme, scope }} />
  )
}

function Table({ programme, scope }) {

  const { tableProps, setQuery, isLoading } = useSpraypaintTableNamedParams({
    initialScope: scope,
    searchIdentifier: 'companies',
  })

  const columns = [
    {
      Cell: (row: any) => {
        return (
          <Link href={adminProgrammeCompanyPath(programme.id, row.original.id)}>{row.original.name}</Link>
        )
      },
      Header: 'Organisation',
      accessor: 'name',
    },
    {
      Header: 'Previous pledge',
      accessor: 'previousPledge',
      sortable: false
    },
    {
      Header: 'Current pledge',
      accessor: 'currentPledge',
      sortable: false
    },
    {
      Cell: (row: any) => {
        const change = (((row.original.currentPledge || 0) / (row.original.previousPledge || 1)) * 100)
        return (
          <div>{change === 0 ? 'n/a' : change === 1 ? 'No change' : `${change < 1 ? '-' : ''}${change.toFixed(1)}%`}</div>
        )
      },
      Header: '% of previous pledge',
      sortable: false,
    }
  ]

  const handleSearch = (searchTerm) => {
    setQuery({ search: searchTerm })
  }

  const paginationProps = React.useCallback(
    () => (tableProps),
    [tableProps],
  );

  return(
    <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.REGULAR}>
      <div className="mar-b-3">
        <Search handleSearch={handleSearch} />
      </div>
      <ReactTable
        {...tableProps}
        loading={isLoading}
        columns={columns}
        PaginationComponent={NewPaginationComponent}
        getPaginationProps={paginationProps}
        className="-unthemed"
        minRows={1}
      />
    </Card>
  )
}