import * as React from "react";
import { DebounceInput } from "react-debounce-input";

interface ISearchFieldProps {
  autoComplete?: string;
  canClear?: boolean;
  clear?: boolean;
  debounceTimeout?: number;
  id?: string;
  isSearching?: boolean;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
  width?: string;
  willUpdateUrl?: boolean;
  handleChange(searchTerm: string): void;
  handleFocus?(): void;
  handleKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;
}

export default function SearchField(props: ISearchFieldProps) {
  const {
    canClear = true,
    clear = false,
    debounceTimeout = 500,
    handleFocus,
    handleKeyDown,
    id,
    isSearching = false,
    name = "search",
    placeholder = "Search...",
    readOnly = false,
    width = "100%",
    willUpdateUrl,
  } = props;
  const [value, setValue] = React.useState<string>(props.value || "");
  const input = React.createRef<any>();

  React.useEffect(() => {
    updateParamState();
  }, []);

  React.useEffect(() => {
    if (clear) handleClear();
  }, [clear]);

  const updateParamState = () => {
    const params = new URLSearchParams(window.location.search);
    const searchParam = params.get("search");
    if (searchParam) handleChange(searchParam);
  };

  const onChange = (event: React.SyntheticEvent) => {
    handleChange((event.target as HTMLInputElement).value);
  };

  const handleChange = (searchTerm: string) => {
    setValue(searchTerm);
    props.handleChange(searchTerm);
  };

  const handleClear = () => {
    handleChange("");
  };

  const searchingIcon = (
    <i className="fas fa-circle-notch fa-spin search__right-icon" />
  );
  const clearIcon = (
    <span onClick={handleClear} style={{ cursor: "pointer" }}>
      <i className="fas fa-times-circle search__right-icon" />
    </span>
  );

  return (
    <div className="search" style={{ width }}>
      <i className="fal fa-search search__search-icon" />
      <DebounceInput
        className="search__input"
        debounceTimeout={debounceTimeout}
        element="input"
        id={id}
        inputRef={input}
        name={name}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        style={{ width }}
        type="text"
        {...{ onChange, value }}
        readOnly={readOnly}
      />
      {isSearching ? searchingIcon : canClear && value && clearIcon}
    </div>
  );
}
