import * as React from 'react';
import {visit} from 'turbolinks';
import {
  Frow,
  ISelectOption,
  RadioSelect,
} from "@pinpointhq/thumbtack";

interface ICycleSelect {
  baseUrl: string;
  cycle: { id: string };
  cycles: ISelectOption[];
}

export default function CycleSelect({ cycle, cycles, baseUrl }: ICycleSelect) {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const radios = cycles.map((cycleOption) => {
    const handleChange = () => {
      setIsSubmitting(true)
      visit(`${baseUrl}?cycle_id=${cycleOption.value}`)
    }

    return (
      <div className="col-flex-grow-1" key={cycleOption.value}>
        <RadioSelect
          disabled={isSubmitting}
          label={cycleOption.label}
          onChange={handleChange}
          checked={cycleOption.value === cycle.id}
        />
      </div>
    );
  })

  return(
    <Frow flexDirection="row" flexWrap="nowrap" justifyContent="center">
      {radios}
    </Frow>
  )
}