import * as React from 'react';
import * as $ from 'jquery';
import { Alerter, IconNames16, Intent, Menu, MenuItem, Popover, WorkflowTag } from "@pinpointhq/thumbtack";
import { createToast } from "../../../FlashToaster";
import { adminProgrammeCompaniesCyclePath } from "../../../../javascript/application/ts_routes";
import { getCsrfToken } from "../../../shared/csrfToken";
import { Position } from "@blueprintjs/core";
import useVisibility from "../../../shared/hooks/useVisibility";

export function Status({ cycle, fetchData, programmeId }) {
  const [activeStatus, setActiveStatus] = React.useState(cycle.withdrawn ? 'Withdrawn' : 'Active')
  const { isOpen: isStatusOpen, handleOpen: handleStatusOpen, handleClose: handleStatusClose } = useVisibility(false);

  React.useEffect(() => {
    if (cycle) setActiveStatus(cycle.withdrawn ? 'Withdrawn' : 'Active')
  }, [cycle])

  const statusIntent = () => {
    switch (activeStatus) {
      case 'Active':
        return Intent.SUCCESS;
      case 'Waitlist':
        return Intent.WARNING;
      case 'Withdrawn':
        return Intent.DANGER
    }
  }

  const handleActiveUpdate = () => {
    if (activeStatus === 'Active') return;

    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      intent: Intent.SUCCESS,
      onConfirm: () => {
        updateCycle({ withdrawn: false })
      },
      text: 'Are you sure you want to make the company active?',
    });
  }

  const handleWithdrawnUpdate = () => {
    if (activeStatus === 'Withdrawn') return;

    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, withdraw',
      intent: Intent.DANGER,
      onConfirm: () => {
        updateCycle({ withdrawn: true })
      },
      text: 'Are you sure you want to mark this company as withdrawn? Allocated candidates will be removed',
    });
  }

  const updateCycle = (attrs) => {
    createToast({ isLoading: true, type: 'success', text: 'Changing status', key: 'cycle-status' })
    $.ajax({
      url: adminProgrammeCompaniesCyclePath(programmeId, cycle.id),
      data: {
        authenticity_token: getCsrfToken(),
        companies_cycle: attrs
      },
      type: 'PATCH',
      dataType: 'json',
      success: () => {
        createToast({ type: 'success', text: 'Successfully changed status', key: 'cycle-status' });
        setActiveStatus(activeStatus === 'Active' ? 'Withdrawn' : 'Active');
        fetchData()
      },
    });
  }

  return(
    <Popover onOpening={handleStatusOpen} onClosing={handleStatusClose} position={Position.BOTTOM}>
      <WorkflowTag interactive={true} intent={statusIntent()} text={activeStatus} rightIcon={isStatusOpen ? IconNames16.CARET_UP : IconNames16.CARET_DOWN} />
      <Menu>
        <MenuItem text="Active" intent={Intent.SUCCESS} icon={IconNames16.TICK} onClick={handleActiveUpdate} />
        {/*<MenuItem text="Waitlist" intent={Intent.WARNING} icon={IconNames16.CALENDAR} />*/}
        <MenuItem text="Withdrawn" intent={Intent.DANGER} icon={IconNames16.CLOSE} onClick={handleWithdrawnUpdate} />
      </Menu>
    </Popover>
  )
}