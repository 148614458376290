import { Button, Callout, Dialog, FormGroup, Intent, ProgressBar, TextArea } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { AllocationRequest } from '../../../../javascript/models';
import { createToast } from '../../../FlashToaster';
import { promiseAllWithProgress } from '../../../shared/promiseAllWithProgress';

interface IRejectDialog {
  fetchData: () => void;
  handleClose: () => void;
  selection: AllocationRequest[];
  setSelection: (selection: AllocationRequest[]) => void;
}

export function RejectDialog({ fetchData, handleClose, selection, setSelection }: IRejectDialog) {
  const [reason, setReason] = React.useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const toastKey = 'rejectAllocationRequest';

  const handleSubmit = () => {
    setIsSubmitting(true);
    promiseAllWithProgress({
      callback: (progress) => displayProgressToast(progress),
      promises: selection.map((allocationRequest) => rejectAllocationRequest(allocationRequest)),
    })
      .then(() => {
        displaySuccessToast();
        setSelection([]);
        fetchData();
        handleClose();
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  function displaySuccessToast() {
    setTimeout(() => {
      createToast({
        key: toastKey,
        text: 'Successfully updated requests',
        type: 'success',
      });
    }, 1000);
  }

  async function rejectAllocationRequest(allocationRequest: AllocationRequest) {
    allocationRequest.status = 'rejected';
    allocationRequest.rejectionReason = reason;
    await allocationRequest.save();
  }

  function displayProgressToast(progress) {
    const toastContent = (
      <>
        Updating allocation requests {progress.complete} of {progress.total}
        <div style={{ marginTop: '4px' }}>
          <ProgressBar value={progress.percentage / 100} intent={Intent.NONE} />
        </div>
      </>
    );

    createToast({
      isLoading: true,
      key: toastKey,
      text: toastContent,
    });
  }

  return (
    <Dialog title="Reject request" isOpen={true} onClose={handleClose}>
      <Callout intent={Intent.PRIMARY} className="mar-b-3">
        The reason you are rejecting this allocation request will be sent to the primary contact in an email
      </Callout>
      <FormGroup label="Reason for rejection">
        <TextArea value={reason} onChange={handleReasonChange} />
      </FormGroup>
      <div className="mar-t-2">
        <Button
          onClick={handleSubmit}
          text="Reject"
          large={true}
          fill={true}
          disabled={!reason}
          loading={isSubmitting}
        />
      </div>
    </Dialog>
  );
}
