import * as React from 'react';
import PaginationComponent from "../../candidates/awaiting/PaginationComponent";
import ReactTable from "react-table";
import {
  IconButton40,
  IconNames16,
  Menu,
  MenuItem,
  Popover,
  Subheading1
} from "@pinpointhq/thumbtack";
import {AllocateDialog} from "../../shared/AllocateDialog";

export function Jobs({ jobs }) {
  const [selectedJobId, setSelectedJobId] = React.useState()

  const columns = [
    {
      Cell: (row: any) => {
        return (
          <div>{row.original.workstream}</div>
        )
      },
      Header: 'Workstream',
      sortable: false,
    },
    {
      Cell: (row: any) => {
        return (
          <div>{row.original.region}</div>
        )
      },
      Header: 'Region',
      sortable: false,
      width: 120
    },
    {
      Cell: (row: any) => {
        return (
          <div>{row.original.headcount}</div>
        )
      },
      Header: 'Headcount',
      sortable: false,
      width: 120
    },
    {
      Cell: (row: any) => {
        return (
          <div>{row.original.applications_count}</div>
        )
      },
      Header: 'Applications',
      sortable: false,
      width: 140
    },
    {
      Cell: (row: any) => {
        const handleClick = () => setSelectedJobId(row.original.id)

        return(
          <div>
            <Popover>
              <IconButton40 icon={IconNames16.OVERFLOW} />
              <Menu>
                <MenuItem text="Allocate" onClick={handleClick} />
              </Menu>
            </Popover>
          </div>
        )
      },
      Header: '',
      sortable: false,
      width: 80
    }
  ]

  const handleClose = () => setSelectedJobId(null)

  const handleSuccess = () => window.location.reload()

  return(
    <>
      <Subheading1 className="mar-b-3">Jobs</Subheading1>
      <ReactTable
        columns={columns}
        data={jobs}
        PaginationComponent={PaginationComponent}
        className="-unthemed"
        minRows={1}
      />
      {selectedJobId && <AllocateDialog isOpen={!!selectedJobId} jobId={selectedJobId} handleClose={handleClose} handleSuccess={handleSuccess} />}
    </>
  )
}