import * as React from 'react';
import {
  Card,
  CardLevel,
  Frow,
  Heading2,
  Intent,
  PaddingLevel,
  Select,
  Spinner,
  Tab,
  Tabs,
  Tag
} from "@pinpointhq/thumbtack";
import {Pledge} from "./programmeDetails/Pledge";
import {Jobs} from "./programmeDetails/Jobs";
import {CompanyComments} from "./programmeDetails/CompanyComments";
import {Submissions} from "./programmeDetails/Submissions";
import {Timelines} from "./programmeDetails/Timelines";
import {Status} from "../../../companies/show/Status";

export function ProgrammeDetails({ company, defaultCycleId, fetchData, cycleOptions, isLoading, programmeId, programmeTab, userId, workstreamOptions }) {
  const [selectedTabId, setSelectedTabId] = React.useState('pledge')
  const [cycleId, setCycleId] = React.useState(defaultCycleId)

  const handleTabChange = (tabId) => setSelectedTabId(tabId)

  const tabs = [
    {
      id: 'pledge',
      label: 'Pledge',
    },
    {
      id: 'timelines',
      label: 'Timelines',
    },
    {
      id: 'jobs',
      label: 'Jobs',
    },
    {
      id: 'submissions',
      label: 'Submissions',
    },
    {
      id: 'comments',
      label: 'Comments',
    }
  ]

  const components = {
    pledge: Pledge,
    jobs: Jobs,
    comments: CompanyComments,
    submissions: Submissions,
    timelines: Timelines
  };

  const Component = components[selectedTabId];

  const activeCycle = company ?
    company.companiesCycles.find((companyCycle) => companyCycle.cycleId === cycleId && companyCycle.programmeId === programmeId) :
    null;
  const activeCycleOption = cycleOptions.find((option) => option.value === cycleId)

  const handleCycleChange = (selectedOption) => {
    setCycleId(selectedOption.value)
  }

  const pledgeStatusTag = {
    met: 'Pledge met',
    not_met: 'Pledge not met',
    exceeded: 'Pledge exceeded',
    not_applicable: 'N/A',
    no_current_pledge: 'No pledge'
  }
  const pledgeIntent = {
    met: Intent.SUCCESS,
    not_met: Intent.WARNING,
    exceeded: Intent.SUCCESS,
    not_applicable: Intent.NONE,
    no_current_pledge: Intent.WARNING
  }

  return(
    <>
      <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.EXPANDED} cardInnerProps={{ style: { padding: '24px 40px' } }}>
        <Frow alignItems="center" justifyContent="space-between" gutters={16} verticalGutters={16}>
          <div>
            <Frow gutters={16} alignItems="center">
              <div>
                <Heading2 className="mar-b-0">{programmeTab.label}</Heading2>
              </div>
              {activeCycle && (
                <div>
                  <Tag intent={pledgeIntent[activeCycle.pledgeStatus]}>{pledgeStatusTag[activeCycle.pledgeStatus]}</Tag>
                </div>
              )}
            </Frow>
          </div>
          <div>
            <Frow gutters={16} verticalGutters={16} alignItems="center">
              <div>
                <Select
                  activeItem={activeCycleOption}
                  items={cycleOptions}
                  fill={true}
                  buttonProps={{ large: true }}
                  onItemSelect={handleCycleChange}
                />
              </div>
              {activeCycle && (
                <div>
                  <Status cycle={activeCycle} {...{ fetchData, programmeId }} />
                </div>
              )}
            </Frow>
          </div>
        </Frow>
      </Card>
      <Frow md={{ flexWrap: 'nowrap' }}>
        <Tabs
          className="responsive-secondary-tabs"
          id="template-options"
          vertical={true}
          muted={true}
          selectedTabId={selectedTabId}
          onChange={handleTabChange}
        >
          {tabs.map((tab) => {
            return(
              <Tab id={tab.id} key={tab.id}>
                {tab.label}
              </Tab>
            )
          })}
        </Tabs>
        <div style={{ flexGrow: 1 }}>
          <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.EXPANDED}>
            {isLoading ? <Spinner /> : <Component {...{ activeCycle, company, fetchData, userId, workstreamOptions }} />}
          </Card>
        </div>
      </Frow>
    </>
  )
}