import { Button, Callout, Dialog, FormGroup, Intent, TextArea } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { BulkReleaseRequest } from '../../../javascript/models';
import { createToast } from '../../FlashToaster';

interface IRejectDialog {
  fetchData: () => void;
  handleClose: () => void;
  bulkReleaseRequest: BulkReleaseRequest;
}

export function RejectDialog({ fetchData, handleClose, bulkReleaseRequest }: IRejectDialog) {
  const [reason, setReason] = React.useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const toastKey = 'rejectAllocationRequest';

  const handleSubmit = () => {
    bulkReleaseRequest.status = 'rejected';
    bulkReleaseRequest.response = reason;
    setIsSubmitting(true);
    bulkReleaseRequest.save().then((success) => {
      if (success) {
        createToast({ type: 'success', text: 'Successfully rejected request', key: toastKey });
        fetchData();
        setIsSubmitting(false);
        handleClose();
      } else {
        setIsSubmitting(false);
        createToast({ type: 'error', text: 'Unable to rejected request', key: toastKey });
      }
    });
  };

  return (
    <Dialog title="Reject request" isOpen={true} onClose={handleClose}>
      <Callout intent={Intent.PRIMARY} className="mar-b-3">
        The reason you are rejecting this release request will be sent to the primary contact in an email
      </Callout>
      <FormGroup label="Reason for rejection">
        <TextArea value={reason} onChange={handleReasonChange} />
      </FormGroup>
      <div className="mar-t-2">
        <Button
          onClick={handleSubmit}
          text="Reject"
          large={true}
          fill={true}
          disabled={!reason}
          loading={isSubmitting}
        />
      </div>
    </Dialog>
  );
}
