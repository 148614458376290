import * as React from 'react';
import { Icon16, Intent, intentColor, intentIcon, Spinner, Toaster } from '@pinpointhq/thumbtack';
import { Classes, Position } from '@blueprintjs/core';

type FlashMessageType = 'alert' | 'error' | 'notice' | 'success';

/**
 * Takes a type and text
 */
export interface IFlashMessage {
  isLoading?: boolean;
  key?: string;
  type?: FlashMessageType;
  text: React.ReactNode;
  timeout?: number;
}

interface IFlashToaster {
  flashMessages: IFlashMessage[];
}

export function createToast(toast: IFlashMessage) {
  const customEvent = new CustomEvent<IFlashMessage>('toasts:create', {
    detail: toast,
  });
  document.dispatchEvent(customEvent);
}

export function dismissToast(key: string) {
  const customEvent = new CustomEvent<string>('toasts:dismiss', {
    detail: key,
  });
  document.dispatchEvent(customEvent);
}

export function FlashToaster({ flashMessages }: IFlashToaster) {
  const toastIntent: Record<FlashMessageType, Intent> = {
    alert: Intent.WARNING,
    error: Intent.DANGER,
    notice: Intent.PRIMARY,
    success: Intent.SUCCESS,
  };
  const toasterRef = React.useRef<Toaster>();

  const toastEventListener = (e: CustomEvent<IFlashMessage>) => showToast(e.detail);

  React.useEffect(() => {
    document.addEventListener('toasts:create', toastEventListener);
    return () => {
      document.removeEventListener('toasts:create', toastEventListener);
    };
  }, []);

  function showToast({ isLoading, key, type, text, timeout }: IFlashMessage) {
    const intent = toastIntent[type] || Intent.NONE;
    const icon = isLoading ? <Spinner small={true} /> : <Icon16 icon={intentIcon(intent)} fill={intentColor(intent)} />;
    toasterRef.current.show(
      {
        icon,
        intent,
        message: text,
        timeout: timeout ? timeout : isLoading ? 0 : 8000,
      },
      key,
    );
  }

  const dismissToastEventListener = (_event: CustomEvent<string>) => ({ detail: key }) => {
    toasterRef.current.dismiss(key);
  };

  React.useEffect(() => {
    document.addEventListener('toasts:dismiss', dismissToastEventListener);
    return () => {
      document.removeEventListener('toasts:dismiss', dismissToastEventListener);
    };
  }, []);

  React.useEffect(() => {
    flashMessages.forEach((flashMessage) => showToast(flashMessage));
  }, []);

  // If we use a portal we don't have a way of setting the portal container so we run into
  // turbolinks issues
  return <Toaster ref={toasterRef} className={Classes.DARK} position={Position.BOTTOM} usePortal={false} />;
}

export default FlashToaster;
