// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

/* --------------------------------------------------
  Styles
-------------------------------------------------- */
import "../stylesheets/application";

/* --------------------------------------------------
  Images - so we're adding them to the pack manifest
-------------------------------------------------- */
require.context("../images/", true, /\.(gif|jpg|png|svg)$/i);

/* --------------------------------------------------
  // Isomorphic Fetch - to ensure that fetch works in IE11
  // https://github.com/matthew-andrews/isomorphic-fetch
-------------------------------------------------- */
import 'isomorphic-fetch';