import {Card, CardLevel, Drawer, Link, PaddingLevel} from "@pinpointhq/thumbtack";
import * as React from "react";
import {DrawerHeader} from "../../../shared/DrawerHeader";
import {useSpraypaintTableNamedParams} from "../../shared/hooks/useSpraypaintTable";
import {Company} from "../../../../javascript/models";
import {adminProgrammeCompanyPath} from "../../../../javascript/application/ts_routes";
import ReactTable from "react-table";
import NewPaginationComponent from "../../shared/NewPaginationComponent";

export function ProgressTable({ cycle, handleClose, selectedStatus, programme }) {

  const { tableProps } = useSpraypaintTableNamedParams({
    initialScope: Company.where({ cycle_progress: { eq: [selectedStatus.key] } }).selectExtra(['current_pledge', 'previous_hires']).extraParams({ cycle_id: cycle.id, programme_id: programme.id }),
    searchIdentifier: 'progress-table'
  })

  const columns = [
    {
      Cell: (row: any) => {
        return (
          <Link href={adminProgrammeCompanyPath(programme.id, row.original.id)}>{row.original.name}</Link>
        )
      },
      Header: 'Organisation',
      accessor: 'name',
    },
    {
      Header: 'Worksteam',
      accessor: 'workstream',
      sortable: false,
    },
    {
      Header: 'Current pledge',
      accessor: 'currentPledge',
      sortable: false
    },
  ]

  const paginationProps = React.useCallback(
    () => (tableProps),
    [tableProps],
  );

  return(
    <Drawer isOpen={true} onClose={handleClose} maxWidth={1000}>
      <DrawerHeader title={selectedStatus.label} handleClose={handleClose} />
      <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.REGULAR}>
        <ReactTable
          {...tableProps}
          columns={columns}
          PaginationComponent={NewPaginationComponent}
          getPaginationProps={paginationProps}
          className="-unthemed"
          minRows={1}
        />
      </Card>
    </Drawer>
  )
}