import { Heading2 } from '@pinpointhq/thumbtack';
import { flatten } from 'lodash';
import * as React from 'react';
import ReactTable from 'react-table';
import { Company, Cycle } from '../../../../javascript/models';
import PaginationComponent from '../../candidates/awaiting/PaginationComponent';

export function PledgeTable({ company, cycles }: { company: Company; cycles: Cycle[] }) {
  const tableData = company.allocationInformation;

  const columns = flatten([
    {
      Header: '',
      accessor: 'statName',
    },
    cycles.map((cycle) => {
      return {
        Header: cycle.name,
        accessor: cycle.id,
      };
    }),
  ]);

  return (
    <div>
      <Heading2>Pledge Table</Heading2>
      <ReactTable
        columns={columns}
        data={tableData}
        PaginationComponent={PaginationComponent}
        loading={false}
        className="-unthemed"
        minRows={1}
        pageSize={10}
      />
    </div>
  );
}
