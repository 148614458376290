import * as React from 'react';
import {Body, Classes, Empty, Heading2} from "@pinpointhq/thumbtack";
import {CompaniesCycle} from "../../../../../../javascript/models";
import {CenteredContent} from "../../../../../shared/CenteredContent";

export function Pledge({ activeCycle }: { activeCycle: CompaniesCycle }) {
  if (!activeCycle) {
    return(
      <CenteredContent maxWidth={1200}>
        <Heading2 className="mar-b-3">Pledge</Heading2>
        <Empty text="No pledge information for the selected cycle" />
      </CenteredContent>
    )
  }

  const { companyCycleInformation } = activeCycle

  return(
    <CenteredContent maxWidth={1200}>
      <Heading2 className="mar-b-3">Pledge</Heading2>
      <ul className={Classes.LIST_TABULAR}>
        <li>
          <Body semiBold={true}>Initial Pledge</Body>
          <Body>{companyCycleInformation.initialPledge}</Body>
        </li>
        <li>
          <Body semiBold={true}>Current Pledge</Body>
          <Body>{companyCycleInformation.currentPledge}</Body>
        </li>
        <li>
          <Body semiBold={true}>No. of roles in Pinpoint</Body>
          <Body>{companyCycleInformation.jobsCount}</Body>
        </li>
        <li>
          <Body semiBold={true}>Offers extended</Body>
          <Body>{companyCycleInformation.offersExtended}</Body>
        </li>
        <li>
          <Body semiBold={true}>Hires made</Body>
          <Body>{companyCycleInformation.numberOfHires}</Body>
        </li>
      </ul>
    </CenteredContent>
  )
}
