import * as React from 'react';
import * as $ from 'jquery';
import useSpraypaintFind from "../../shared/hooks/useSpraypaintFind";
import {CompaniesCycle, Company, Contact} from "../../../../javascript/models";
import {visit} from 'turbolinks';
import {
  Alerter,
  AnchorButton,
  Body,
  Button,
  Card,
  CardLevel,
  Classes,
  Col,
  Colors,
  Dialog,
  FormGroup,
  Frow,
  Heading2,
  Icon16,
  IconNames16,
  Intent,
  ISelectOption,
  MultiSelect,
  PaddingLevel,
  Size,
  Tab,
  Tabs,
  Tag,
  TextArea, Tooltip
} from "@pinpointhq/thumbtack";
import {Contacts} from "./show/Contacts";
import {ProgrammeDetails} from "./show/ProgrammeDetails";
import MergeCompanies from "../../companies/MergeCompanies";
import {createToast} from "../../../FlashToaster";
import {adminCompanyPath, adminRootPath} from "../../../../javascript/application/ts_routes";
import {getCsrfToken} from "../../../shared/csrfToken";
import useVisibility from "../../../shared/hooks/useVisibility";

interface IShow {
  companyId: string;
  cycleId: string;
  cycleOptions: ISelectOption[]
  companyName: string;
  defaultTab: string;
  programmeTabs: { label: string, disabled: boolean, value: string }[];
  programmes: { name: string; id: string }[]
  userId: string;
  currentProgrammeId: string;
  workstreamOptions: ISelectOption[];
}

export default function Show({ companyId, companyName, cycleId, cycleOptions, defaultTab, programmeTabs, programmes, userId, currentProgrammeId, workstreamOptions }: IShow) {
  const [selectedTabId, setSelectedTabId] = React.useState<string>(defaultTab || 'contacts')
  const [mergeDialogOpen, setMergeDialogOpen] = React.useState()
  const { isOpen: isFavouriteOpen, handleOpen: handleFavouriteOpen, handleClose: handleFavouriteClose } = useVisibility(false);
  const { isOpen: isBlacklistOpen, handleOpen: handleBlacklistOpen, handleClose: handleBlacklistClose } = useVisibility(false);
  const { isOpen: isRedFlaggedOpen, handleOpen: handleRedFlagOpen, handleClose: handleRedFlagClose } = useVisibility(false);

  const [favouriteReason, setFavouriteReason] = React.useState<string[]>([])
  const [favouriteAdditionalReason, setFavouriteAdditionalReason] = React.useState<string>()

  const [blacklistReason, setBlacklistReason] = React.useState<string[]>([])
  const [blacklistAdditionalReason, setBlacklistAdditionalReason] = React.useState<string>()

  const [redFlaggedReason, setRedFlagReason] = React.useState<string[]>([])
  const [redFlaggedAdditionalReason, setRedFlaggedAdditionalReason] = React.useState<string>()

  const handleTabChange = (newSelectedTabId: string) => {
    setSelectedTabId(newSelectedTabId)
  }

  const contactScope = Contact.selectExtra(['programme_names', 'programme_ids'])
  const companiesCycleScope = CompaniesCycle.selectExtra(['cycle_name', 'pledge_status'])
  const { data: company, isLoading, fetchData } = useSpraypaintFind(
    Company
      .includes(
        [
          'contacts',
          { companies_cycles: ['company_cycle_information', 'company_submission', 'company_timeline', { company_submission: ['saved_internships'] }] },
          'jobs']
      ).merge({ contact: contactScope, companies_cycles: companiesCycleScope }),
    companyId
  )

  const handleMergeClick = () => setMergeDialogOpen(true)
  const handleMergeClose = () => setMergeDialogOpen(false)

  const handleDeleteClick = () => {
    Alerter.create({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, delete',
      intent: Intent.DANGER,
      onConfirm: () => {
        $.ajax({
          url: adminCompanyPath(company.id),
          data: { authenticity_token: getCsrfToken() },
          type: 'DELETE',
          dataType: 'json',
          success: () => {
            createToast({ text: 'Company deleted', type: 'success' })
            visit(adminRootPath())
          },
        });
      },
      text: 'Are you sure you want to delete the company? You cannot undo this action.',
    });
  }

  const handleFavouriteReason = (items) => {
    setFavouriteReason(items.map((item) => item.value))
  }

  const handleFavouriteAdditionalReasonChange = (event) => {
    setFavouriteAdditionalReason(event.currentTarget.value)
  }

  const handleBlackReason = (items) => {
    setBlacklistReason(items.map((item) => item.value))
  }

  const handleBlacklistAdditionalReasonChange = (event) => {
    setBlacklistAdditionalReason(event.currentTarget.value)
  }

  const handleRedFlagReason = (items) => {
    setRedFlagReason(items.map((item) => item.value))
  }

  const handleRedFlagAdditionalReasonChange = (event) => {
    setRedFlaggedAdditionalReason(event.currentTarget.value)
  }

  const otherReasonText = 'Please explain your selection or if you have other reasons'

  const favouriteOptions = [
    { label: 'Completed hiring ahead of 10KBI/AI timeline', value: 'Completed hiring ahead of 10KBI/AI timeline' },
    { label: 'Hired above pledge', value: 'Hired above pledge' },
    { label: 'Cooperative', value: 'Cooperative' },
    { label: 'Donor', value: 'Donor' },
    { label: 'Supported training', value: 'Supported training' },
    { label: 'Supported training', value: 'Supported training' },
    { label: otherReasonText, value: otherReasonText }
  ]

  const redFlagOptions = [
    { label: 'Repeated under-hiring', value: 'Repeated under-hiring' },
    { label: 'Repeated zero hiring', value: 'Repeated zero hiring' },
    { label: 'Unresponsive/Uncooperative', value: 'Unresponsive/Uncooperative' },
    { label: otherReasonText, value: otherReasonText }
  ]

  const blackListOptions = [
    { label: 'Offering unpaid internships', value: 'Offering unpaid internships' },
    { label: 'Intern mistreatment', value: 'Intern mistreatment' },
    { label: otherReasonText, value: otherReasonText }
  ]

  const formattedReason = (reasons, additionalReasons) => {
    let reasonString = reasons.join(', ')
    if (additionalReasons) {
      reasonString += `. Additional comments: ${additionalReasons}`
    }
    return reasonString
  }

  const handleFavouriteSave = () => {
    company.favourite = true;
    company.favouritedAt = new Date();
    company.favouritedById = userId;
    company.favouriteReason = formattedReason(favouriteReason, favouriteAdditionalReason)
    company.save().then((success) => {
      createToast({ type: 'success', text: 'Marked organisation as favourite' })
      fetchData();
      handleFavouriteClose()
    })
  }

  const handleBlacklistSave = () => {
    company.blacklisted = true;
    company.blacklistedAt = new Date();
    company.blacklistedById = userId;
    company.blacklistReason = formattedReason(blacklistReason, blacklistAdditionalReason)
    company.save().then((success) => {
      createToast({ type: 'success', text: 'Marked organisation as blocklisted' })
      fetchData();
      handleBlacklistClose()
    })
  }

  const handleRedFlagSave = () => {
    company.redFlagged = true;
    company.redFlaggedAt = new Date();
    company.redFlaggedById = userId;
    company.redFlagReason = formattedReason(redFlaggedReason, redFlaggedAdditionalReason)
    company.save().then((success) => {
      createToast({ type: 'success', text: 'Red flagged organisation' })
      fetchData();
      handleRedFlagClose()
    })
  }

  const favouriteValid = favouriteReason.length > 0 && (!favouriteReason.includes(otherReasonText) || !!favouriteAdditionalReason)
  const blacklistValid = blacklistReason.length > 0  && (!blacklistReason.includes(otherReasonText) || !!blacklistAdditionalReason)
  const redFlagValid = redFlaggedReason.length > 0 && (!redFlaggedReason.includes(otherReasonText) || !!redFlaggedAdditionalReason)

  const selectedFavouriteOptions = favouriteOptions.filter((option) => favouriteReason.includes(option.value))
  const selectedRedFlagOptions = redFlagOptions.filter((option) => redFlaggedReason.includes(option.value))
  const selectedBlackListOptions = blackListOptions.filter((option) => blacklistReason.includes(option.value))

  return(
    <>
      <div className="mar-t-1 mar-b-5">
        <Frow justifyContent="space-between" alignItems="center" gutters={16} verticalGutters={16}>
          <div>
            <Frow alignItems="center" gutters={8} verticalGutters={8}>
              <div>
                <Heading2 className="mar-b-0">{companyName}</Heading2>
              </div>
              {company && company.clickupId && (<div>
                <Tooltip content="Clickups link">
                  <AnchorButton minimal={true} icon={IconNames16.LINK} href={`https://app.clickup.com/t/${company.clickupId}`} target="_blank" />
                </Tooltip>
              </div>)}
            </Frow>
          </div>
          <div>
            <Frow gutters={16} alignItems="center">
              <div>
                {company && company.favourite ? (
                  <Tag intent={Intent.SUCCESS}>Favourite</Tag>
                ) : (
                  <Button text="Favourite" icon={IconNames16.HEART} minimal={true} onClick={handleFavouriteOpen} />
                )}
              </div>
              <div>
                {company && company.redFlagged ? (
                  <Tag intent={Intent.WARNING}>Red Flag</Tag>
                ) : (
                  <Button text="Red Flag" icon={IconNames16.ERROR} minimal={true} onClick={handleRedFlagOpen} />
                )}
              </div>
              <div>
                {company && company.blacklisted ? (
                  <Tag intent={Intent.DANGER}>Blocklisted</Tag>
                ) : (
                  <Button text="Blocklist" icon={IconNames16.CLOSE} minimal={true} onClick={handleBlacklistOpen} />
                )}
              </div>
              <div>
                <Button icon={<Icon16 icon={IconNames16.REDO} style={{ fill: Colors.WHITE }} />} text="Merge" onClick={handleMergeClick} />
              </div>
              <div>
                <Button icon={IconNames16.DELETE} intent={Intent.DANGER} onClick={handleDeleteClick}>Delete</Button>
              </div>
            </Frow>
          </div>
        </Frow>
      </div>
      <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.NONE}>
        <Tabs id="company-primary-nav" selectedTabId={selectedTabId} onChange={handleTabChange}>
          <Tab id="contacts" panel={<Contacts {...{ company, fetchData, isLoading, cycleId, programmes }} />}>
            Contacts
          </Tab>
          {programmeTabs.map((programmeTab) => {
            return(
              <Tab
                key={programmeTab.label}
                id={programmeTab.value}
                style={{ cursor: 'white' }}
                panel={<ProgrammeDetails {...{ company, cycleOptions, fetchData, isLoading, programmeTab, userId, workstreamOptions }} programmeId={selectedTabId} defaultCycleId={cycleId} />}>
                {programmeTab.label}
              </Tab>
            )
          })}
          <div className={Classes.FLEX_EXPANDER} style={{ backgroundColor: Colors.SAGE_50 }} />
        </Tabs>
      </Card>
      {mergeDialogOpen && <MergeCompanies handleClose={handleMergeClose} company={company} currentProgrammeId={currentProgrammeId} />}
      {isFavouriteOpen && (
        <Dialog size={Size.SMALL} title="Favourite Organisation" isOpen={isFavouriteOpen} onClose={handleFavouriteClose}>
          <Body>You are favouriting this organisation, please select a reason for doing this. This reason will be added to the comments section of the organisation profile.</Body>
          <FormGroup label="Reason" className="mar-v-4">
            <MultiSelect
              handleChange={handleFavouriteReason}
              items={favouriteOptions}
              fill={true}
              condensed={true}
              selectedItems={selectedFavouriteOptions}
            />
          </FormGroup>
          {favouriteReason.includes(otherReasonText) && (
            <FormGroup label="Additional Reason" className="mar-v-4">
              <TextArea onChange={handleFavouriteAdditionalReasonChange} />
            </FormGroup>
          )}
          <Frow>
            <Col>
              <Button text="Favourite" fill={true} disabled={!favouriteValid} onClick={handleFavouriteSave} />
            </Col>
            <Col>
              <Button text="Cancel" fill={true} minimal={true} onClick={handleFavouriteClose} />
            </Col>
          </Frow>
        </Dialog>
      )}
      {isBlacklistOpen && (
        <Dialog size={Size.SMALL} title="Blocklist Organisation" isOpen={isBlacklistOpen} onClose={handleBlacklistClose}>
          <Body>You are blocklisting this organisation, please provide a reason for doing this. This reason will be added to the comments section of the organisation profile.</Body>
          <FormGroup label="Reason" className="mar-v-4">
            <MultiSelect
              handleChange={handleBlackReason}
              items={blackListOptions}
              fill={true}
              condensed={true}
              selectedItems={selectedBlackListOptions}
            />
          </FormGroup>
          {blacklistReason.includes(otherReasonText) && (
            <FormGroup label="Additional Reason" className="mar-v-4">
              <TextArea onChange={handleBlacklistAdditionalReasonChange} />
            </FormGroup>
          )}
          <Frow>
            <Col>
              <Button intent={Intent.DANGER} text="Blocklist" fill={true} disabled={!blacklistValid} onClick={handleBlacklistSave} />
            </Col>
            <Col>
              <Button text="Cancel" fill={true} minimal={true} onClick={handleBlacklistClose} />
            </Col>
          </Frow>
        </Dialog>
      )}
      {isRedFlaggedOpen && (
        <Dialog size={Size.SMALL} title="Red flag Organisation" isOpen={isRedFlaggedOpen} onClose={handleRedFlagClose}>
          <Body>You are red flagging this organisation, please provide a reason for doing this. This reason will be added to the comments section of the organisation profile.</Body>
          <FormGroup label="Reason" className="mar-v-4">
            <MultiSelect
              handleChange={handleRedFlagReason}
              items={redFlagOptions}
              fill={true}
              condensed={true}
              selectedItems={selectedRedFlagOptions}
            />
          </FormGroup>
          {redFlaggedReason.includes(otherReasonText) && (
            <FormGroup label="Additional Reason" className="mar-v-4">
              <TextArea onChange={handleRedFlagAdditionalReasonChange} />
            </FormGroup>
          )}
          <Frow>
            <Col>
              <Button intent={Intent.NONE} text="Red flag" fill={true} disabled={!redFlagValid} onClick={handleRedFlagSave} />
            </Col>
            <Col>
              <Button text="Cancel" fill={true} minimal={true} onClick={handleRedFlagClose} />
            </Col>
          </Frow>
        </Dialog>
      )}
    </>
  )
}