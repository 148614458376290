import * as React from 'react';

import { round } from 'lodash';
import { ChartDataSets } from 'chart.js';
import { ChartData, Doughnut } from 'react-chartjs-2';

import {
  Body,
  Card,
  CardLevel,
  Empty,
  IconNames16,
  IconButton40,
  Menu,
  Popover,
  Subheading1,
  ICardProps,
  Spinner,
  ISpinnerProps,
} from '@pinpointhq/thumbtack';

import useCustomEventEffect from '../../../shared/hooks/useCustomEventEffect';

interface ICandidatesByPreference {
  fetchUrl: string;
  preference: 'one' | 'two' | 'three' | 'four';
  insightDescription: string;
}

export default function CandidatesByPreference(props: ICandidatesByPreference) {
  const { fetchUrl, preference, insightDescription } = props;
  const [data, setData] = React.useState<any>([]);
  const [errors, setErrors] = React.useState<any>();
  const [isLoaded, setIsLoaded] = React.useState(false);
  useCustomEventEffect('dashboard-filter-change', (event) => fetchData(event.detail));

  React.useEffect(() => {
    fetchData();
  }, []);

  async function fetchData(args = { cycleId: null, allocationType: null }) {
    const { allocationType, cycleId } = args;
    let url = `${fetchUrl}?`
    // const url = cycleId ? `${fetchUrl}?cycle_id=${cycleId}` : fetchUrl
    if (cycleId) {
      url = `${url}cycle_id=${cycleId}&`
    }
    if (allocationType) {
      url = `${url}allocation_type=${allocationType}`
    }
    await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.errors) {
          setErrors(json.errors);
        } else {
          setErrors(null);
          setData(json);
        }
      })
      .then(() => {
        setIsLoaded(true);
      });
  }

  return (
    <>
      <InsightsCard
        level={CardLevel.RAISED}
        subheading={`Workstreams by Preference ${preference}`}
        description={insightDescription}
      >
        <Card level={CardLevel.FILLED} condensed={true}>
          <CardListWithChart
            {...{ array: data, isLoading: !isLoaded }}
            placeholderText="There are currently no candidates"
          />
        </Card>
      </InsightsCard>
    </>
  );
}

interface ICard extends ICardProps {
  subheading?: string | JSX.Element;
  description?: string;
  menuItems?: React.ReactNode;
}

function CardHeader({ subheading, description, menuItems }) {
  if (!subheading && !description && !menuItems) return null;

  return (
    <div className="mar-b-2">
      <div className="frow frow--items-start frow--justify-between frow--nowrap frow--items-center">
        <div className="col-flex-grow-1">
          {subheading && <Subheading1>{subheading}</Subheading1>}
          {description && (
            <Body muted={true} className="mar-t-1">
              {description}
            </Body>
          )}
        </div>
        <div>
          {menuItems ? (
            <Popover>
              <IconButton40 icon={IconNames16.OVERFLOW} />
              <Menu>{menuItems}</Menu>
            </Popover>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function InsightsCard({ children, description, menuItems, subheading, ...cardProps }: ICard) {
  return (
    <Card {...cardProps}>
      <CardHeader {...{ description, menuItems, subheading }} />
      {children}
    </Card>
  );
}

interface ICardListWithChartProps {
  array: { key?: string; value: number; label: string; handleClick?: () => any }[];
  colorMap?: { [key: string]: string };
  isLoading: boolean;
  placeholderText?: string;
}

export function CardListWithChart(props: ICardListWithChartProps) {
  const { array, colorMap, isLoading, placeholderText } = props;

  if (isLoading) return <LoadingSpinner />;

  if (!array || array.length === 0) {
    return <Empty text={placeholderText} />;
  }

  const total = array.reduce((sum, item) => sum + item.value, 0);
  const items = colorMap
    ? array.map((item, index) => {
        const percentage = total ? round((item.value / total) * 100, 1) : 0;
        return <CardListWithChartItem key={index} {...item} color={colorMap[item.key]} percentage={percentage} />;
      })
    : array.map((item, index) => {
        const percentage = total ? round((item.value / total) * 100, 1) : 0;
        return <CardListWithChartItem key={index} {...item} color={chartColor(index)} percentage={percentage} />;
      });

  const datasets: ChartDataSets[] = [
    {
      backgroundColor: colorMap
        ? array.map((item, index) => colorMap[item.key])
        : array.map((_, index) => chartColor(index)),
      borderWidth: 0,
      data: array.map(({ value }) => value),
    },
  ];

  const labels: string[] = array.map(({ label }) => label);

  return (
    <div className="frow frow--gutters-2x">
      <div className="col-sm-1-2 col-md-1-1 col-lg-1-2" style={{ maxHeight: '425px', overflowY: 'scroll' }}>
        {<div className="admin-card-list">{items}</div>}
      </div>
      <div className="col-sm-1-2 col-md-1-1 col-lg-1-2 hide-sm-block" style={{ maxWidth: '300px' }}>
        {total ? <DoughnutChart data={{ datasets, labels }} insetNumber={total} /> : null}
      </div>
    </div>
  );
}

const dotStyles = {
  fontSize: '0.6em',
  marginRight: '8px',
  position: 'relative' as 'relative',
  top: '-2px',
};

interface ICardListWithChartItemProps {
  label: string;
  color: string;
  percentage: number;
  handleClick?: () => any;
}

interface IDivOrAnchorProps extends React.HTMLAttributes<HTMLAnchorElement & HTMLDivElement> {
  handleClick?: () => any;
}

function DivOrAnchor({ handleClick, ...props }: IDivOrAnchorProps) {
  return handleClick ? <a {...props} onClick={handleClick} /> : <div {...props} />;
}

function CardListWithChartItem(props: ICardListWithChartItemProps) {
  const { color, percentage, label, handleClick } = props;

  return (
    <DivOrAnchor className="admin-card-list__item" handleClick={handleClick}>
      <div className="frow frow--items-center frow--gutters">
        <div className="col-5-6 text-ellipsis" title={label}>
          <i className="fas fa-circle" style={{ ...dotStyles, color }} />
          {label}
        </div>
        <div className="col-1-6">
          <span className="number number--bold">{percentage}%</span>
        </div>
      </div>
    </DivOrAnchor>
  );
}

interface ILoadingSpinnerProps extends ISpinnerProps {
  /**
   * The height of the loading spinner container
   */
  height?: string;
  /**
   * The minimum width of the loading spinner container
   */
  // minWidth?: string;
  /**
   * The width of the loading spinner container as the container is a block
   * @default 100%
   */
  width?: string;
}

function LoadingSpinner({ height, width, ...spinnerProps }: ILoadingSpinnerProps) {
  const style: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    height: height || '120px',
    justifyContent: 'center',
    width: width || '100%',
  };
  return (
    <div style={style}>
      <Spinner {...spinnerProps} />
    </div>
  );
}

const colors = [
  '#455c51',
  '#278753',
  '#36bc73',
  '#ed9d85',
  '#f1564e',
  '#fcb643',
  '#cfe0d8',
  '#81d4df',
  '#f4c1b1',
  '#f6928d',
  '#d2f0f4',
  '#fba922',
  '#efe7d3',
];

const colorsLength = colors.length;

const chartColor = (index: number) => colors[(colorsLength + index) % colorsLength];

interface IDoughnutChartProps {
  data: ChartData<Chart.ChartData>;
  insetNumber: number | string;
  options?: Chart.ChartOptions;
}

function DoughnutChart(props: IDoughnutChartProps) {
  const { data, insetNumber, options = {} } = props;

  const chartOptions: Chart.ChartOptions = {
    cutoutPercentage: 70,
    legend: {
      display: false,
    },
    ...options,
  };

  const numberStyles: React.CSSProperties = {
    left: '50%',
    marginRight: '-50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <div style={{ position: 'relative' }}>
      <Doughnut {...{ data }} options={chartOptions} height={300} />
      <span className="number number--large" style={numberStyles}>
        {insetNumber}
      </span>
    </div>
  );
}
