import * as React from 'react';

interface ICenteredContentProps {
  children: React.ReactNode;
  /**
   * 880 is for Secondary Tab content that has a side nav e.g. Application comments
   * 1200 is for Page content that shouldn't be full width e.g. Edit Job form
   * 1280 is to be used more as a layout when you want the content to fix perfect at 1600px e.g. Dashboard
   */
  maxWidth: 880 | 1200 | 1280;
}

export function CenteredContent({ children, maxWidth }: ICenteredContentProps) {
  return <div className={`centered-content-${maxWidth}`}>{children}</div>;
}
