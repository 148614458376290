import * as React from 'react';

import SearchField from './SearchField';
import useCustomEventEffect from './hooks/useCustomEventEffect';

export interface ISearchProps {
  clear?: boolean;
  canClear?: boolean;
  debounceTimeout?: number;
  target?: string;
  initialValue?: string;
  width?: string;
  willUpdateUrl?: boolean;
  searchPlaceholderText?: string;
  handleKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;
  handleSearch?(searchTerm: string): void;
}

export interface ICustomSearch {
  searchTerm: string;
}

export type ICustomSearchEvent = CustomEvent<ICustomSearch>;

export default function Search(props: ISearchProps) {
  const {
    canClear = false,
    clear = false,
    debounceTimeout = 250,
    handleSearch,
    handleKeyDown,
    initialValue = '',
    target,
    willUpdateUrl = false,
    width,
    searchPlaceholderText = 'Search...',
  } = props;
  const [searchTerm, setSearchTerm] = React.useState<string>(initialValue);
  const [isTableReady, setIsTableReady] = React.useState<boolean>(false);

  if (target) {
    useCustomEventEffect<void>(`${target}:ready`, () => {
      setIsTableReady(true);
    });
    const { dispatch: dispatchSearch } = useCustomEventEffect<ICustomSearch>(`${target}:search`);

    React.useEffect(() => {
      dispatchSearch({ searchTerm });
    }, [searchTerm]);

    React.useEffect(() => {
      if (searchTerm) dispatchSearch({ searchTerm });
    }, [isTableReady]);
  }

  if (handleSearch) {
    React.useEffect(() => handleSearch(searchTerm), [searchTerm]);
  }

  function handleChange(newSearchTerm) {
    setSearchTerm(newSearchTerm);
  }

  return (
    <SearchField
      {...{
        canClear,
        clear,
        debounceTimeout,
        handleKeyDown,
        willUpdateUrl,
        width,
      }}
      handleChange={handleChange}
      placeholder={searchPlaceholderText}
      value={searchTerm}
    />
  );
}
