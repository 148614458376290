import { Divider, Subheading1 } from '@pinpointhq/thumbtack';
import * as React from 'react';

interface ISubheadedSectionProps {
  children: React.ReactNode;
  showDivider?: boolean;
  mutedDivider?: boolean;
  title?: string;
}

export const SubheadedSection = ({ children, title, mutedDivider = false, showDivider = true }: ISubheadedSectionProps) => {
  return (
    <>
      {showDivider && <Divider className="mar-v-5" muted={mutedDivider} />}
      {title && <Subheading1 className="mar-b-3">{title}</Subheading1>}
      {children}
    </>
  );
};