import * as React from 'react';
import {
  Caption,
  Colors,
  Empty,
  Heading2,
  Icon16,
  IconButton40,
  IconNames16,
  Menu,
  MenuItem,
  Popover,
  Tag
} from "@pinpointhq/thumbtack";
import ReactTable from "react-table";
import {CompaniesCycle} from "../../../../../../javascript/models";
import {distanceInWords, format, isBefore} from "date-fns";
import {CenteredContent} from "../../../../../shared/CenteredContent";

export function Timelines({ activeCycle }: { activeCycle: CompaniesCycle }) {

  if (!activeCycle) {
    return(
      <CenteredContent maxWidth={1200}>
        <Heading2 className="mar-b-3">Timelines</Heading2>
        <Empty text="There is no timeline information for this company for the selected cycle" />
      </CenteredContent>
    )
  }

  const { companyTimeline } = activeCycle

  if (!companyTimeline) {
    return <Empty text="Timelines not completed" />
  }

  const columns = [
    {
      accessor: 'stage',
      Header: 'Stage',
    },
    {
      accessor: 'startDate',
      Header: 'Start',
    },
    {
      accessor: 'endDate',
      Header: 'End',
    },
    // {
    //   accessor: 'relativeToProgramme',
    //   Header: 'Relative to programme',
    // },
    // {
    //   Cell: ({ original }) => {
    //     if (original.isComplete) {
    //       return <CompleteTab />
    //     }
    //     if (original.isLate) {
    //       return <OverdueTab tabText={original.tagText} />
    //     }
    //     return <UpcomingTab tabText={original.tagText} />
    //   },
    //   accessor: 'progress',
    //   Header: 'Progress',
    // },
    {
      Cell: ({ original }) => {
        return(
          <Popover>
            <IconButton40 icon={IconNames16.OVERFLOW} aria-label="Timeline menu" />
            <Menu>
              <MenuItem text="Grant extension" />
              <MenuItem text="Flag issue" />
            </Menu>
          </Popover>
        )
      },
      Header: '',
      accessor: 'overflow',
      className: 'text-right',
      maxWidth: 80,
      sortable: false,
    }
  ]

  const firstContactProgramme = new Date('2023-05-01')
  const startRecruitmentProgramme = new Date('2023-05-01')
  const makeOfferProgramme = new Date('2023-05-01')
  const completeHiresProgramme = new Date('2023-05-01')

  const firstContactComplete = ['in_progress', 'offering', 'offering_and_hiring', 'complete'].includes(activeCycle.programmeProgress)
  const recruitmentComplete = ['in_progress', 'offering', 'offering_and_hiring', 'complete'].includes(activeCycle.programmeProgress)
  const makeOffersComplete = ['offering', 'offering_and_hiring', 'complete'].includes(activeCycle.programmeProgress)
  const completeHiresComplete = ['complete'].includes(activeCycle.programmeProgress)

  const data = [
    {
      stage: 'Review',
      startDate: companyTimeline.reviewStartDate ? format(companyTimeline.reviewStartDate, 'DD/MM/YYYY') : 'Not completed',
      endDate: companyTimeline.reviewEndDate ? format(companyTimeline.reviewEndDate, 'DD/MM/YYYY') : 'Not completed',
      // relativeToProgramme: `${distanceInWords(firstContactProgramme, firstContactDate)} ${
      //   isBefore(firstContactProgramme, firstContactDate) ? 'late' : 'early'
      // }`,
      // isComplete: firstContactComplete,
      // isLate: isBefore(firstContactProgramme, firstContactDate),
      // tagText: firstContactComplete ? 'Done' : `${distanceInWords(firstContactProgramme, firstContactDate)} ${
      //   isBefore(firstContactProgramme, firstContactDate) ? 'late' : 'to go'
      // }`
    },
    {
      stage: 'Assessment',
      startDate: companyTimeline.assessmentStartDate ? format(companyTimeline.assessmentStartDate, 'DD/MM/YYYY') : 'Not completed',
      endDate: companyTimeline.assessmentEndDate ? format(companyTimeline.assessmentEndDate, 'DD/MM/YYYY') : 'Not completed',
      // relativeToProgramme: `${distanceInWords(startRecruitmentProgramme, startInterviews)} ${
      //   isBefore(startRecruitmentProgramme, startInterviews) ? 'late' : 'early'
      // }`,
      // isComplete: recruitmentComplete,
      // isLate: isBefore(startRecruitmentProgramme, startInterviews),
      // tagText: recruitmentComplete ? 'Done' : `${distanceInWords(startRecruitmentProgramme, startInterviews)} ${
      //   isBefore(startRecruitmentProgramme, startInterviews) ? 'late' : 'to go'
      // }`
    },
    {
      stage: 'Offer',
      startDate: companyTimeline.offeringStartDate ? format(companyTimeline.offeringStartDate, 'DD/MM/YYYY') : 'Not completed',
      endDate: companyTimeline.offeringEndDate ? format(companyTimeline.offeringEndDate, 'DD/MM/YYYY') : 'Not completed',
      // relativeToProgramme: `${distanceInWords(makeOfferProgramme, finaliseOffers)} ${
      //   isBefore(makeOfferProgramme, finaliseOffers) ? 'late' : 'early'
      // }`,
      // isComplete: makeOffersComplete,
      // isLate: isBefore(makeOfferProgramme, finaliseOffers),
      // tagText: makeOffersComplete ? 'Done' : `${distanceInWords(makeOfferProgramme, finaliseOffers)} ${
      //   isBefore(makeOfferProgramme, finaliseOffers) ? 'late' : 'to go'
      // }`
    },
    {
      stage: 'Hire',
      startDate: companyTimeline.hiredStartDate ? format(companyTimeline.hiredStartDate, 'DD/MM/YYYY') : 'Not completed',
      endDate: companyTimeline.hiredEndDate ? format(companyTimeline.hiredEndDate, 'DD/MM/YYYY') : 'Not completed',
      // relativeToProgramme: `${distanceInWords(completeHiresProgramme, completeHires)} ${
      //   isBefore(completeHiresProgramme, completeHires) ? 'late' : 'early'
      // }`,
      // isComplete: completeHiresComplete,
      // isLate: isBefore(completeHiresProgramme, completeHires),
      // tagText: completeHiresComplete ? 'Done' : `${distanceInWords(completeHiresProgramme, completeHires)} ${
      //   isBefore(completeHiresProgramme, completeHires) ? 'late' : 'to go'
      // }`
    },
  ]

  return(
    <CenteredContent maxWidth={1200}>
      <Heading2 className="mar-b-3">Timelines</Heading2>
      <ReactTable
        columns={columns}
        data={data}
        showPagination={false}
        className="-unthemed"
        minRows={1}
      />
    </CenteredContent>
  )
}

function CompleteTab() {
  return(
    <Tag
      style={{ background: Colors.GREEN_10 }}
      large={true}
      icon={<Icon16 icon={IconNames16.TICK} fill={Colors.GREEN_50} />}
    >
      <Caption style={{ color: Colors.GREY_80 }}>
        Done
      </Caption>
    </Tag>
  )
}

function UpcomingTab({ tabText }) {
  return(
    <Tag
      style={{ background: Colors.YELLOW_10 }}
      large={true}
      icon={<Icon16 icon={IconNames16.CLOCK} fill={Colors.YELLOW_50} />}
    >
      <Caption style={{ color: Colors.GREY_80 }}>
        {tabText}
      </Caption>
    </Tag>
  )
}

function OverdueTab({ tabText }) {
  return(
    <Tag
      style={{ background: Colors.RED_10 }}
      large={true}
      icon={<Icon16 icon={IconNames16.CLOCK} fill={Colors.RED_50} />}
    >
      <Caption style={{ color: Colors.GREY_80 }}>
        {tabText}
      </Caption>
    </Tag>
  )
}