import * as React from "react";
import {DrawerHeader} from "../../../../shared/DrawerHeader";
import {Card, CardLevel, Classes, Divider, Link, PaddingLevel, Tab, Tabs} from "@pinpointhq/thumbtack";
import {useSpraypaintTableNamedParams} from "../../../shared/hooks/useSpraypaintTable";
import {adminProgrammeCompanyPath} from "../../../../../javascript/application/ts_routes";
import Search from "../../../../shared/Search";
import ReactTable from "react-table";
import NewPaginationComponent from "../../../shared/NewPaginationComponent";
import {Company} from "../../../../../javascript/models";

export function SuperleagueCompanies({ cycle, handleClose, programme, selectedTab }) {
  const [selectedTabId, setSelectedTabId] = React.useState(selectedTab || 'not-taking-part')

  const handleTabChange = (tabId) => setSelectedTabId(tabId)

  return(
    <>
      <DrawerHeader title="Super-league organisations" handleClose={handleClose} />
      <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.NONE}>
        <Divider muted={true} />
        <Tabs
          className={[Classes.FOCUS_DISABLED, 'responsive-secondary-tabs'].join(' ')}
          id="superleague-companies"
          selectedTabId={selectedTabId} onChange={handleTabChange}
          muted={true}
          renderActiveTabPanelOnly={true}
        >
          <Tab panel={<NotTakingPart {...{ cycle, programme }} />} id="not-taking-part">
            Not taking part
          </Tab>
          <Tab panel={<SignedUp {...{ cycle, programme }} />} id="signed-up">
            Already signed up
          </Tab>
          <Tab panel={<Pending {...{ cycle, programme }} />} id="pending">
            Yet to roll over
          </Tab>

        </Tabs>
      </Card>
    </>
  )
}

function NotTakingPart({ cycle, programme }) {
  const scope = Company.where({ superleague: true, not_taking_part: true })
    .extraParams({ cycle_id: cycle.id, programme_id: programme.id })
    .selectExtra(['previous_hires', 'previous_pledge'])


  return(
    <Table {...{ programme, scope }} />
  )
}

function SignedUp({ cycle, programme }) {
  const scope = Company.where({ superleague: true, already_signed_up: true })
    .extraParams({ cycle_id: cycle.id, programme_id: programme.id })
    .selectExtra(['previous_hires', 'previous_pledge'])


  return(
    <Table {...{ programme, scope }} />
  )
}

function Pending({ cycle, programme }) {
  const scope = Company.where({ superleague: true, yet_to_roll_over: true })
    .extraParams({ cycle_id: cycle.id, programme_id: programme.id })
    .selectExtra(['previous_hires', 'previous_pledge'])


  return(
    <Table {...{ programme, scope }} />
  )
}

function Table({ programme, scope }) {

  const { tableProps, setQuery, isLoading } = useSpraypaintTableNamedParams({
    initialScope: scope,
    searchIdentifier: 'companies',
  })

  const columns = [
    {
      Cell: (row: any) => {
        return (
          <Link href={adminProgrammeCompanyPath(programme.id, row.original.id)}>{row.original.name}</Link>
        )
      },
      Header: 'Organisation',
      accessor: 'name',
    },
    {
      Header: 'Previous pledge',
      accessor: 'previousPledge',
      sortable: false
    },
    {
      Header: 'Previous hires',
      accessor: 'previousHires',
      sortable: false
    },
  ]

  const handleSearch = (searchTerm) => {
    setQuery({ search: searchTerm })
  }

  const paginationProps = React.useCallback(
    () => (tableProps),
    [tableProps],
  );

  return(
    <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.REGULAR}>
      <div className="mar-b-3">
        <Search handleSearch={handleSearch} />
      </div>
      <ReactTable
        {...tableProps}
        loading={isLoading}
        columns={columns}
        PaginationComponent={NewPaginationComponent}
        getPaginationProps={paginationProps}
        className="-unthemed"
        minRows={1}
      />
    </Card>
  )
}