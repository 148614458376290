import * as React from 'react';
import {Col, Frow} from "@pinpointhq/thumbtack";
import {Problems} from "./internshipData/Problems";
import {InternshipsTakingPlace} from "./internshipData/InternshipsTakingPlace";
import {AveragePayPerWorkstream} from "./internshipData/AveragePayPerWorkstream";

export function InternshipData() {
  return(
    <Frow flexDirection="column" verticalGutters={40}>
      <Col>
        <Problems />
      </Col>
      <Col>
        <InternshipsTakingPlace />
      </Col>
      <Col>
        <AveragePayPerWorkstream />
      </Col>
    </Frow>
  )
}