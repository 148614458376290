import * as React from "react";

export const columns = [
  {
    Cell: (row: any) => <div>{row.original.attributes.company_name}</div>,
    Header: 'Company',
    sortable: false,
    // width: 180,
  },
  {
    Cell: (row: any) => <div>{row.original.attributes.first_contact_date}</div>,
    Header: 'First contact',
    sortable: false,
    width: 130,
  },
  {
    Cell: (row: any) => <div>{row.original.attributes.complete_hires}</div>,
    Header: 'Complete hires',
    sortable: false,
    width: 150,
  },
  {
    Cell: (row: any) => <div>{row.original.attributes.internship_start_date}</div>,
    Header: 'Start date',
    sortable: false,
    width: 120,
  },
  {
    Cell: (row: any) => <div>{row.original.attributes.programme_progress}</div>,
    Header: 'Status',
    sortable: false,
    width: 120,
  },
  {
    Cell: (row: any) => <div>{row.original.attributes.internship_end_date}</div>,
    Header: 'End date',
    sortable: false,
    width: 120,
  },
]