const workstreamClusters = require('../workstreamClusters.json');

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key].includes(value));
}

function workstreamCluster(workstream: string) {
  return getKeyByValue(workstreamClusters, workstream);
}

export default workstreamCluster;
