import * as React from "react";
import {Body, Card, CardLevel, Empty, PaddingLevel, Subheading1} from "@pinpointhq/thumbtack";

export function AveragePayPerWorkstream() {
  return(
    <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
      <Subheading1>Average pay per workstream</Subheading1>
      <Body className="mar-t-1 mar-b-3" muted={true}>Average pay of internships by workstream </Body>
      <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
        <Empty text="Currently empty because we don't ask for internship pay" />
      </Card>
    </Card>
  )
}