import { Button, Dialog, FormGroup, Input, ISelectOption, MultiSelect } from '@pinpointhq/thumbtack';
import * as React from 'react';
import { createToast } from '../../FlashToaster';

const educationOptions = [
  'Undergraduate (Foundation Year)',
  'Undergraduate (First Year)',
  'Undergraduate (Penultimate Year)',
  'Undergraduate (Final Year)',
  'Undergraduate (Other Year)',
  'Recent Graduate',
  'Postgraduate',
  'PhD',
  'Gap Year',
];

const graduationOptions = [
  'After 2030',
  '2026',
  '2025',
  '2024',
  '2027',
  '2029',
  '2023',
  '2028',
  '2021',
  '2022',
  'Before 2021',
  'Not Applicable',
  '2030',
];

export function AllocateDialog({
  callout = null,
  degreeSubjectOptions,
  isOpen,
  handleClose,
  handleSubmit,
  initialSector = null,
  regionOptions,
  workstreamOptions,
}) {
  const [allocationAmount, setAllocationAmount] = React.useState<number>(10);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleNumberChange = (event) => {
    setAllocationAmount(event.target.value);
  };

  const handleClick = () => {
    if (!allocationAmount || allocationAmount < 1) {
      createToast({ type: 'error', text: 'You must choose 1 or more applications to create' });
      return;
    }

    setIsSubmitting(true);
    handleSubmit({
      allocationAmount,
      selectedSectors,
      selectedEducation,
      selectedGraduation,
      selectedDegree,
      selectedLocations,
    });
  };

  // -------------------------
  // Sectors / Workstreams
  // -------------------------
  const [selectedSectors, setSelectedSectors] = React.useState([initialSector]);
  const handleSectorChange = (items: ISelectOption[]) => setSelectedSectors(items.map((item) => item.value));
  const selectedWorkstreamOptions = workstreamOptions.filter((option) => selectedSectors.includes(option.value));

  // -------------------------
  // Education
  // -------------------------
  const [selectedEducation, setSelectedEducation] = React.useState([]);
  const handleEducationChange = (items: ISelectOption[]) => setSelectedEducation(items.map((item) => item.value));
  const selectableEducationOptions = educationOptions.map((option) => ({ value: option, label: option }));
  const selectedEducationOptions = selectableEducationOptions.filter((option) =>
    selectedEducation.includes(option.value),
  );

  // -------------------------
  // Graduation Year
  // -------------------------
  const [selectedGraduation, setSelectedGraduation] = React.useState([]);
  const selectableGraduationOptions = graduationOptions.map((option) => ({ value: option, label: option }));
  const selectedGraduationOptions = selectableGraduationOptions.filter((option) =>
    selectedGraduation.includes(option.value),
  );
  const handleGraduationChange = (items: ISelectOption[]) => setSelectedGraduation(items.map((item) => item.value));

  // -------------------------
  // Degree
  // -------------------------
  const [selectedDegree, setSelectedDegree] = React.useState([]);
  const selectedDegreeOptions = degreeSubjectOptions.filter((option) => selectedDegree.includes(option.value));
  const handleDegreeChange = (items: ISelectOption[]) => setSelectedDegree(items.map((item) => item.value));

  // -------------------------
  // Locations / Regions
  // -------------------------
  const [selectedLocations, setSelectedLocations] = React.useState([]);
  const handleLocationChange = (items: ISelectOption[]) => setSelectedLocations(items.map((item) => item.value));
  const selectedRegionOptions = regionOptions.filter((option) => selectedLocations.includes(option.value));

  return (
    <Dialog title="Allocate candidates" isOpen={isOpen} onClose={handleClose}>
      {callout}
      <FormGroup label="Number of candidates">
        <Input type="number" min={1} onChange={handleNumberChange} value={allocationAmount} />
      </FormGroup>
      <FormGroup
        label="Workstreams"
        helperText="The candidates allocated candidates will be split evenly across the selected sectors. Leave this blank to use the jobs workstream"
      >
        <MultiSelect
          fill={true}
          items={workstreamOptions}
          selectedItems={selectedWorkstreamOptions}
          handleChange={handleSectorChange}
          condensed={selectedSectors.length > 2}
          scrollToActiveItem={false}
        />
      </FormGroup>
      <FormGroup label="Regions" helperText="Leaving this blank will use the region of the job">
        <MultiSelect
          fill={true}
          items={regionOptions}
          selectedItems={selectedRegionOptions}
          handleChange={handleLocationChange}
          condensed={selectedLocations.length > 2}
          scrollToActiveItem={false}
        />
      </FormGroup>
      <FormGroup label="Level of education" helperText="Specify a level of education">
        <MultiSelect
          fill={true}
          items={selectableEducationOptions}
          selectedItems={selectedEducationOptions}
          handleChange={handleEducationChange}
          condensed={selectedEducation.length > 2}
          scrollToActiveItem={false}
        />
      </FormGroup>
      <FormGroup label="Year of graduation" helperText="Specify a year of graduation">
        <MultiSelect
          fill={true}
          items={selectableGraduationOptions}
          selectedItems={selectedGraduationOptions}
          handleChange={handleGraduationChange}
          condensed={selectedGraduation.length > 2}
          scrollToActiveItem={false}
        />
      </FormGroup>
      <FormGroup label="Degree" helperText="Specify the degree subjects">
        <MultiSelect
          fill={true}
          items={degreeSubjectOptions}
          selectedItems={selectedDegreeOptions}
          handleChange={handleDegreeChange}
          condensed={selectedDegree.length > 2}
          scrollToActiveItem={false}
        />
      </FormGroup>
      <Button
        text="Allocate"
        fill={true}
        large={true}
        className="mar-t-3"
        onClick={handleClick}
        loading={isSubmitting}
      />
    </Dialog>
  );
}
