import * as React from 'react';
import * as $ from 'jquery';
import LoadingSpinner from '../../shared/LoadingSpinner';
import {
  Alerter,
  Button,
  Caption,
  Card,
  CardLevel,
  Empty,
  FormGroup,
  Frow,
  IconButton40,
  IconNames16,
  Intent,
  Menu,
  MenuItem,
  PaddingLevel,
  Popover, SimpleAvatar,
  TextArea,
} from '@pinpointhq/thumbtack';
import {format} from 'date-fns';
import {getCsrfToken} from '../../shared/csrfToken';
import simpleFormat from '../../shared/utilities/simpleFormat';
import {adminCommentPath} from '../../../javascript/application/ts_routes';

interface IComment {
  attributes: {
    body: string;
    created_at: string;
    user_name: string;
    user_id: string;
  },
  id: string;
}

export default function Comments({ indexPath, userId }) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [comments, setComments] = React.useState<IComment[]>([])

  React.useEffect(
    () => {
      getData();
    }, []
  )

  const getData = () => {
    $.ajax({
      url: indexPath,
      type: 'GET',
      dataType: 'json',
      cache: false,
      beforeSend: () => setIsLoading(true),
      complete: () => setIsLoading(false),
      success: (data) => setComments(data.data),
    });
  }

  const renderedComments = comments.map((comment) => {
    return <CommentCard key={comment.id} {...{ comment, getData, userId }}  />
  })

  const empty = <div className='mar-t-3'><Empty text="There are no comments yet" /></div>


  return(
    <>
      <CommentForm {...{ getData, indexPath }} />
      {isLoading ? <LoadingSpinner /> : comments.length > 0 ? renderedComments : empty}
    </>
  )
}

function CommentCard({ comment, getData, userId }: { comment: IComment, getData(): void; userId: string }) {
  const [isEditing, setIsEditing] = React.useState<boolean>(false)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleDelete = () => {
    Alerter.create({
      cancelButtonText: 'cancel',
      confirmButtonText: 'Yes, delete',
      intent: Intent.DANGER,
      onConfirm: handleConfirm,
      text: 'Are you sure you want to delete this comment? This is irreversible',
    });
  }

  const handleConfirm = () => {
    $.ajax({
      url: adminCommentPath(comment.id),
      data: { authenticity_token: getCsrfToken() },
      type: 'DELETE',
      dataType: 'json',
      success: () => {
        getData();
      },
    });
  }

  const handleCancel = () => setIsEditing(false)

  return(
    <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR} className="mar-v-3">
      {isEditing ? <CommentForm indexPath={null} {...{ handleCancel, getData }} commentId={comment.id} initialBody={comment.attributes.body} /> : (
        <>
          <Frow gutters={24} flexWrap="nowrap">
            <div>
              <SimpleAvatar initials={comment.attributes.user_name.split(' ').map((name) => name[0]).join('')} large={true} />
            </div>
            <div className="bp3-col--flex-grow-1">
              <Frow flexWrap="nowrap" justifyContent="space-between" gutters={8} verticalGutters={8}>
                <div className="col-flex-grow-1">
                  <div className='bp3-running-text' dangerouslySetInnerHTML={{ __html: simpleFormat(comment.attributes.body) }} />
                </div>
              </Frow>
              <Frow justifyContent="space-between" gutters={16} verticalGutters={16}>
                <div>
                  <Caption>{comment.attributes.user_name}</Caption>
                </div>
                <div>
                  <Caption>{format(comment.attributes.created_at, 'Do MMM YY [@] HH:mm')}</Caption>
                </div>
              </Frow>
            </div>
            <div>
              {userId === comment.attributes.user_id && (<div>
                <Popover>
                  <IconButton40 icon={IconNames16.OVERFLOW} />
                  <Menu>
                    <MenuItem text="Edit" onClick={handleEdit} />
                    <MenuItem text="Delete" onClick={handleDelete} />
                  </Menu>
                </Popover>
              </div>)}
            </div>
          </Frow>
        </>
      )}
    </Card>
  )
}

function CommentForm({ commentId = null, indexPath, initialBody = null, getData, handleCancel = null }) {
  const [commentBody, setCommentBody] = React.useState(initialBody)
  const [isLoading, setIsLoading] = React.useState(false)
  const handleCommentChange = (event) => {
    setCommentBody(event.target.value)
  }

  const handleSave = () => {
    if (!commentBody) return;

    if (commentId) {
      $.ajax({
        url: adminCommentPath(commentId),
        data: { authenticity_token: getCsrfToken(), comment: { body: commentBody } },
        type: 'PATCH',
        dataType: 'json',
        beforeSend: () => setIsLoading(true),
        complete: () => setIsLoading(false),
        success: () => {
          setCommentBody('')
          handleCancel();
          setIsLoading(false)
          getData();
        },
      });
    } else {
      $.ajax({
        url: indexPath,
        data: { authenticity_token: getCsrfToken(), comment: { body: commentBody } },
        type: 'POST',
        dataType: 'json',
        beforeSend: () => setIsLoading(true),
        complete: () => setIsLoading(false),
        success: () => {
          setCommentBody('')
          setIsLoading(false)
          getData();
        },
      });
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      handleSave()
    }
  }

  return(
    <Card paddingLevel={PaddingLevel.REGULAR} level={CardLevel.FILLED}>
      <FormGroup label="New comment">
        <TextArea onChange={handleCommentChange} value={commentBody} fill={true} onKeyPress={handleKeyPress} />
      </FormGroup>
      <Frow gutters={16}>
        <div className="bp3-col--flex-grow-1">
          <Button text={`${commentId ? 'Update' : 'Post'} comment`} disabled={!commentBody} onClick={handleSave} loading={isLoading} fill={true} />
        </div>
        {commentId && (
          <div>
            <Button text="Cancel" minimal={true} onClick={handleCancel} loading={isLoading}/>
          </div>
        )}
      </Frow>
    </Card>
  )
}
