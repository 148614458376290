import { Button, Dialog, FormGroup, NumericInput } from '@pinpointhq/thumbtack';
import * as React from 'react';

export function ChangeHeadcountDialog({ isOpen, job, handleClose, fetchData }) {
  const [headcount, setHeadcount] = React.useState(job);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (job) {
      setHeadcount(job.headcount);
    }
  }, [job]);

  const handleHeadcountChange = (value) => setHeadcount(value);

  const handleSubmit = () => {
    setIsSubmitting(true);
    job.headcount = headcount;
    job.save().then(() => {
      fetchData();
      handleClose();
      setIsSubmitting(false);
    });
  };

  return (
    <Dialog title="Change headcount" isOpen={isOpen} onClose={handleClose}>
      <FormGroup label="Headcount">
        <NumericInput value={headcount} onValueChange={handleHeadcountChange} fill={true} />
      </FormGroup>
      <Button
        fill={true}
        text="Update"
        large={true}
        disabled={!headcount}
        onClick={handleSubmit}
        loading={isSubmitting}
      />
    </Dialog>
  );
}
