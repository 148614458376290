import {
  Body,
  Button,
  Card,
  CardLevel,
  Classes,
  Frow,
  IconButton40,
  IconNames16,
  Intent,
  Menu,
  MenuItem,
  PaddingLevel,
  Popover,
  Subheading1,
  Tooltip,
  WorkflowTag,
} from '@pinpointhq/thumbtack';
import { format } from 'date-fns';
import * as React from 'react';
import ReactTable from 'react-table';
import { visit } from 'turbolinks';
import { exportAdminProgrammeReleaseRequestsPath } from '../../../../javascript/application/ts_routes';
import { BulkReleaseRequest } from '../../../../javascript/models';
import Search from '../../../shared/Search';
import { useSpraypaintTableNamedParams } from '../../shared/hooks/useSpraypaintTable';
import NewPaginationComponent from '../../shared/NewPaginationComponent';
import { RejectDialog } from '../../shared/RejectDialog';
import { ApproveDialog } from './releaseTable/ApproveDialog';
import { ReleaseRequestDialog } from './releaseTable/ReleaseRequestDialog';

export function ReleaseTable({ cycleId, programmeId }) {
  const identifier = 'released-applications';

  const [approvingRequest, setApprovingRequest] = React.useState<BulkReleaseRequest>();
  const [rejectingRequest, setRejectingRequest] = React.useState<BulkReleaseRequest>();
  const [viewingReleaseRequest, setViewingReleaseRequest] = React.useState<BulkReleaseRequest>();

  const { fetchData, tableProps, setQuery } = useSpraypaintTableNamedParams({
    initialScope: BulkReleaseRequest.includes({
      release_requests: { application: { job_seeker: ['candidate'] } },
    }).selectExtra(['company_name', 'internship_title']),
    searchIdentifier: 'bulk-release-requests',
  });

  const columns = [
    {
      Cell: ({ original }: { original: BulkReleaseRequest }) => {
        if (original.status === 'approved') {
          return <WorkflowTag intent={Intent.SUCCESS} text="Approved" />;
        }
        if (original.status === 'rejected') {
          return <WorkflowTag intent={Intent.DANGER} text="Rejected" />;
        }
        if (original.status === 'automatically_rejected') {
          return <WorkflowTag intent={Intent.DANGER} text="Automatically rejected" />;
        }
        return <WorkflowTag intent={Intent.NONE} text="Unconfirmed" />;
      },
      Header: 'Status',
      accessor: 'status',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: BulkReleaseRequest }) => {
        const handleClick = () => {
          setViewingReleaseRequest(original);
        };
        return (
          <div onClick={handleClick}>
            <span className={Classes.LINK}>{original.companyName}</span>
          </div>
        );
      },
      Header: 'Company name',
      accessor: 'companyName',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: BulkReleaseRequest }) => {
        return <div>{format(original.createdAt, 'MMMM Do YYYY')}</div>;
      },
      Header: 'Requested on',
      accessor: 'createdAt',
    },
    {
      Cell: ({ original }: { original: BulkReleaseRequest }) => {
        return <div>{original.releaseRequests.length}</div>;
      },
      Header: 'No. to release',
      accessor: 'numberOfCandidates',
    },
    {
      Cell: ({ original }: { original: BulkReleaseRequest }) => {
        return <div>{original.internshipTitle}</div>;
      },
      Header: 'Internship',
      accessor: 'internshipTitle',
    },
    {
      Cell: ({ original }: { original: BulkReleaseRequest }) => {
        return (
          <div>
            <Tooltip content={original.reason}>{original.reason}</Tooltip>
          </div>
        );
      },
      Header: 'Reason',
      accessor: 'releaseReason',
      sortable: false,
    },
    {
      Cell: ({ original }: { original: BulkReleaseRequest }) => {
        const handleApprove = () => {
          setApprovingRequest(original);
        };

        const handleReject = () => {
          setRejectingRequest(original);
        };

        if (original.status !== 'requested') return null;

        return (
          <div>
            <Popover>
              <IconButton40 icon={IconNames16.OVERFLOW} />
              <Menu>
                <MenuItem intent={Intent.NONE} text="Approve" onClick={handleApprove} />
                <MenuItem intent={Intent.DANGER} text="Reject" onClick={handleReject} />
              </Menu>
            </Popover>
          </div>
        );
      },
      Header: '',
      accessor: 'menu',
      sortable: false,
      width: 80,
    },
  ];

  const paginationProps = React.useCallback(() => tableProps, [tableProps]);

  const handleSearch = (searchTerm: string) => {
    setQuery({ search: searchTerm });
  };

  const handleExport = () => {
    visit(exportAdminProgrammeReleaseRequestsPath(programmeId, { cycle_id: cycleId }));
  };

  const handleCancel = () => {
    setApprovingRequest(undefined);
    setRejectingRequest(undefined);
  };

  return (
    <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.REGULAR}>
      <div className="mar-b-2">
        <Subheading1>Requests to release</Subheading1>
        <Body muted={true} className="mar-t-1">
          Company release requests
        </Body>
      </div>
      <div className="mar-b-2">
        <Frow gutters={16} verticalGutters={16}>
          <div className="bp3-col--flex-grow-1">
            <Search target={identifier} handleSearch={handleSearch} />
          </div>
          <div>
            <Button minimal={true} text="Export" large={true} onClick={handleExport} />
          </div>
        </Frow>
      </div>
      <ReactTable
        {...tableProps}
        columns={columns}
        PaginationComponent={NewPaginationComponent}
        getPaginationProps={paginationProps}
        className="-unthemed"
        minRows={1}
      />
      {approvingRequest && (
        <ApproveDialog fetchData={fetchData} handleClose={handleCancel} bulkReleaseRequest={approvingRequest} />
      )}
      {rejectingRequest && (
        <RejectDialog fetchData={fetchData} handleClose={handleCancel} bulkReleaseRequest={rejectingRequest} />
      )}
      {viewingReleaseRequest && (
        <ReleaseRequestDialog
          handleClose={() => setViewingReleaseRequest(undefined)}
          bulkReleaseRequest={viewingReleaseRequest}
        />
      )}
    </Card>
  );
}
